import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppContext } from "../components/AppContext";
import Header from "../components/Header";
import Image from "../components/Image";
import Section from "../components/Section";
import Flex from "../components/Flex";
import Text from "../components/Text";
import SplitView from "../components/SplitView";
import SelectForCalculator, {
  OptionProp
} from "../components/SelectForCalculator";
import { Color } from "../types";
import HR from "../components/HR";
import Button from "../components/Button";
import LiveMarkets from "../liveMarkets";
import Group from "../components/Group";
import ContactUsModal from "./modals/ContactUsModal";
import Field from "../components/Field";

const defaultForm = {
  name: "",
  // phone: "",
  managementStyle: "",
  email: "",
  consent: true
};

const CostSegregation = () => {
  const { mobileSize } = useContext(AppContext);
  const { marketPathParam } = useParams<any>();
  const [thankYou, setThankYou] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [isContactUs, setContactUs] = useState(false);
  const ConsentLabel = () => {
    return (
      <div
        style={{
          fontWeight: 300,
          fontSize: "0.7rem",
          marginTop: "-0.9rem",
          marginBottom: "2rem"
        }}
      >
        By submitting this form, you agree to receive subsequent emails and
        third-party marketing communications from Chalet pursuant to our{" "}
        <Link
          to="/privacy-policy"
          target="_blank"
          style={{
            cursor: "pointer",
            display: "inline-block",
            textDecoration: "underline"
          }}
        >
          Privacy Policy
        </Link>
        , which you may opt out of, or unsubscribe from, at any time.
      </div>
    );
  };
  const [errors, setErrors] = useState<any>({
    name: false,
    // phone: false,
    managementStyle: false,
    email: false,
    notes: false
  });

  const { name, email, consent, managementStyle } = form;

  useEffect(() => {
    async function addContact() {
      // eslint-disable-next-line
    console.log("name", name);
      // eslint-disable-next-line
    console.log("Consent", consent);
      try {
        const requestOptions = {
          method: "POST",
          // headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name,
            email,
            // phone,
            downloadReport: true,
            market: LiveMarkets[marketPathParam],
            // post_type: postTypeQuery,
            marketSlug: marketPathParam,
            managementStyle,
            consent,
            // ...cookies,
            sourcePage: "cost-segregation"
            // sourcePage: postTypeQuery
          })
        };
        // const apiData = await API.post("customers", "/contacts", data);
        await fetch(
          "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/cost-seg-contacts",
          requestOptions
        );
        setThankYou(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
    console.log("error scheduling consultation:", error);
      }
      setContactUs(false);
    }
    if (isContactUs) {
      setContactUs(true);
      addContact();
    }
  }, [isContactUs, form]);
  const allowContactUs = () => {
    const isEveryElementFilled = Object.entries(form).every(
      ([formName, value]: [string, string | boolean]) => {
        if (
          formName !== "password" &&
          formName !== "confirmpassword" &&
          formName !== "verificationcode" &&
          formName !== "other"
        ) {
          if (formName === "consent") {
            return value;
          }
          return value !== "";
        }

        return true;
      }
    );
    if (!isEveryElementFilled) {
      const newErrors = { ...errors };
      Object.entries(form).forEach(
        ([formName, value]: [string, string | boolean]) => {
          if (
            formName !== "password" &&
            formName !== "confirmpassword" &&
            formName !== "verificationcode" &&
            formName !== "other"
          ) {
            if (formName === "consent") {
              newErrors.consent = !consent;
            } else {
              newErrors[formName] = value === "";
            }
          }
        }
      );
      setErrors(newErrors);
    }
    return isEveryElementFilled;
  };
  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.persist();
    const { name: n, value } = e.target as HTMLInputElement;
    // eslint-disable-next-line
    console.log("On change name", n);
    // eslint-disable-next-line
    console.log("On change value", value);
    let inputName = n;
    let inputValue = value;
    if (!inputName) {
      inputName = (e as any).name;
      inputValue = ((e as any).value as OptionProp).label as string;
    }
    setForm((prevState) => ({
      ...prevState,
      [inputName]: inputValue
    }));
  };
  const titleString = `Free Cost Segregation Estimate | ${
    LiveMarkets[marketPathParam] || "Maximize Tax Savings"
  }`;
  const descriptionString = `Maximize Tax Savings on Your Real Estate Investments ${
    `in ${LiveMarkets[marketPathParam]}` || " "
  }. Get free cost segregation estimate so see how you can unlock significant cash flow for your investment property.  
  `;
  const urlSlug = marketPathParam
    ? `https://www.getchalet.com/cost-segregation/${marketPathParam}`
    : "https://www.getchalet.com/cost-segregation";
  return (
    <div style={{ paddingTop: "160px" }}>
      <Helmet>
        <link rel="canonical" href={urlSlug} />
        <title>{titleString}</title>
        <meta property="og:type" content="website" />
        <meta name="description" content={descriptionString} />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={descriptionString} />
        <meta property="og:image" content="" />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Section
        style={{
          background: mobileSize ? "var(--white)" : "none",
          boxShadow: "none"
          //   max
        }}
      >
        {!thankYou && (
          <SplitView
            style={{
              // padding: mobileSize ? "1rem" : "2rem",
              // paddingTop: "1rem",
              backgroundColor: "none",
              maxWidth: mobileSize ? "100vw" : "85vw",
              margin: "1rem auto"
            }}
          >
            {!thankYou && (
              <div style={{ padding: 0 }}>
                <>
                  <Header size={1} style={{ textAlign: "left" }}>
                    {" "}
                    Maximize Your Tax Savings with 100% FREE Cost Segregation
                    Estimate
                  </Header>
                  {/* <Image
                  src={`${process.env.PUBLIC_URL}/images/chalet_markets_2024.png`}
                  alt="Market Report"
                  style={{ padding: "0rem", maxWidth: "100%" }}
                  $mobileSize={mobileSize}
                /> */}
                  <Text
                    block
                    size="1.2rem"
                    align="left"
                    weight={300}
                    // style={{ lineHeight: "2.7rem" }}
                    // lineHeight="3.5rem"
                  >
                    Cost segregation is a strategic tax planning tool that
                    accelerates depreciation deductions, deferring federal and
                    state income taxes {marketPathParam && "in"}{" "}
                    <Text
                      style={{
                        color: "var(--tertiary)",
                        fontWeight: "500",
                        // textDecoration: "underline",
                        fontSize: mobileSize ? "1.2rem" : "1.2rem"
                      }}
                    >
                      {LiveMarkets[marketPathParam]}
                    </Text>
                    .
                  </Text>
                  <Text block size="1.2rem" align="left" weight={300}>
                    Get a{" "}
                    <Text weight={400} style={{ color: "var(--quinary)" }}>
                      free cost segregation estimate
                    </Text>{" "}
                    to see how you can unlock significant cash flow for your
                    business or investment property.
                  </Text>
                </>
              </div>
            )}

            <div style={{ paddingBottom: "1rem" }}>
              <>
                <div
                  style={{ width: mobileSize ? "calc(100% - 0rem)" : "100%" }}
                >
                  <Field
                    name="name"
                    placeholder="Name"
                    type="text"
                    value={name}
                    invalid={errors.name}
                    onChange={onChange}
                  />
                  {/* <Field
                    name="phone"
                    placeholder="How"
                    type="text"
                    value={phone}
                    invalid={errors.phone}
                    onChange={onChange}
                  /> */}
                  <Field
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={email}
                    invalid={errors.email}
                    onChange={onChange}
                  />
                  <SelectForCalculator
                    name="managementStyle"
                    placeholder="How do you manage your rentals?"
                    onChange={onChange}
                    data-id="management-style-dropdown"
                    style={{ margin: mobileSize ? "initial" : "0 0rem" }}
                    options={[
                      { label: "I self-manage", value: "I self-manage" },
                      {
                        label: "I have a property manager",
                        value: "I have a property manager"
                      }
                      // { label: "3", value: 2 },
                      // { label: "4", value: 3 },
                      // { label: "5", value: 4 },
                      // { label: "5+", value: "5+" }
                    ]}
                    value={managementStyle}
                  />
                  <ConsentLabel />
                  {Object.values(errors).some((value) => value) && (
                    <Text
                      color={Color.danger}
                      style={{
                        display: "block",
                        padding: "0.5rem",
                        textAlign: "center",
                        width: "100%"
                      }}
                    >
                      Fields{" "}
                      {Object.entries(errors)
                        .map(([key, value]) => (value ? key : undefined))
                        .filter((str) => str !== undefined)
                        .join(", ")}{" "}
                      are missing values
                    </Text>
                  )}
                  <Button
                    isLoading={isContactUs}
                    style={{
                      marginBottom: "1rem",
                      width: "100%",
                      background: "var(--quinary)"
                    }}
                    onClick={() => allowContactUs() && setContactUs(true)}
                    data-id="modal_contact_us_message_us_button"
                  >
                    Get a Free Estimate
                  </Button>
                </div>
              </>
            </div>
          </SplitView>
        )}
        {thankYou && (
          <Flex
            style={{
              alignItems: "stretch",
              flexDirection: "column",
              padding: mobileSize ? "0.5rem" : "3rem",
              textAlign: "center",
              minHeight: "70vh",
              background: "var(--white)"
            }}
          >
            <Text
              weight={400}
              color={Color.tertiary}
              style={{ fontSize: "1.5rem" }}
            >
              Thank you for reaching out{" "}
              <Text
                weight={400}
                color={Color.quinary}
                style={{ fontSize: "1.5rem" }}
              >
                {name}
              </Text>
              !
            </Text>
            <Header>Next steps</Header>
            <Text style={{ fontSize: "1.5rem", color: "var(--sixth)" }}>
              1. Our partner cost segregation specialist will reach out to you
              via email. They will ask for you property details and few other
              things to best determine the estimate
            </Text>
            <Text style={{ fontSize: "1.5rem", color: "var(--sixth)" }}>
              2. They will send you a free estimate with your potential tax
              savings to your inbox.
            </Text>
            <Text style={{ fontSize: "1.5rem", color: "var(--sixth)" }}>
              3. You can then decide what the right cost segregation study is
              right for you.
            </Text>
            <Text />
          </Flex>
        )}
      </Section>
      <Group style={{ paddingTop: "3rem", paddingBottom: "5rem" }}>
        <div
          style={{ paddingTop: "3rem", maxWidth: "1200px", margin: "0 auto" }}
        >
          <Header>How Does Cost Segregation Work?</Header>
          <Text block style={{ textAlign: "center", fontSize: "1.5rem" }}>
            Cost segregation is the process of identifying personal property
            assets that are grouped with real property assets and separating out
            personal assets for tax reporting purposes.
          </Text>
          <Text block style={{ textAlign: "center", fontSize: "1.5rem" }}>
            This technique can significantly increase your upfront depreciation
            deductions, reduce tax liability, and boost cash flow{" "}
            {marketPathParam && "in"}{" "}
            <Text
              style={{
                color: "var(--tertiary)",
                fontWeight: "500",
                // textDecoration: "underline",
                fontSize: mobileSize ? "2rem" : "1.6rem"
              }}
            >
              {LiveMarkets[marketPathParam]}
            </Text>
            . Our partner team of experts uses detailed engineering and tax
            analysis to identify qualifying assets.
          </Text>
        </div>
        <HR />
        <Section style={{ boxShadow: "none", paddingTop: "0" }}>
          <Header size={2} style={{ color: "var(--quinary)" }}>
            3 Simple Steps to maximizing your deductions
          </Header>
          <Image
            src={`${process.env.PUBLIC_URL}/images/cost-segregation.webp`}
            alt="Free Cost Segregation Estimate"
            style={{
              padding: "1rem",
              borderRadius: "0.25rem",
              maxWidth: "100%"
            }}
            $mobileSize={mobileSize}
          />
        </Section>
      </Group>
      <Group style={{ marginBottom: "10rem" }}>
        <Section>
          <Header size={2}>
            Success Story: Slashing $36K in T axes with Smart Strategy!
          </Header>
          <SplitView split="1:1">
            <div>
              <Header size={4}>
                Discover Our Own Journey through Cost Segregation and Bonus
                Depreciation
              </Header>
              <Text block>
                When we embarked on our own cost segregation journey, we were
                just as curious and hopeful as you might be today. The results
                were astounding: a whopping $36,000 slashed from our tax bill!
              </Text>
              <Text block>
                In our detailed case study, we peel back the layers of how we
                combined cost segregation with bonus depreciation to unlock
                these savings. Join us in exploring the potential for
                transformative financial benefits in your own portfolio.
              </Text>
            </div>

            <Image
              src={`${process.env.PUBLIC_URL}/images/blue-river.webp`}
              alt="Rental"
              style={{ padding: "1rem", borderRadius: "0.25rem" }}
              $mobileSize={mobileSize}
            />
          </SplitView>
          <a
            style={{
              display: "block",
              background: "var(--quinary)",
              padding: "1rem",
              fontSize: "1.4rem",
              margin: "0 auto",
              maxWidth: "300px",
              textAlign: "center",
              borderRadius: "0.25rem",
              textTransform: "uppercase",
              textDecoration: "none",
              color: "var(--white)"
            }}
            href="/blog/real-talk-how-we-slashed-36k-in-taxes-by-taking-charge-of-our-short-term-rental"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read More
          </a>
        </Section>
      </Group>
      {/* <Group style={{ margin: "4rem 0 " }}>
        <Header size={1}>How Does the Process Work?</Header>
        <Header
          style={{ color: "var(--tertiary)", padding: 0, margin: 0 }}
          size={2}
        >
          3 Simple steps to savings
        </Header>
        <Section style={{ boxShadow: "none" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: "4rem",
              flexFlow: "wrap"
            }}
          >
            <div
              style={{
                display: "flex",
                width: mobileSize ? "100%" : "27%",
                height: mobileSize ? "100%" : "100%",
                minWidth: "240px",
                marginBottom: "3rem",
                flexFlow: mobileSize ? "row wrap" : "initial",
                flexDirection: mobileSize ? "row" : "column",
                border: "2px solid var(--quinary)",
                margin: "2rem",
                padding: "2rem"
              }}
            >
              <div style={{ marginBottom: "2rem", marginRight: "2rem" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/optimize_listing.webp`}
                  alt="Airbnb & VRBO listings setup "
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "100%",
                    maxHeight: mobileSize ? "100%" : "100%",
                    minHeight: mobileSize ? "100%" : "100%",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div style={{ maxWidth: mobileSize ? "100%" : "285px" }}>
                <Header
                  size={2}
                  // color={Color.quinary}
                  style={{ paddingBottom: "1rem", textAlign: "left" }}
                >
                  1. Consultation
                </Header>
                <Text
                  block
                  style={{ fontSize: "1.5rem", lineHeight: "1.5rem" }}
                >
                  Contact us for an initial discussion about your property.
                </Text>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: mobileSize ? "100%" : "27%",
                height: mobileSize ? "100%" : "100%",
                minWidth: "240px",
                marginBottom: "3rem",
                flexFlow: mobileSize ? "row wrap" : "initial",
                flexDirection: mobileSize ? "row" : "column",
                border: "2px solid var(--quinary)",
                margin: "2rem",
                padding: "2rem"
              }}
            >
              <div style={{ marginBottom: "2rem", marginRight: "2rem" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/photographer.webp`}
                  alt="Airbnb & VRBO photographers"
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "100%",
                    maxHeight: mobileSize ? "100%" : "100%",
                    minHeight: mobileSize ? "100%" : "100%",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div style={{ maxWidth: mobileSize ? "100%" : "285px" }}>
                <Header
                  size={2}
                  // color={Color.quinary}
                  style={{ paddingBottom: "1rem", textAlign: "left" }}
                >
                  2. Analysis
                </Header>
                <Text
                  block
                  style={{ fontSize: "1.5rem", lineHeight: "1.5rem" }}
                >
                  We&apos;ll conduct a preliminary analysis to estimate
                  potential savings.
                </Text>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: mobileSize ? "100%" : "27%",
                height: mobileSize ? "100%" : "100%",
                minWidth: "240px",
                marginBottom: "3rem",
                flexFlow: mobileSize ? "row wrap" : "initial",
                flexDirection: mobileSize ? "row" : "column",
                border: "2px solid var(--quinary)",
                margin: "2rem",
                padding: "2rem"
              }}
            >
              <div style={{ marginBottom: "2rem", marginRight: "2rem" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/smart_messaging.webp`}
                  alt="Airbnb & VRBO automated messaging"
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "100%",
                    maxHeight: mobileSize ? "100%" : "100%",
                    minHeight: mobileSize ? "100%" : "100%",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div style={{ maxWidth: mobileSize ? "100%" : "285px" }}>
                <Header
                  size={2}
                  // color={Color.quinary}
                  style={{ paddingBottom: "1rem", textAlign: "left" }}
                >
                  3.Decision
                </Header>
                <Text
                  block
                  style={{ fontSize: "1.5rem", lineHeight: "1.5rem" }}
                >
                  With your estimate in hand, decide if a full study is right
                  for you.
                </Text>
              </div>
            </div>
          </div>
          <SectionFooter $mobileSize={mobileSize}>
            <Button
              block
              color={Color.tertiary}
              onClick={handleContactUsClick}
              data-id="chalet-concierge_invest_get_started_button"
            >
              GET A FREE ESTIMATE
            </Button>
          </SectionFooter>
        </Section>
      </Group> */}
      <Group
        style={{
          background: "var(--tertiary)",
          paddingTop: "5rem",
          paddingBottom: "7rem"
        }}
      >
        <Header
          color={Color.white}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
            // color: "var(--tertiary)"
          }}
        >
          FAQ
        </Header>
        <Section
          color={Color.white}
          style={{ background: "none", boxShadow: "none" }}
        >
          <Header
            size={2}
            style={{
              textAlign: "left",
              color: "var(--white)"
            }}
          >
            What types of properties benefit the most from cost segregation?
          </Header>
          <Text style={{ color: "var(--white)" }}>
            Commercial buildings, residential rental properties, and renovations
            can see significant tax savings.
          </Text>

          <Header
            size={2}
            style={{
              textAlign: "left",
              color: "var(--white)"
            }}
          >
            How long does the process take{marketPathParam && " in"}{" "}
            {LiveMarkets[marketPathParam]}?
          </Header>
          <Text style={{ color: "var(--white)" }}>
            The initial estimate can be completed quickly, while a full study
            varies based on property complexity.
          </Text>

          <Header
            size={2}
            style={{
              textAlign: "left",
              color: "var(--white)"
            }}
          >
            Is cost segregation risky?
          </Header>
          <Text style={{ color: "var(--white)" }}>
            When done by professionals, it&apos;s a safe and IRS-recognized
            strategy. Our team ensures compliance and accuracy.
          </Text>

          <Header
            size={2}
            style={{
              textAlign: "left",
              color: "var(--white)"
            }}
          >
            Can I get an estimate for a property I&apos;ve owned for years?
          </Header>
          <Text style={{ color: "var(--white)" }}>
            Absolutely! Cost segregation can be applied retroactively, offering
            potential refunds on past taxes.
          </Text>
        </Section>
        <Button
          isLoading={isContactUs}
          style={{
            marginBottom: "1rem",
            width: "100%",
            maxWidth: "350px",
            margin: "0 auto",
            background: "var(--quinary)"
          }}
          onClick={() => allowContactUs() && setContactUs(true)}
          data-id="modal_contact_us_message_us_button"
        >
          Get a Free Estimate
        </Button>
      </Group>
      <ContactUsModal />
    </div>
  );
};

export default CostSegregation;
