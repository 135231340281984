import React, {
  MouseEvent,
  useEffect,
  useRef,
  useContext,
  useState
} from "react";
import uniqueId from "lodash.uniqueid";
import styled from "styled-components";
import Field, { FieldProps, StyledField } from "./Field";
import { AppContext } from "./AppContext";
import useOnClickOutside from "../hooks/useOnClickOutside";

export type AddressAutoCompleteProps = FieldProps;

let autoComplete: any;

const loadScript = (url: string, callback: () => any) => {
  const script: any = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

async function handlePlaceSelect(updateQuery: any) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject;
  updateQuery(query);
}

function handleScriptLoad(updateQuery: any, autoCompleteRef: any) {
  autoComplete = new (window as any).google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {
      types: ["address"],
      componentRestrictions: { country: "us" },
      fields: ["address_components", "formatted_address", "geometry"]
    }
  );
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

const MagnifyingGlass = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    viewBox="0 0 46.553307 46.200966"
    version="1.1"
  >
    <g transform="translate(-29.461,-26.738)">
      {/* eslint-disable-next-line */}
      <path d="m69.902 72.704-10.935-10.935c-2.997 1.961-6.579 3.111-10.444 3.111-10.539 0-19.062-8.542-19.062-19.081 0-10.519 8.522-19.061 19.062-19.061 10.521 0 19.06 8.542 19.06 19.061 0 3.679-1.036 7.107-2.828 10.011l11.013 11.011c0.583 0.567 0.094 1.981-1.076 3.148l-1.64 1.644c-1.17 1.167-2.584 1.656-3.15 1.091zm-8.653-26.905c0-7.033-5.695-12.727-12.727-12.727-7.033 0-12.745 5.694-12.745 12.727s5.712 12.745 12.745 12.745c7.032 0 12.727-5.711 12.727-12.745z" />
    </g>
  </svg>
);

export const StyledAddressAutoComplete = styled.div`
  position: relative;
  margin-right: 1rem;

  ${StyledField} {
    // box-shadow: 0 0 43px rgba(0, 0, 0, 0.1);
    -webkit-appearance: none;
    // border: none;
    background-color: var(--quinary);
    text-decoration: underline;
    border: 1px solid var(--quinary);
    margin-bottom: 2rem;
    min-width: 400px;
    border-radius: 0.25rem;
    color: var(--tertiary);

    input::placeholder {
      color: var(--tertiary) !important;
    }
    @media (max-width: 800px) {
      min-width: 100%;
    }
  }

  > svg {
    fill: var(--quinary);
    height: 50%;
    padding: 0.5rem;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media (max-width: 800px) {
    margin-right: 0;
  }
`;

const AddressAutoComplete = ({
  name: propName,
  id,
  onChange,
  value: propValue,
  ...props
}: AddressAutoCompleteProps) => {
  const idRef = useRef(id || uniqueId("autocomplete-"));
  const fieldRef = useRef();
  const containerRef = useRef<HTMLDivElement>();
  const popoverRef = useRef<HTMLElement>();
  const [value, setValue] = useState("");
  // const [calcLat, setCalcLat] = useState(0);
  const { setCalcLat, setCalcLng } = useContext(AppContext);

  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(popoverRef, (e: React.FocusEvent<HTMLDivElement>) => {
    if (!containerRef?.current?.contains(e.relatedTarget)) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    if (value !== propValue) {
      setValue(propValue);
    }
  }, [propValue]);
  const handleChange = (e: MouseEvent<HTMLInputElement>) => {
    setValue((e.target as any).value);
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleFocus = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "ArrowDown" || e.key === "Tab") {
      if (popoverRef.current) {
        e.preventDefault();
        e.stopPropagation();

        popoverRef.current?.getElementsByTagName("button")[0]?.focus();
      }
    }
  };

  const handleOptionClick = (query: any) => {
    setValue(query.formatted_address);
    setCalcLat(query.geometry.location.lat());
    // console.log("this is running", query.geometry.location.lat());
    setCalcLng(query.geometry.location.lng());
    // console.log("this is running", query.geometry.location.lng());
    const e = {};
    (e as any).name = propName;
    (e as any).value = query.formatted_address;
    (e as any).calcLat = query.geometry.location.lat();
    (e as any).calcLng = query.geometry.location.lng();
    onChange?.(e as any);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PLACES_API_KEY}&libraries=places`,
      () => handleScriptLoad(handleOptionClick, fieldRef)
    );
  }, []);

  return (
    <StyledAddressAutoComplete ref={containerRef as any}>
      <Field
        {...props}
        autoComplete="off"
        name={propName}
        id={idRef.current}
        onFocus={handleFocus}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        value={value}
        onChange={handleChange}
        ref={fieldRef}
      />
      <MagnifyingGlass />
      {/* <Popover */}
      {/*  container={containerRef} */}
      {/*  isOpen={!!value && isOpen} */}
      {/*  ref={popoverRef} */}
      {/*  target={idRef.current} */}
      {/* > */}
      {/*  {loading && <Option disabled>Loading ...</Option>} */}
      {/*  {!loading && options.length === 0 && ( */}
      {/*    <Option disabled>No addresses found.</Option> */}
      {/*  )} */}
      {/*  {!loading && */}
      {/*    options.length > 0 && */}
      {/*    options.map((option, idx) => { */}
      {/*      const key = `option-${option.item.label}-${idx}`; */}
      {/*      return ( */}
      {/*        <Option key={key} onClick={handleOptionClick(option)}> */}
      {/*          <span */}
      {/*            // eslint-disable-next-line react/no-danger */}
      {/*            dangerouslySetInnerHTML={{ */}
      {/*              __html: `${option.item.label}${ */}
      {/*                option.item.postalcode ? ` ${option.item.postalcode}` : "" */}
      {/*              }` */}
      {/*                .split("") */}
      {/*                .map((char: string) => { */}
      {/*                  if (value.includes(char)) { */}
      {/*                    return `<b>${char}</b>`; */}
      {/*                  } */}
      {/*                  return char; */}
      {/*                }) */}
      {/*                .join("") */}
      {/*            }} */}
      {/*          /> */}
      {/*        </Option> */}
      {/*      ); */}
      {/*    })} */}
      {/* </Popover> */}
    </StyledAddressAutoComplete>
  );
};

export default AddressAutoComplete;
