import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import "../../styles/Analytics.scss";
import Section from "../../components/Section";
import { Featured, FeaturedCenter } from "../../components/Featured";
import fetcher from "../../utils/fetch";
import Flex from "../../components/Flex";
import AnalyticsHeader from "./components/AnalyticsHeader";
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import SkeletonLoader from "../../components/SkeletonLoader";
import MultiSelect from "../../components/MultiSelect";
import AnalyticsMarket from "./AnalyticsMarket";

const sortOptions = [
  {
    label: "Gross Yield",
    value: "gross_yield"
  },
  {
    label: "Annual Revenue",
    value: "annual_revenue"
  }
];

const AnalyticsPage = () => {
  const { market: marketPathParam } = useParams<any>();
  const [filteredTypes, setFilteredTypes] = React.useState<string[]>([]);
  const [stateTypes, setStateTypes] = React.useState<string[]>([]);
  const [sortType, setSortType] = React.useState<string | undefined>();

  const { data: markets } = useSWR<any[]>(
    "https://zmura59afa.execute-api.us-west-2.amazonaws.com/v1/markets",
    fetcher,
    {
      fallbackData: []
    }
  );

  const featuredComponents: any = (markets || [])
    .sort((a: any, b: any) => {
      const type = sortOptions.find(({ label }) => label === sortType);
      if (type) {
        return (
          parseFloat(b[type.value].replaceAll("$", "")) -
          parseFloat(a[type.value].replaceAll("$", ""))
        );
      }

      return 0;
    })
    .filter((market: any) => {
      let includesFilter = true;
      let includesState = true;
      if (filteredTypes.length > 0) {
        includesFilter = filteredTypes.includes(market.market_type);
      }
      if (stateTypes.length > 0) {
        includesState = stateTypes.includes(market.state_name);
      }
      return includesFilter && includesState;
    })
    .slice(0, 14)
    .map((market: any) => {
      const key = `market-${market.market}`;
      return (
        <FeaturedCenter
          key={key}
          image={market.image_url}
          title={market.market}
          gross={market.gross_yield}
          revenue={market.annual_revenue}
          medianHomeValue={market.median_home_value}
          href={`/analytics/overview/${market.market_slug}`}
          // cta="LEARN MORE"
          // right={idx % 2 !== 0}
        />
      );
    });

  const handleApply = (selectedOptions: string[]) => {
    console.log("Selected options:", selectedOptions);
    setFilteredTypes(selectedOptions);
  };

  const handleStateApply = (selectedOptions: string[]) => {
    console.log("Selected state options:", selectedOptions);
    setStateTypes(selectedOptions);
  };

  const handleSortApply = (selectedOptions: string[]) => {
    console.log("Selected state options:", selectedOptions);
    setSortType(selectedOptions[0]);
  };

  const [marketOptions, stateOptions] = (markets || []).reduce(
    (acc: [any[], any[]], cur: any) => {
      acc[0].push(cur.market_type);
      acc[1].push(cur.state_name);

      return acc;
    },
    [[], []]
  );

  if (marketPathParam) {
    return <AnalyticsMarket />;
  }

  return (
    <AnalyticsWrapper>
      <AnalyticsHeader />
      <Section id="analytics-main">
        <div id="analytics_landing">
          <div className="analytics_intro">
            Looking to invest in short-term rentals but unsure where to start?
            Our free investment and market analysis tool provides comprehensive
            insights and strategies tailored to investors at all experience
            levels.
          </div>
          <div className="analytics_intro">
            Our guides offer the knowledge and tools you need to make informed
            decisions and maximize your returns. Get ready for the launch of our
            sophisticated Deal Analyzer (Airbnb Calculator), designed to help
            you navigate and excel in the short-term rental market.
          </div>
          <div id="analytics_markets">
            <Featured
              title="Short-Term Rental Markets"
              actions={
                <Flex style={{ gap: "1rem" }}>
                  <MultiSelect
                    options={marketOptions}
                    onApply={handleApply}
                    placeholder="Market Types"
                  />
                  <MultiSelect
                    options={stateOptions}
                    onApply={handleStateApply}
                    placeholder="States"
                  />
                  <MultiSelect
                    options={sortOptions.map(({ label }) => label)}
                    onApply={handleSortApply}
                    placeholder="Unsorted"
                    prefix="Sort"
                    single
                  />
                </Flex>
              }
            >
              {markets?.length === 0 ? <SkeletonLoader /> : featuredComponents}
            </Featured>
          </div>
        </div>
      </Section>
    </AnalyticsWrapper>
  );
};

export default AnalyticsPage;
