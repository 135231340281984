const LiveMarkets: any = {
  "galveston-tx": "Galveston, TX",
  "san-antonio-tx": "San Antonio, TX",
  "dallas-tx": "Dallas, TX",
  "houston-tx": "Houston, TX",
  "memphis-tn": "Memphis, TN",
  "philadelphia-pa": "Philadelphia, PA",
  "columbus-oh": "Columbus, OH",
  "minneapolis-mn": "Minneapolis, MN",
  "fort-wayne-in": "Fort Wayne, IN",
  "kissimmee-fl": "Kissimmee, FL",
  "pompano-beach-fl": "Pompano Beach, FL",
  "west-palm-beach-fl": "West Palm Beach, FL",
  "hollywood-fl": "Hollywood, FL",
  "miami-beach-fl": "Miami Beach, FL",
  "miami-fl": "Miami, FL",
  "panama-city-beach-fl": "Panama City Beach, FL",
  "santa-fe-nm": "Santa Fe, NM",
  "dillon-beach-ca": "Dillon Beach, CA",
  "mendocino-ca": "Mendocino, CA",
  "sonoma-ca": "Sonoma, CA",
  "los-gatos-ca": "Los Gatos, CA",
  "coachella-ca": "Coachella, CA",
  "palm-desert-ca": "Palm Desert, CA",
  "la-quinta-ca": "La Quinta, CA",
  "st-petersburg-fl": "St. Petersburg, FL",
  "lake-ozark-mo": "Lake Ozark, MO",
  "pittsburgh-pa": "Pittsburgh, PA",
  "fort-lauderdale-fl": "Fort Lauderdale, FL",
  "lake-worth-fl": "Lake Worth, FL",
  "fort-myers-fl": "Fort Myers, FL",
  "destin-fl": "Destin,FL",
  "san-diego-county": "San Diego County, CA",
  "lake-havasu-city-az": "Lake Havasu City, AZ",
  "phoenix-az": "Phoenix, AZ",
  "tucson-az": "Tucson, AZ",
  "tempe-az": "Tempe, AZ",
  "sedona-az": "Sedona, AZ",
  "scottsdale-az": "Scottsdale, AZ",
  "mesa-az": "Mesa, AZ",
  "flagstaff-az": "Flagstaff, AZ",
  "gulf-shores-al": "Gulf Shores, AL",
  "oxford-ms": "Oxford, MS",
  "ocean-city-md": "Ocean City, MD",
  "santa-cruz-ca": "Santa Cruz, CA",
  "athens-ga": "Athens, GA",
  "madison-wi": "Madison, WI",
  "honolulu-hi": "Honolulu, HI",
  "ann-arbor-mi": "Ann Arbor, MI",
  "gulf-breeze-fl": "Gulf Breeze, FL",
  "gatlinburg-tn": "Gatlinburg, TN",
  "blue-ridge-ga": "Blue Ridge, GA",
  "poconos-pa": "Poconos, PA",
  "tobyhanna-pa": "Tobyhanna, PA",
  "chandler-az": "Chandler, AZ",
  "joshua-tree-ca": "Joshua Tree, CA",
  "yucca-valley-ca": "Yucca Valley, CA",
  "virginia-beach-va": "Virginia Beach, VA",
  "milwaukee-wi": "Milwaukee, WI",
  "st-louis-mo": "Saint Louis, MO",
  "kansas-city-mo": "Kansas City, MO",
  "buffalo-ny": "Buffalo, NY",
  "cincinnati-oh": "Cincinnati, OH",
  "tavernier-fl": "Tavernier, FL",
  "bradenton-beach-fl": "Bradenton Beach, FL",
  "puerto-rico": "Puerto Rico",
  "big-bear-ca": "Big Bear, CA",
  "palm-springs-ca": "Palm Springs, CA",
  "eugene-or": "Eugene, OR",
  "cape-coral-fl": "Cape Coral, FL",
  "steamboat-springs-co": "Steamboat Springs, CO",
  "cleveland-oh": "Cleveland, OH",
  "milawakuee-wi": "Milawakuee, WI",
  "montgomery-al": "Montgomery, AL",
  "gainesville-fl": "Gainesville, FL",
  "south-bend-in": "South Bend, IN",
  "south-lake-tahoe": "South Lake Tahoe, CA",
  "north-charleston-sc": "North Charleston, SC",
  "augusta-ga": "Augusta, GA",
  "east-stroudsburg-pa": "East Stroudsburg, PA",
  "seminole-fl": "Seminole, FL",
  "clearwater-fl": "Clearwater, FL",
  "orange-beach-fl": "Orange Beach, AL",
  "vail-co": "Vail, CO",
  "myrtle-beach-sc": "Myrtle Beach, SC",
  "hilton-head-sc": "Hilton Head, SC",
  "fort-walton-beach-fl": "Fort Walton Beach, FL",
  "san-diego-ca": "San Diego, CA",
  "austin-tx": "Austin, TX",
  "duluth-mn": "Duluth, MN",
  "baxter-mn": "Baxter, MN",
  "ithaca-ny": "Ithaca, NY",
  florida: "Florida",
  arizona: "Arizona",
  california: "California",
  texas: "Texas",
  tennessee: "Tennessee",
  colorado: "Colorado",
  nevada: "Nevada",
  "north-carolina": "North-Carolina",
  "south-carolina": "South-Carolina",
  "south-padre-island-tx": "South Padre Island, TX",
  virginia: "Virginia",
  minnesota: "Minnesota",
  alabama: "Alabama",
  oklahoma: "Oklahoma",
  ohio: "Ohio",
  indiana: "Indiana",
  montana: "Montana",
  "new-york": "New York",
  pennsylvania: "Pennsylvania",
  "pensacola-fl": "Pensacola, FL",
  "folly-beach-sc": "Folly Beach, SC",
  "big-bear-lake-ca": "Big Bear Lake, CA",
  "atlantic-city-nj": "Atlantic City, NJ",
  "new-mexico": "New Mexico",
  "chicago-il": "Chicago, IL",
  "north-las-vegas-nv": "North Las Vegas, NV",
  michigan: "Michigan",
  "key-west-fl": "Key West, FL",
  "key-largo-fl": "Key Largo, FL",
  "brainerd-mn": "Brainerd, MN",
  "broken-bow-ok": "Broken Bow, OK",
  "sevierville-tn": "Sevierville, TN",
  "pigeon-forge-tn": "Pigeon Forge, TN",
  "seattle-wa": "Seattle, WA",
  "indian-rocks-beach-fl": "Indian Rocks Beach, FL",
  "crystal-beach-tx": "Crystal Beach, TX",
  "oceanside-ca": "Oceanside, CA",
  "encinitas-ca": "Encinitas, CA",
  "sarasota-fl": "Sarasota, FL",
  "gufl-breeze-fl": "Gulf Breeze, FL",
  "las-vegas-nv": "Las Vegas, NV",
  "norfolk-va": "Norfolk, VA",
  "south-haven-mi": "South Haven, MI",
  "miramar-beach-fl": "Miramar Beach, FL",
  "island-park-id": "Island Park, ID",
  "raleigh-nc": "Raleigh, NC",
  "lake-harmony-pa": "Lake Harmony, PA",
  "palm-beach-fl": "Palm Beach, FL",
  "charlotte-nc": "Charlotte, NC",
  "saint-paul-mn": "Saint Paul, MN",
  "lake-arrowhead-ca": "Lake Arrowhead, CA",
  "nashville-tn": "Nashville, TN",
  "daytona-beach-fl": "Daytona Beach, FL",
  "naples-fl": "Naples, FL",
  "savannah-ga": "Savannah, GA",
  "mammoth-lakes-ca": "Mammoth Lakes, CA",
  "park-city-ut": "Park City, UT",
  "big-sky-mt": "Big Sky, MT",
  "breinerd-mn": "Breinerd, MN",
  "banner-elk-nc": "Banner Elk, NC",
  "frisco-tx": "Frisco, TX",
  "saint-petersburg-fl": "Saint Petersburg, FL",
  "clermont-fl": "Clermont, FL",
  "bradenton-fl": "Bradenton, FL",
  "venice-fl": "Venice, FL",
  "green-bay-wi": "Green Bay, WI",
  "santa-rosa-beach-fl": "Santa Rosa Beach, FL",
  "greensboro-nc": "Greensboro, NC",
  "jacksonville-fl": "Jacksonville, FL",
  "jasper-ga": "Jasper, GA",
  "lexington-ky": "Lexington, KY",
  "birmingham-al": "Birmingham, AL",
  "logan-oh": "Logan, OH",
  "hot-springs-national-ar": "Hot Springs National, AR",
  "hot-springs-ar": "Hot Springs, AR",
  "marin-county-ca": "Marin County, CA",
  "little-rock-ar": "Little Rock, AR",
  "angel-fire-nm": "Angel Fire, NM",
  "cedar-rapids-ia": "Cedar Rapids, IA",
  "hampton-va": "Hampton, VA",
  "detroit-mi": "Detroit, MI",
  "portland-me": "Portland, ME",
  "port-aransas-tx": "Port Aransas, TX",
  "boise-id": "Boise, ID",
  "fredericksburg-tx": "Fredericksburg, TX",
  "osage-beach-mo": "Osage Beach, MO",
  "boone-nc": "Boone, NC",
  "traverse-city-mi": "Traverse City, MI",
  "grand-rapids-mi": "Grand Rapids, MI",
  "anchorage-ak": "Anchorage, AK",
  "corpus-christi-tx": "Corpus Christi, TX",
  "mount-pleasant-sc": "Mount Pleasant, SC",
  "surfside-beach-sc": "Surfside Beach, SC",
  "wilimington-nc": "Wilimington, NC",
  "winston-salem-nc": "Winston-Salem, NC",
  "taos-nm": "Taos, NM",
  "pleno-tx": "Pleno, TX",
  "irving-tx": "Irving, TX",
  "arlington-tx": "Arlington, TX",
  "breckenridge-co": "Breckenridge,CO",
  "rochester-ny": "Rochester, NY",
  "jackson-wy": "Jackson, WY",
  "spokane-wa": "Spokane, WA",
  "indianapolis-in": "Indianapolis, IN",
  "oklahoma-city-ok": "Oklahoma City, OK",
  "tulsa-ok": "Tulsa, OK",
  "tallahassee-fl": "Tallahassee, FL",
  "orlando-fl": "Orlando, FL",
  "asheville-nc": "Asheville, NC",
  "albuquerque-nm": "Albuquerque, NM",
  "wilmington-nc": "Wilmington, NC",
  "plano-tx": "PLano, TX",
  "newark-nj": "Newark, NJ",
  "jersey-city-ny": "Jersey City, NY",
  "carlsbad-ca": "Carlsbad, CA",
  "cinncinati-oh": "Cinncinati, OH",
  "henderson-nc": "Henderson, NC",
  "tampa-fl": "Tampa, FL",
  "catskill-ny": "Catskill, NY",
  "kihei-hi": "Kihei, HI",
  "hudson-ny": "Hudson, NY",
  "palm-coast-fl": "Palm Coast, FL",
  "saint-augustine-fl": "Saint Augustine, FL",
  "bozeman-mt": "Bozeman, MT",
  "burlington-vt": "Burlington, VT"
};

export default LiveMarkets;
