import React from "react";

interface VerticalDividerProps {
  name: string;
}

interface HorizontalDividerProps {
  name: string;
}

export const VerticalDivider: React.FC<VerticalDividerProps> = ({ name }) => {
  const style = {
    height: "auto",
    width: "3px",
    display: "inline-block",
    background: "linear-gradient(to top, #2F354B, #F4246C)"
  };

  return <div id={name} style={style} />;
};

export const HorizontalDivider: React.FC<HorizontalDividerProps> = ({
  name
}) => {
  const style = {
    height: "3px",
    width: "100%",
    display: "inline-block",
    background: "linear-gradient(to top, #2F354B, #F4246C)"
  };

  return <div id={name} style={style} />;
};
