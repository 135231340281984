import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Text from "../../components/Text";
import Field from "../../components/Field";
import { OptionProp } from "../../components/Select";

import Image from "../../components/Image";
import useCookies from "../../hooks/useCookies";
import Header from "../../components/Header";
import Button from "../../components/Button";
import { Color } from "../../types";
import Modal from "../../components/Modal";
import { AppContext } from "../../components/AppContext";

import SplitView from "../../components/SplitView";

const defaultForm = {
  name: "",
  phone: "",
  email: "",
  propertyValue: ""
  //   consent: true
};

const CostSegModal = () => {
  const { mobileSize, isCostSegModalOpen, setOpenCostSegModal } =
    useContext(AppContext);
  // eslint-disable-next-line
    console.log("isCostSegModalOpen", isCostSegModalOpen);
  // const [isContactUs, setContactUs] = useState(false);
  const [contactUs, setContactUs] = useState(false);
  const { getAllCookies } = useCookies();
  const [thankYou, setThankYou] = useState(false);
  const [errors, setErrors] = useState<any>({
    name: false,
    phone: false,
    email: false,
    propertyValue: false
  });
  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });

  const [form, setForm] = useState(defaultForm);
  const { name, phone, email, propertyValue } = form;

  useEffect(() => {
    async function addContact() {
      try {
        const requestOptions = {
          method: "POST",
          // headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name,
            email,
            phone,
            propertyValue,
            interested_in: "cost-segregation",
            ...cookies,
            consent: true,
            sourcePage: "cost-segregation-modal"
          })
        };
        // const apiData = await API.post("customers", "/contacts", data);
        const apiData = await fetch(
          "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
          requestOptions
        );
        // eslint-disable-next-line
    console.log("api data", apiData);

        setThankYou(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
    console.log("error scheduling consultation:", error);
      }
      setOpenCostSegModal(false);
    }
    if (contactUs) {
      addContact();
    }
  }, [contactUs, form]);

  const allowContactUs = () => {
    const isEveryElementFilled = Object.entries(form).every(
      ([formName, value]: [string, string | boolean]) => {
        if (
          formName !== "password" &&
          formName !== "confirmpassword" &&
          formName !== "verificationcode" &&
          formName !== "other"
        ) {
          if (formName === "consent") {
            return value;
          }
          return value !== "";
        }

        return true;
      }
    );
    if (!isEveryElementFilled) {
      const newErrors = { ...errors };
      Object.entries(form).forEach(
        ([formName, value]: [string, string | boolean]) => {
          if (
            formName !== "password" &&
            formName !== "confirmpassword" &&
            formName !== "verificationcode" &&
            formName !== "other"
          ) {
            newErrors[formName] = value === "";
          }
        }
      );
      setErrors(newErrors);
    }
    return isEveryElementFilled;
  };
  const handleToggle = () => {
    setForm(defaultForm);
    setOpenCostSegModal((prevState: boolean) => !prevState);
  };

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.persist();
    const { name: n, value } = e.target as HTMLInputElement;
    let inputName = n;
    let inputValue = value;
    if (!inputName) {
      inputName = (e as any).name;
      inputValue = ((e as any).value as OptionProp).label as string;
    }
    setForm((prevState) => ({
      ...prevState,
      [inputName]: inputValue
    }));
  };

  const ConsentLabel = () => {
    return (
      <div
        style={{
          fontWeight: 300,
          fontSize: "0.7rem",
          marginTop: "-0.9rem",
          marginBottom: "2rem",
          color: "var(--white)"
        }}
      >
        By submitting this form, you agree to receive subsequent emails and
        third-party marketing communications from Chalet pursuant to our{" "}
        <Link
          to="/privacy-policy"
          target="_blank"
          style={{
            cursor: "pointer",
            display: "inline-block",
            textDecoration: "underline"
          }}
        >
          Privacy Policy
        </Link>
        , which you may opt out of, or unsubscribe from, at any time.
      </div>
    );
  };
  return (
    <Modal
      isOpen={isCostSegModalOpen}
      toggle={handleToggle}
      style={{
        maxWidth: mobileSize ? "100vw" : "95vw",
        background:
          "linear-gradient(234.86deg, #FB929D 3.32%, #BC6A81 100.14%)",
        // background:
        //   "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #FB929D 71.35%)",
        padding: mobileSize ? "1rem" : "1rem",
        maxHeight: mobileSize ? "inherit" : "95vw",
        minHeight: "fit-content"
      }}
    >
      {!thankYou && (
        <div>
          <img
            // src={`${process.env.PUBLIC_URL}/logo.webp`}
            src={`${process.env.PUBLIC_URL}/images/logo_white.png`}
            alt="Chalet"
            style={{
              marginTop: mobileSize ? "0.5rem" : "0rem",
              display: mobileSize ? "none" : "block",
              width: mobileSize ? "inherit" : "12",
              marginBottom: mobileSize ? "0rem" : "-0.5rem",
              position: "absolute",
              maxWidth: "100px",

              top: "1rem",
              left: "1rem"
            }}
          />
          <div>
            <>
              <Header
                size={2}
                style={{
                  paddingBottom: 0,
                  display: mobileSize ? "none" : "block"
                }}
                color={Color.white}
              >
                &apos;Tis The Season. Maximize the Tax Benefits on Your Airbnb
                Rental
              </Header>
              <Text
                block
                style={{
                  maxWidth: mobileSize ? "100%" : "60%",
                  margin: "0rem auto 2rem auto",
                  textAlign: "center",
                  paddingBottom: "1rem",
                  marginBottom: 0,
                  fontWeight: 300,
                  fontSize: "1.5rem",
                  color: "var(--white)"
                }}
              >
                Did you know that you can receive tax savings of{" "}
                <Text
                  style={{
                    fontSize: "1.5rem",
                    // fontWeight: 300,
                    color: "var(--white)",
                    fontWeight: "500"
                  }}
                >
                  6-8%
                </Text>{" "}
                through{" "}
                <Text
                  style={{
                    fontSize: "1.5rem",
                    // fontWeight: 300,
                    color: "var(--white)",
                    fontWeight: "500"
                  }}
                >
                  Cost Segregation
                </Text>{" "}
                and Tangible Property Regulation compliance?
              </Text>
            </>
          </div>
          <SplitView
            style={{
              padding: mobileSize ? "0rem" : "1.5rem",
              paddingTop: mobileSize ? "0" : "1rem",
              //   border: "1px solid var(--white)",
              borderRadius: "0.5rem",
              maxWidth: mobileSize ? "100vw" : "85vw",
              margin: mobileSize ? "0 auto" : "1rem auto"
            }}
          >
            <div
              style={{
                width: mobileSize ? "calc(100% - 0rem)" : "100%",
                padding: 0
              }}
            >
              <Text
                block
                style={{
                  textAlign: "center",
                  paddingBottom: "0.5rem",
                  display: mobileSize ? "none" : "block",
                  fontSize: "1.3rem",
                  color: "var(--white)"
                }}
              >
                Get A Free Estimate & Talk To A Strategist
              </Text>
              <Field
                name="name"
                placeholder="Name"
                type="text"
                value={name}
                invalid={errors.name}
                onChange={onChange}
              />
              <Field
                name="phone"
                placeholder="Phone"
                type="text"
                value={phone}
                invalid={errors.phone}
                onChange={onChange}
              />
              <Field
                name="email"
                placeholder="Email"
                type="email"
                value={email}
                invalid={errors.email}
                onChange={onChange}
              />
              <Field
                name="propertyValue"
                placeholder="Property Value"
                type="propertyValue"
                value={propertyValue}
                invalid={errors.propertyValue}
                onChange={onChange}
              />
              <ConsentLabel />
              {Object.values(errors).some((value) => value) && (
                <Text
                  color={Color.danger}
                  style={{
                    display: "block",
                    padding: "0.5rem",
                    textAlign: "center",
                    width: "100%"
                  }}
                >
                  Fields{" "}
                  {Object.entries(errors)
                    .map(([key, value]) => (value ? key : undefined))
                    .filter((str) => str !== undefined)
                    .join(", ")}{" "}
                  are missing values
                </Text>
              )}
              <Button
                isLoading={contactUs}
                style={{
                  marginBottom: "1rem",
                  width: "100%",
                  background: "var(--white)",
                  fontWeight: 500,
                  padding: "1rem",
                  borderRadius: "0.5rem",
                  textDecoration: "underline",
                  color: "var(--tertiary)"
                }}
                onClick={() => allowContactUs() && setContactUs(true)}
                data-id="modal_contact_us_message_us_button"
              >
                Get A Free Estimate
              </Button>
            </div>
            <div
              style={{
                paddingTop: "0rem"
                //   margin: "1rem",

                //   // maxWidth: "100%",
                //   // maxHeight: "300px",
                //   // margin: "1rem auto",
                //   // display: mobileSize ? "none" : "block"
              }}
            >
              <>
                <Image
                  // src="./images/tax_savings.png"
                  src={`${process.env.PUBLIC_URL}/images/tax_savings.png`}
                  alt="Maximize your tax savings with Cost Segregation Study"
                  style={{
                    // border: "1px solid var(--white)",
                    // boxShadow: mobileSize
                    //   ? "initial"
                    //   : "0 0 43px 10px rgba(0,0,0,0.1)",
                    // border: "1px solid var(--white)",
                    borderRadius: "0.75rem",
                    padding: mobileSize ? "intitia" : "0rem 4rem",
                    maxWidth: "100%",
                    maxHeight: "300px",
                    margin: "0rem auto 1rem auto",
                    display: mobileSize ? "none" : "block"
                  }}
                  $mobileSize={mobileSize}
                />
                <Text
                  block
                  style={{
                    textAlign: "center",
                    fontSize: "1.5rem",
                    color: "var(--white)"
                  }}
                >
                  That&apos;s{" "}
                  <Text
                    style={{
                      fontSize: "1.5rem",
                      color: "var(--white)",
                      fontWeight: "500"
                    }}
                  >
                    $60K-$80K{" "}
                  </Text>
                  for each $1M in building costs of your Airbnb rentals!
                </Text>
              </>
            </div>
          </SplitView>
        </div>
      )}
    </Modal>
  );
};

export default CostSegModal;
