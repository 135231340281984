import React from "react";
import { useParams } from "react-router-dom";
import AnalyticsPage from "./AnalyticsPage";
import AnalyticsMarket from "./AnalyticsMarket";
import AnalyticsMidTermRentalsMarket from "./AnalyticsMidTermRentalsMarket";
import AnalyticsRentalRegulations from "./AnalyticsRentalRegulations";
import AnalyticsAirbnbCalculator from "./AnaytlicsAirbnbCalculator";
import AnalyticsInvestorGuides from "./AnalyticsInvestorGuides";

export default function AnalyticsRoute() {
  const { type, market } = useParams<any>();

  if (type === "overview") {
    if (!market) {
      return <AnalyticsPage />;
    }

    return <AnalyticsMarket />;
  }

  if (type === "airbnb-calculator") {
    return <AnalyticsAirbnbCalculator />;
  }

  if (type === "rental-regulations") {
    return <AnalyticsRentalRegulations />;
  }

  if (type === "mid-term-rentals") {
    return <AnalyticsMidTermRentalsMarket />;
  }

  if (type === "investor-guides") {
    return <AnalyticsInvestorGuides />;
  }

  return <AnalyticsPage />;
}
