import { HTMLAttributes, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

export interface PortalProps extends HTMLAttributes<HTMLDivElement> {
  node?: Element;
}

const Portal = ({ children, node }: PortalProps) => {
  const nodeRef = useRef<any>();

  useEffect(() => {
    const existingNode = document.getElementById("portal-container");
    if (!node && !existingNode) {
      nodeRef.current = document.createElement("div");
      nodeRef.current.id = "portal-container";
      document.body.appendChild(nodeRef.current);
    } else if (!node) {
      nodeRef.current = existingNode;
    } else {
      nodeRef.current = node;
    }
  }, [nodeRef, node]);

  if (nodeRef.current) {
    return createPortal(children, nodeRef.current);
  }

  return null;
};

export default Portal;
