import React, { useContext, useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import Text from "../components/Text";
import Image from "../components/Image";
import Group from "../components/Group";
import Header from "../components/Header";
import SectionFooter from "../components/SectionFooter";
import useCookies from "../hooks/useCookies";
import Input from "../components/Input";
import Button from "../components/Button";
import { Color } from "../types";
import { AppContext } from "../components/AppContext";
import SplitView from "../components/SplitView";
import Section from "../components/Section";

const defaultForm = {
  name: "",
  phone: "",
  email: "",
  market: "",
  interestedIn: "",
  consent: true
};

const ChaletRemoteHost = () => {
  const { mobileSize } = useContext(AppContext);
  const { getAllCookies } = useCookies();
  const [contactUs, setContactUs] = useState(false);
  const [, setThankYou] = useState(false);

  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });

  const [{ annualRevenue }, setAnnualRevenue] = useState<any>({
    annualRevenue: 60000
  });

  const annualSavings = useMemo(() => {
    // eslint-disable-next-line
    console.log("this is called", annualRevenue);
    return `$${annualRevenue * 0.18}`;
  }, [annualRevenue]);

  const [form] = useState(defaultForm);
  const { name, phone, email, market, interestedIn, consent } = form;
  const onInputChange = () => (e: React.FormEvent) => {
    const { value } = e.target as HTMLInputElement;
    // this.setState =
    setAnnualRevenue({ annualRevenue: value });
  };
  useEffect(() => {
    async function addContact() {
      try {
        const requestOptions = {
          method: "POST",
          // headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name,
            email,
            phone,
            market,
            interested_in: interestedIn,
            consent,
            ...cookies,
            sourcePage: "airbnb-consulting"
          })
        };
        // const apiData = await API.post("customers", "/contacts", data);
        await fetch(
          "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
          requestOptions
        );

        setThankYou(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("error scheduling consultation:", error);
      }
      setContactUs(false);
    }
    if (contactUs) {
      addContact();
    }
  }, [contactUs, form]);
  const handleContactUsClick = () => {
    // window.location.href = "https://form.typeform.com/to/ZbQsTdlZ";
    window.location.href =
      "https://form.typeform.com/to/ZbQsTdlZ#market_of_interest=Not Specified&source_page=remote_host";
  };
  const handleCleanersClick = () => {
    window.open("/turnover-services", "_blank");
  };

  const handleCostSegregationClick = () => {
    // window.open("/contact-us");
    window.open("/blog/cost-segregation-analysis-for-airbnb-rentals", "_blank");
  };
  return (
    <div style={{ paddingTop: mobileSize ? "120px" : "208px" }}>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.getchalet.com/remote-manage-your-airbnbs"
        />
        <title>
          Earn $10k+ more by Running Your Airbnb Business With Chalet RemoteHost
        </title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Why pay ongoing property management fees, when you can automate your Airbnb business with Chalet Remote Host.
          Save Thousands of dollars annually. Hosts worldwide can save up to 20% annually by managing their rental remotely with Chalet."
        />
        <meta
          property="og:title"
          content="Earn $10k+ more by Running Your Airbnb Business With Chalet RemoteHost"
        />
        <meta
          property="og:description"
          content="Why pay ongoing property management fees, when you can automate your Airbnb business with Chalet.
          Save Thousands of dollars annually. Hosts worldwide can save up to 20% annually by managing their rental remotely with Chalet."
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/remote-manage-your-airbnbs"
        />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Group
        style={{ marginBottom: "4rem", marginTop: "4rem", background: "none" }}
      >
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit",
            // border: "1px solid var(--tertiary)",
            borderRadius: "0.5rem"
          }}
        >
          <Header size={1} style={{ marginBottom: "0rem", paddingBottom: 0 }}>
            Chalet Remote Host
          </Header>
          <Header size={3} color={Color.quinary} style={{ marginTop: "0" }}>
            Run Your Airbnb Rentals Remotely Like a PRO
          </Header>
          <SplitView
            split="3:2"
            style={{
              alignItems: "start",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"

                // style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                Why pay ongoing property management fees, when you can automate
                your Airbnb business with Chalet and save thousands of dollars
                annually.
              </Text>
              {/* <Text block align="left" style={{ fontWeight: 500 }}>
                Save thousands of dollars annually. Hosts worldwide can save up
                to 20% annually by managing their rental remotely with Chalet.
              </Text> */}
              <Text block align="left">
                Short-term rental hosts use Chalet services to automate their
                Airbnb rentals end to end, maximize their profit and manage
                their portfolio 100% remotely.
              </Text>
              {/* <Text block align="left">
                Potentially save thousands of dollars in taxes by qualifying as
                a material participant
              </Text> */}
              <Button
                block
                style={{ margin: "0rem auto" }}
                // color={Color.tertiary}
                onClick={handleContactUsClick}
                data-id="buy-cta2_sell_button"
              >
                Free Consultation
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",

                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/automate.webp`}
                alt="Run Your Airbnb Rentals Remotely Like a PRO"
                style={{
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "300px",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <div
        className="first"
        style={{
          display: mobileSize ? "none" : "block",
          margin: "1rem auto",
          // display: "block",
          textAlign: "center"
        }}
      >
        <Text
          inverse
          weight={500}
          style={{ fontSize: mobileSize ? "1.8rem" : "2.5rem" }}
        >
          You could save $10k+
        </Text>
        <>&nbsp;</>
        <Text
          weight={200}
          style={{ fontSize: mobileSize ? "1.8rem" : "2.5rem" }}
        >
          by Running Your Airbnb With Chalet
        </Text>
      </div>
      <Section
        style={{
          display: mobileSize ? "none" : "block",
          padding: mobileSize ? "2rem 0.5rem 4rem 0.5rem" : "3rem 2rem",
          marginBottom: mobileSize ? "0rem" : "3rem",
          position: "relative"
        }}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/images/chalet_vs_pm.webp`}
          alt="Save $10k plus by Running Your Airbnb Business With Chalet"
          style={{
            padding: mobileSize ? "0rem" : "0rem",
            // position: "absolute",
            // top: "-9rem",
            // right: "2rem",
            // borderRadius: "0.25rem",
            maxWidth: mobileSize ? "100%" : "100%",
            width: "100%",
            display: mobileSize ? "block" : "block"
          }}
          $mobileSize={mobileSize}
        />
        <div
          style={{
            // border: "1px solid var(--quinary)",
            padding: "0.5rem",
            display: "flex",
            justifyItems: "center",
            width: mobileSize ? "100%" : "50%",
            margin: "0.5rem auto"
            // borderRadius: "0.25rem"
          }}
        >
          <Text
            style={{
              fontSize: "1.3rem",
              // display: "block",
              // width: "100%",
              fontWeight: 400,
              flex: "auto",
              paddingBottom: "0rem",
              textAlign: mobileSize ? "center" : "center"
              // flexGrow: "1"
            }}
          >
            Your Annual Revenue
          </Text>
          <input
            name="Your Annual Revenue"
            style={{
              border: "none",
              fontSize: "1.3rem",
              paddingLeft: mobileSize ? "0" : "0.25rem",
              flex: "auto",
              textAlign: mobileSize ? "right" : "center"
            }}
            readOnly
            value={`$${annualRevenue}`}
          />
        </div>
        <div
          style={{
            position: "relative",
            display: "block"
          }}
        >
          <div
            className="mb1"
            style={{
              width: "100%",
              margin: "0 auto",
              padding: "0 1rem"
            }}
          >
            <input
              list="tickmarks"
              max={200000}
              min={5000}
              // onChange={e => this.onUpdate(e)}
              step={1000}
              type="range"
              // value={this.state.value}
              name="Savings with Chalet"
              placeholder="60000"
              onChange={onInputChange()}
              style={{
                width: mobileSize ? "100%" : "80%",
                margin: "0 auto",
                display: "block"
              }}
            />
          </div>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            marginTop: "2rem",
            padding: 0,
            alignItems: "center"
          }}
        >
          <Input
            name="Your Savings"
            // label={savings}
            style={{
              background: "var(--secondary)",
              color: "var(--white)",
              border: "none",
              display: "block",
              textAlign: "center",
              padding: "0.5rem",
              fontSize: "2.5rem",
              fontWeight: 500,
              width: "300px",
              margin: "0 auto"
            }}
            readOnly
            value={annualSavings}
            // onChange={onInputChange()}
          />
        </div>
        <div
          style={{
            position: "relative",
            width: "fit-content",
            margin: "0 auto"
          }}
        >
          <span
            style={{
              fontSize: "1.5rem",
              width: "100%",
              marginTop: "-0.5rem",
              display: "block",
              textAlign: "center"
              // flexGrow: "2"
            }}
          >
            Your Savings
          </span>
          <span
            style={{
              fontSize: "1rem",
              // width: "100%",
              // marginTop: "-0.5rem",
              // display: "block",
              textAlign: "center",
              position: "absolute",
              top: 0,
              right: "-0.3rem"
              // flexGrow: "2"
            }}
          >
            *
          </span>
        </div>
        <Text
          style={{
            fontStyle: "italic",
            marginTop: "4rem",
            fontSize: "1rem",
            paddingBottom: 0
          }}
          block
        >
          * Compared to a traditional property management firm charging 20% of
          net revenue
        </Text>
        {/* <Button
          isLoading={contactUs}
          style={{
            maxWidth: "350px",
            width: "100%",
            fontSize: "2rem",
            padding: "1rem",
            margin: "2rem auto",
            background:
              "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #FB929D 71.35%)"
          }}
          onClick={() => allowContactUs() && setContactUs(true)}
          data-id="modal_contact_us_message_us_button"
        >
          Learn How
        </Button> */}
      </Section>

      <Group
      // style={{
      //   background:
      //     "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #FB929D 71.35%)"
      // }}
      >
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit",
            background: "none",
            padding: mobileSize ? "1rem" : "2rem"
          }}
        >
          <Header size={2} style={{ paddingBottom: 0 }}>
            Automate. Optimize. Operate 100% Remotely.
          </Header>
          <div
            style={{
              display: mobileSize ? "none" : "block",
              margin: "0 auto",
              textAlign: "center"
            }}
          >
            <Text color={Color.quinary} style={{ fontWeight: "500" }}>
              Market is changing.
            </Text>

            <Text
              style={{
                paddingBottom: 0
              }}
            >
              Why pay ongoing property management fees? There’s{" "}
              <Text color={Color.quinary} style={{ fontWeight: "500" }}>
                a new way
              </Text>{" "}
              to{" "}
              <Text color={Color.quinary} style={{ fontWeight: "500" }}>
                maximize returns{" "}
              </Text>{" "}
              on your Airbnb Rentals
            </Text>
          </div>

          <Text
            block
            style={{
              paddingBottom: 0,
              margin: "0 auto",
              width: "fit-content",
              textAlign: "center",
              color:
                "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #FB929D 71.35%)"
            }}
          >
            Everything You Need to Run Your Business{" "}
            <Text color={Color.quinary} style={{ fontWeight: "500" }}>
              100% remotely{" "}
            </Text>{" "}
            and <Text>save up to 20%**</Text>
          </Text>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: "4rem",
              flexFlow: "wrap"
            }}
          >
            <div
              style={{
                display: "flex",
                width: mobileSize ? "100%" : "33%",
                height: mobileSize ? "100%" : "350px",
                minWidth: "240px",
                marginBottom: "3rem",
                flexFlow: mobileSize ? "row wrap" : "initial",
                flexDirection: mobileSize ? "row" : "column",

                padding: "1rem"
              }}
            >
              <div style={{ marginBottom: "2rem", marginRight: "2rem" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/optimize_listing.webp`}
                  alt="Airbnb & VRBO listings setup "
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "85px",
                    maxHeight: mobileSize ? "100%" : "60px",
                    minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div style={{ maxWidth: mobileSize ? "100%" : "285px" }}>
                <Header
                  size={5}
                  // color={Color.quinary}
                  style={{ paddingBottom: "1rem", textAlign: "left" }}
                >
                  Airbnb & VRBO Listing Setup
                </Header>
                <Text style={{ fontSize: "1rem", lineHeight: "1.5rem" }}>
                  Fully customized property listing for your home designed with
                  SEO in mind to rank higher on Airbnb/VRBO search. High
                  converting listing titles and descriptions designed by Airbnb
                  PROs. Meticulously designed digital guidebooks and embedded it
                  into automated messaging flow to improve guest experience and
                  reduce overhead.
                </Text>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: mobileSize ? "100%" : "33%",
                height: mobileSize ? "100%" : "350px",
                minWidth: "240px",
                marginBottom: "3rem",
                flexFlow: mobileSize ? "row wrap" : "initial",
                flexDirection: mobileSize ? "row" : "column",

                padding: "1rem"
              }}
            >
              <div style={{ marginBottom: "2rem", marginRight: "2rem" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/photographer.webp`}
                  alt="Airbnb & VRBO photographers"
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "85px",
                    maxHeight: mobileSize ? "100%" : "60px",
                    minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div style={{ maxWidth: mobileSize ? "100%" : "285px" }}>
                <Header
                  size={5}
                  // color={Color.quinary}
                  style={{ paddingBottom: "1rem", textAlign: "left" }}
                >
                  Professional Photography
                </Header>
                <Text style={{ fontSize: "1rem", lineHeight: "1.5rem" }}>
                  Much like hiring a professional property manager to help lower
                  your days on the market, an experienced Airbnb photographer
                  can help generate a 40% increase in earnings, a whopping 24%
                  more bookings, and even a 26% increase in your nightly price.
                  Chalet connects you with best photographers in your market at
                  no cost.
                </Text>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: mobileSize ? "100%" : "33%",
                height: mobileSize ? "100%" : "350px",
                minWidth: "240px",
                marginBottom: "3rem",
                flexFlow: mobileSize ? "row wrap" : "initial",
                flexDirection: mobileSize ? "row" : "column",

                padding: "1rem"
              }}
            >
              <div style={{ marginBottom: "2rem", marginRight: "2rem" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/smart_messaging.webp`}
                  alt="Airbnb & VRBO automated messaging"
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "85px",
                    maxHeight: mobileSize ? "100%" : "60px",
                    minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div style={{ maxWidth: mobileSize ? "100%" : "285px" }}>
                <Header
                  size={5}
                  // color={Color.quinary}
                  style={{ paddingBottom: "1rem", textAlign: "left" }}
                >
                  Smart Automated Messaging
                </Header>
                <Text style={{ fontSize: "1rem", lineHeight: "1.5rem" }}>
                  Put your messaging on autopilot. We designed the proven system
                  to improve guest satisfaction and get repeated 5 star reviews.
                  Messaging copy designed by PROs along with real-world tested
                  scheduling, will nurture your guests from inquiry to 5-star
                  review and guaranteed to reduce the manual back and forth with
                  the guests.
                </Text>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: mobileSize ? "100%" : "33%",
                height: mobileSize ? "100%" : "350px",
                minWidth: "240px",
                marginBottom: "3rem",
                flexFlow: mobileSize ? "row wrap" : "initial",
                flexDirection: mobileSize ? "row" : "column",

                padding: "1rem"
              }}
            >
              <div style={{ marginBottom: "2rem", marginRight: "2rem" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/smart_pricing.webp`}
                  alt="Airbnb & VRBO intelligent pricing"
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "85px",
                    maxHeight: mobileSize ? "100%" : "60px",
                    minHeight: mobileSize ? "100%" : "60px",

                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div style={{ maxWidth: mobileSize ? "100%" : "285px" }}>
                <Header
                  size={5}
                  // color={Color.quinary}
                  style={{ paddingBottom: "1rem", textAlign: "left" }}
                >
                  Intelligent Pricing Strategies
                </Header>
                <Text style={{ fontSize: "1rem", lineHeight: "1.5rem" }}>
                  Save time and increase income with dynamic pricing tool. You
                  will have intelligent pricing driven by local supply and
                  demand so you never miss out on extra revenue. Chalet PROs
                  provides setup and training.
                </Text>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: mobileSize ? "100%" : "33%",
                height: mobileSize ? "100%" : "350px",
                minWidth: "240px",
                marginBottom: "3rem",
                flexFlow: mobileSize ? "row wrap" : "initial",
                flexDirection: mobileSize ? "row" : "column",

                padding: "1rem"
              }}
            >
              <div style={{ marginBottom: "2rem", marginRight: "2rem" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/airbnb_regulation.webp`}
                  alt="Airbnb & VRBO regulation"
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "85px",
                    maxHeight: mobileSize ? "100%" : "60px",
                    minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div style={{ maxWidth: mobileSize ? "100%" : "285px" }}>
                <Header
                  size={5}
                  // color={Color.quinary}
                  style={{ paddingBottom: "1rem", textAlign: "left" }}
                >
                  Checklists & Layout Recommendations
                </Header>
                <Text style={{ fontSize: "1rem", lineHeight: "1.5rem" }}>
                  Our expert team provide you with a recommended amenities and
                  supplies checklist to make sure your guests have everything
                  they need for a comfortable stay. We will review the layout of
                  each property and make recommendations for furniture to
                  enhance the guest experience. Elevate your short-term rental
                  game and make sure your property gets 5-star reviews!
                </Text>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: mobileSize ? "100%" : "33%",
                height: mobileSize ? "100%" : "350px",
                background: "#F8F0F2",
                borderRadius: "0.5rem",
                boxShadow: "0px 32px 43px rgba(0, 0, 0, 0.1)",
                padding: "1rem",
                minWidth: "240px",
                marginBottom: "3rem",
                flexFlow: "row wrap",
                flexDirection: mobileSize ? "row" : "column"
              }}
            >
              <div
                style={{
                  marginBottom: "2rem",
                  marginRight: "2rem",
                  position: "relative"
                }}
              >
                <Image
                  src={`${process.env.PUBLIC_URL}/images/chalet_vetted_cleaners.webp`}
                  alt="Airbnb & VRBO cleaners/turnover services"
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "85px",
                    maxHeight: mobileSize ? "100%" : "60px",
                    minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div style={{ maxWidth: mobileSize ? "100%" : "285px" }}>
                <Header
                  size={5}
                  // color={Color.quinary}
                  style={{ paddingBottom: "1rem", textAlign: "left" }}
                >
                  Chalet Vetted Airbnb Cleaners
                </Header>
                <Text style={{ fontSize: "1rem", lineHeight: "1.5rem" }}>
                  Let the cleaners be your eyes and your boots on the ground.
                  Chalet Partner cleaners connect directly to your system. No
                  manual scheduling. Get notified when clean is done, offer your
                  guests early check-in.
                </Text>
                <Button
                  block
                  style={{
                    margin: "1rem auto",
                    display: mobileSize ? "none" : "block",
                    position: "relative",
                    width: "100%",
                    paddingTop: "0.25rem",
                    paddingBottom: "0.25rem",
                    background:
                      "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #FB929D 71.35%)"
                  }}
                  color={Color.tertiary}
                  onClick={handleCleanersClick}
                  data-id="buy-cta2_sell_button"
                >
                  Learn More
                </Button>
              </div>
              <Button
                block
                style={{
                  margin: "1rem auto",
                  display: mobileSize ? "block" : "none",
                  position: "relative",
                  width: "100%",
                  paddingTop: "0.25rem",
                  paddingBottom: "0.25rem",
                  background:
                    "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #FB929D 71.35%)"
                }}
                color={Color.tertiary}
                onClick={handleCleanersClick}
                data-id="buy-cta2_sell_button"
              >
                Learn More
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                width: mobileSize ? "100%" : "33%",
                height: mobileSize ? "100%" : "350px",
                minWidth: "240px",
                marginBottom: "3rem",
                flexFlow: mobileSize ? "row wrap" : "initial",
                flexDirection: mobileSize ? "row" : "column",

                padding: "1rem"
              }}
            >
              <div style={{ marginBottom: "2rem", marginRight: "2rem" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/pms.webp`}
                  alt="Airbnb & VRBO property management software"
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "85px",
                    maxHeight: mobileSize ? "100%" : "60px",
                    minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div style={{ maxWidth: mobileSize ? "100%" : "285px" }}>
                <Header
                  size={5}
                  // color={Color.quinary}
                  style={{ paddingBottom: "1rem", textAlign: "left" }}
                >
                  Property Management Software
                </Header>
                <Text style={{ fontSize: "1rem", lineHeight: "1.5rem" }}>
                  Use the tool pros use to automate their processes from start
                  to finish. Utilize software to automate calendar sync so that
                  you NEVER EVER receive a double booking. Get visibility to
                  more STR platforms.
                </Text>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: mobileSize ? "100%" : "33%",
                height: mobileSize ? "100%" : "350px",
                minWidth: "240px",
                marginBottom: "3rem",
                background: "#F0F3F8",
                flexFlow: mobileSize ? "row wrap" : "initial",
                flexDirection: mobileSize ? "row" : "column",
                borderRadius: "0.5rem",
                boxShadow: "0px 32px 43px rgba(0, 0, 0, 0.1)",
                padding: "1rem"
              }}
            >
              <div style={{ marginBottom: "2rem", marginRight: "2rem" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/taxes.webp`}
                  alt="Maximize tax benefits on your Airbnb & VRBO"
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "85px",
                    maxHeight: mobileSize ? "100%" : "60px",
                    minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div style={{ maxWidth: mobileSize ? "100%" : "285px" }}>
                <Header
                  size={5}
                  // color={Color.quinary}
                  style={{ paddingBottom: "1rem", textAlign: "left" }}
                >
                  Maximize tax benefits
                </Header>
                <Text style={{ fontSize: "1rem", lineHeight: "1.5rem" }}>
                  Why pay more taxes than you need to pay? There are massive tax
                  benefits available only to investors who are actively involved
                  (material participants) in managing their Airbnb Rentals.
                </Text>
                <Button
                  block
                  style={{
                    margin: "1rem auto",
                    paddingTop: "0.25rem",
                    display: mobileSize ? "none" : "block",
                    paddingBottom: "0.25rem",
                    width: mobileSize ? "100%" : "initial",
                    position: "relative",
                    background:
                      "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #FB929D 71.35%)"
                  }}
                  color={Color.tertiary}
                  onClick={handleCostSegregationClick}
                  data-id="buy-cta2_sell_button"
                >
                  Learn More
                </Button>
              </div>
              <Button
                block
                style={{
                  margin: "1rem auto",
                  paddingTop: "0.25rem",
                  display: mobileSize ? "block" : "none",
                  paddingBottom: "0.25rem",
                  width: mobileSize ? "100%" : "initial",
                  position: "relative",
                  background:
                    "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #FB929D 71.35%)"
                }}
                color={Color.tertiary}
                onClick={handleCostSegregationClick}
                data-id="buy-cta2_sell_button"
              >
                Learn More
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                width: mobileSize ? "100%" : "33%",
                height: mobileSize ? "100%" : "350px",
                minWidth: "240px",
                marginBottom: "3rem",
                flexFlow: mobileSize ? "row wrap" : "initial",
                flexDirection: mobileSize ? "row" : "column",

                padding: "1rem"
              }}
            >
              <div style={{ marginBottom: "2rem", marginRight: "2rem" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/one_on_one.webp`}
                  alt="One on One with Airbnb Experts"
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "85px",
                    maxHeight: mobileSize ? "100%" : "60px",
                    minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div style={{ maxWidth: mobileSize ? "100%" : "285px" }}>
                <Header
                  size={5}
                  // color={Color.quinary}
                  style={{ paddingBottom: "1rem", textAlign: "left" }}
                >
                  One on One with Airbnb Experts
                </Header>
                <Text style={{ fontSize: "1rem", lineHeight: "1.5rem" }}>
                  After we have completed all items above, we will provide
                  training on Airbnb, VRBO, the PMS, and the smart pricing tool.
                  We will also make general recommendations on best practices
                  for hosting guests. The goal is to “hand-off” all tools and
                  knowledge so you can effectively manage the property moving
                  forward.
                </Text>
              </div>
            </div>
          </div>
          {/* <SectionFooter $mobileSize={mobileSize} /> */}
          <Button
            block
            style={{
              margin: "2rem auto",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              fontSize: "1.5rem",
              background:
                "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #FB929D 71.35%)",
              borderRadius: "0.75rem",
              textDecoration: "underline",
              textTransform: "initial"
            }}
            color={Color.tertiary}
            onClick={handleContactUsClick}
            data-id="buy-cta2_sell_button"
          >
            Put Your Airbnb Rentals On Autopilot Now
          </Button>
        </Section>
        <Text
          style={{ fontSize: "0.8rem", fontStyle: "italic", padding: "2rem" }}
        >
          **When compared to a full service property management firm charging
          20%. Minimum $60,000 in revenue requiered to qualify for $10,000 in
          savings.
        </Text>
      </Group>
    </div>
  );
};

export default ChaletRemoteHost;
