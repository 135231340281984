import { Doughnut } from "react-chartjs-2";
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, defaults } from "chart.js";
import styled from "styled-components";
import { Colors } from "../../../components/GlobalStyle";
import Flex from "../../../components/Flex";
import { Font } from "../../../components/Text";

ChartJS.register(ArcElement, Tooltip);

defaults.font.family = Font.Oswald;

const ChartTitle = styled(Flex)`
  align-items: center;
  color: var(--white);
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  pointer-events: none;

  & > div {
    color: var(--white);
    &:first-child {
      font-size: 1rem;
    }
    &:last-child {
      font-size: 0.75rem;
    }
  }

  @media (max-width: 480px) {
    flex: 1 1 100%;
    width: 100%;

    & > div {
      color: var(--white);
      &:first-child {
        font-size: 0.75rem;
        font-weight: 700;
      }
    }
  }
`;

export default function GaugeChart({
  data,
  title
}: {
  data: number[];
  title?: string[];
}) {
  return (
    <>
      {title && (
        <ChartTitle>
          {title.map((str) => (
            <div key={str}>{str}</div>
          ))}
        </ChartTitle>
      )}
      <Doughnut
        data={{
          datasets: [
            {
              label: "Annual Revenue",
              data,
              backgroundColor: [Colors.seventh, Colors.white]
            }
          ]
        }}
        options={{
          aspectRatio: 1.75,
          cutout: "85%",
          responsive: true,
          elements: {
            arc: {
              borderRadius: 32,
              borderWidth: 0
            }
          },
          layout: {
            padding: {
              left: 16,
              right: 16,
              top: 16,
              bottom: 16
            }
          },
          plugins: {
            datalabels: {
              display: false
            }
          },
          rotation: 202,
          circumference: 315
        }}
      />
    </>
  );
}
