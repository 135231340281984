import styled from "styled-components";
import React from "react";

const StyledSpreadList = styled.ul<
  React.HTMLAttributes<HTMLUListElement> & { count: number }
>`
  align-content: space-between;
  align-items: center;
  display: grid;
  height: calc(100% - 42px);
  grid-template-rows: repeat(${(props) => props.count}, 1fr);
  justify-content: space-between;
  margin: 0;
  padding-left: 1rem;
`;

const SpreadList = ({
  children,
  ...props
}: React.HTMLAttributes<HTMLUListElement>) => (
  <StyledSpreadList count={React.Children.count(children)} {...props}>
    {children}
  </StyledSpreadList>
);

export default SpreadList;
