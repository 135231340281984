import React, { useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import { AppContext } from "../../components/AppContext";
import Text from "../../components/Text";
import Header from "../../components/Header";
import useCookies from "../../hooks/useCookies";
import Flex from "../../components/Flex";
import SectionFooter from "../../components/SectionFooter";
import SplitView from "../../components/SplitView";
import Group from "../../components/Group";
import Section from "../../components/Section";
import { Color } from "../../types";
import LiveMarkets from "../../liveMarkets";
import Image from "../../components/Image";

const Container = styled(Flex)`
  // flex-direction: column;
  font-size: 2.5rem;
  height: 100vh;
  // width: 50%;
  justify-content: center;
  line-height: 1;
  h1 {
    font-weight: 300;
    font-size: 3.5rem;
    // text-align: center;
    margin-bottom: 0;
    text-align: left;
    // padding-bottom: 1rem;
  }
  h3 {
    font-weight: 300;
    font-size: 1.6rem;
    // text-align: center;
    margin: 0;
  }
  video {
    margin: 2rem;
    max-width: 450px;
    border-radius: 0.5rem;
  }
  padding: 5rem 0;
  .first ${Text} {
    white-space: nowrap;
    font-size: 3.5rem;
  }
  .second h1 {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
    color: var(--tertiary);
  }
  .third ${Text} {
    font-size: 1.5rem;
  }
  .first {
    padding: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
    // width: 50%;
  }
  .second,
  .third {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-top: 0.75rem;
    padding: 1rem;
    padding-left: 1rem;
    // width: 50%;
  }
  .second {
    padding-top: 0;
  }
  .third {
    // display: block;
    // text-align: left;
    padding: 0rem;
  }
  ${Group} {
    :first-of-type {
      margin-top: 6rem;
    }
  }

  @media (max-width: 810px) {
    // justify-content: start;
    align-items: flex-start;
    margin-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    h3 {
      font-size: 2rem;
    }
    .first {
      text-align: center;
      h1 {
        font-size: 2.2rem;
        text-align: left;
        ${Text} {
          font-size: 2.2rem;
          text-align: left;
          line-height: 2.2rem;
        }
      }
    }
    .second {
      // display: none;
      h3 {
        font-size: 1.2rem;
        text-align: left;
        ${Text} {
          font-size: 1.2rem !important;
        }
      }
    }
    video {
      display: none;
    }
    .second {
      padding-bottom: 1rem;
    }
    .third ${Text} {
      padding-bottom: 1rem;
    }
    .second,
    .third {
      text-align: center;
    }
    .third {
      flex-direction: column;
    }
  }
`;

const VacationRentalAgents = () => {
  const { getAllCookies } = useCookies();
  const { marketPathParam } = useParams<any>();

  const { mobileSize } = useContext(AppContext);

  const bodyRef = useRef<HTMLDivElement>();

  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });
  const handleInvestorGuideClick = () => {
    let investorGuideSlug = marketPathParam;
    if (marketPathParam === "memphis-tn") {
      investorGuideSlug = "memphis-tn-investor-guide-gross-yields-from-24";
    } else if (marketPathParam === "fort-lauderdale-fl") {
      investorGuideSlug = "broward-county-fl-investor-guide-airbnb-rentals";
    } else if (marketPathParam === "san-diego-county-ca") {
      investorGuideSlug = "encinitas-carlsbad-oceanside-ca";
    } else if (marketPathParam === "lake-worth-fl") {
      investorGuideSlug = "broward-county-fl-investor-guide-airbnb-rentals";
    }
    window.open(`/investor-guides/${investorGuideSlug}`, "_blank");
  };

  const titleString = `Discover Lucrative Vacation Rentals with Top Agents | ${
    LiveMarkets[marketPathParam] || ""
  }`;
  //   const descriptionString = `Find the perfect short-term rental investment in the ${
  //     LiveMarkets[marketPathParam] || ""
  //   } market with the help of a local expert. Our top Airbnb real estate agents
  //    know the market inside and out and can guide you to the best
  //    investment with the highest ROI. Connect with Chalet today`;

  const descriptionString = `Unlock the potential of vacation rental investments in  ${
    `the${LiveMarkets[marketPathParam]}` || "any"
  } market with Chalet. Discover your perfect retreat and turn your vacations into profitable assets with expert guidance. Start your journey to financial freedom and unforgettable getaways today!`;

  const urlSlug = marketPathParam
    ? `https://www.getchalet.com/vacation-rental-agents/${marketPathParam}`
    : "https://www.getchalet.com/vacation-rental-agents";
  return (
    <>
      <Helmet>
        <link rel="canonical" href={urlSlug} />
        <title>{titleString}</title>
        <meta property="og:type" content="website" />
        <meta name="description" content={descriptionString} />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={descriptionString} />
        <meta property="og:image" content="" />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Container ref={bodyRef as any}>
        <div
          style={{
            // maxWidth: mobileSize ? "100%" : "50%",
            paddingRight: mobileSize ? "0" : "4rem"
          }}
        >
          <div className="first">
            <h1 style={{ lineHeight: mobileSize ? "2.6rem" : "4.8rem" }}>
              Discover Lucrative Vacation Rentals with Top Agents
            </h1>
          </div>
          <div className="second">
            <h3>
              <Text
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 400,
                  color: "var(--quinary)"
                }}
              >
                Connect with Leading Agents for Prime Vacation Rental
                <Text style={{ fontSize: mobileSize ? "2rem" : "1.6rem" }}>
                  &nbsp;Investments{marketPathParam && " in"}
                </Text>{" "}
                <Text
                  style={{
                    color: "var(--tertiary)",
                    fontWeight: "500",
                    // textDecoration: "underline",
                    fontSize: mobileSize ? "2rem" : "1.6rem"
                  }}
                >
                  {LiveMarkets[marketPathParam]}
                </Text>
              </Text>
              &nbsp;
              <Text
                // block
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 500
                  // color: "var(--quaternary)",
                  // background: "var(--tertiary)",
                  // padding: "1rem",
                  // marginTop: "-1.75rem"
                }}
              >
                {/* {!marketPathParam && "in "}{" "} */}
                {!marketPathParam && <a href="#locations">in top US markets</a>}
              </Text>
            </h3>
          </div>
          <div className="third">
            <PopupButton
              id="DU51pTsh"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--quinary)"
                  : "var(--quinary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem"
              }}
              className="connect-with-realtor_popup_top"
            >
              Connect With A Real Estate Agent
            </PopupButton>
            {/* <PopupButton
              id="ZbQsTdlZ"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--tertiary)"
                  : "var(--tertiary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem"
              }}
              className="connect-with-realtor_popup_top"
            >
              Talk To A STR Expert First
            </PopupButton> */}
            <a
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--tertiary)"
                  : "var(--tertiary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                fontSize: "1rem",

                cursor: "pointer",
                display: mobileSize ? "block" : "initial",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "0rem auto" : "1rem"
              }}
              data-id="learn-more_button_top"
              href="#section1"
              title="/buy"
            >
              Learn More
            </a>
          </div>
          <div
            className="fourth"
            style={{
              display: mobileSize ? "none" : "none",
              marginTop: "5rem"
            }}
          >
            <h3 style={{ color: "var(--white)", textAlign: "center" }}>
              Buy
              <Text
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 400,
                  color: "var(--white)"
                }}
              >
                &nbsp;the best Short-Term Rental investments
                <Text
                  style={{
                    fontSize: mobileSize ? "2rem" : "1.6rem",
                    textAlign: "center"
                  }}
                >
                  {marketPathParam && " in"}
                </Text>{" "}
                <Text
                  style={{
                    color: "var(--white)",
                    fontWeight: "500",
                    // textDecoration: "underline",
                    textAlign: "center",
                    fontSize: mobileSize ? "2rem" : "1.6rem"
                  }}
                >
                  {LiveMarkets[marketPathParam]}
                </Text>
              </Text>
              &nbsp;with top real estate agents
              <Text
                // block
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 500,
                  color: "var(--white)",
                  textAlign: "center"
                  // background: "var(--tertiary)",
                  // padding: "1rem",
                  // marginTop: "-1.75rem"
                }}
              >
                {!marketPathParam && " in "}{" "}
                {!marketPathParam && (
                  <a
                    style={{
                      textAlign: "center",
                      color: "var(--quaternary)",
                      background: "rgba(255,255,255,0.3)",
                      padding: "0.5rem",
                      marginTop: "0.2rem",
                      borderRadius: "0.5rem",
                      display: "block"
                    }}
                    href="#locations"
                  >
                    most US markets
                  </a>
                )}
              </Text>
            </h3>
          </div>
        </div>
        <div style={{ display: "block", maxWidth: "50%" }}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.webp`}
            alt="Vacation Real Estate Agents With STR Investing Expertise"
            $mobileSize={mobileSize}
            style={{
              gridRowStart: mobileSize ? "1" : "inherit",
              maxWidth: mobileSize ? "100%" : "300px",
              display: mobileSize ? "none" : "block",
              borderRadius: "0.25rem"
            }}
          />
        </div>
      </Container>
      <Group id="section1">
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit",
            maxWidth: "100%"
          }}
        >
          <Header size={2}>
            Welcome to Your Ultimate Vacation Rental Investment Hub
          </Header>
          <SplitView
            split="2:3"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "right",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/invest-with-experts.webp`}
                alt="Buy your Vacation rental with Chalet"
                style={{
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "300px",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"

                // style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                At Chalet, we understand the allure of vacation rentals. Whether
                you&apos;re envisioning a cozy cabin nestled in the mountains or
                a beachfront oasis with panoramic ocean views, we&apos;re here
                to turn your dream into a profitable reality.
              </Text>
              <Text block align="left">
                Our platform bridges the gap between investors and top-rated
                real estate agents who specialize in vacation rentals, ensuring
                a seamless and successful investment journey{" "}
                {marketPathParam && "in"} {LiveMarkets[marketPathParam]}
              </Text>
              {marketPathParam && (
                <Text
                  align="left"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "var(--quinary)"
                  }}
                  onClick={handleInvestorGuideClick}
                >
                  Read our Investor Guide To Vacation Rentals In{" "}
                  {LiveMarkets[marketPathParam]}
                </Text>
              )}
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group
        style={{
          background:
            "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #DB005F 36.46%, #2F354B 67.71%, #FB929D 71.35%)",
          maxWidth: "100%"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            background: "none",
            maxWidth: "100%"
          }}
        >
          <Header size={2} color={Color.white}>
            Investing in Memories, Profits, and Freedom
          </Header>
          <SplitView
            split="3:2"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"
                style={{ lineHeight: "2.5rem" }}
                color={Color.white}
              >
                Picture yourself escaping to your very own oasis—a charming
                cottage nestled in the mountains, a stylish urban loft in the
                heart of the city, or a serene beachfront villa with panoramic
                ocean views. Whatever your dream retreat looks like, our team of
                expert real estate agents specializes in finding properties that
                match your vision and investment goals.
              </Text>
              <Text
                block
                align="left"
                style={{ lineHeight: "2.5rem" }}
                color={Color.white}
              >
                They have the skills and expertise to help you make informed
                investment decisions and reach your investment goals{" "}
                {marketPathParam && "in"} {LiveMarkets[marketPathParam]}
              </Text>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/1031-exchange.webp`}
              alt="Local STR Experts With a Proven Track Record"
              $mobileSize={mobileSize}
              style={{
                display: "flex",
                gridRowStart: mobileSize ? "1" : "inherit",
                justifyContent: "right",
                margin: "0 auto",
                maxWidth: mobileSize ? "100vw" : "300px"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group
        style={{
          paddingBottom: "0rem",
          maxWidth: "100%",
          paddingTop: "0rem"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            maxWidth: "100%",
            background: "none"
          }}
        >
          <Header size={2}>Turn Your Vacation into an Investment</Header>
          <SplitView
            split="1:2"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <Image
              src={`${process.env.PUBLIC_URL}/images/investor-guides3.0.webp`}
              alt="Top locations for Vaction Rental Investment"
              $mobileSize={mobileSize}
              style={{
                padding: "0rem",
                // pos/ition: "absolute",
                // left: 0,
                // bottom: mobileSize ? "-8rem" : "-3rem",
                maxWidth: mobileSize ? "400px" : "100%",
                // display: "block",
                borderRadius: "0.25rem"
              }}
            />
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text block align="left">
                Owning a vacation rental property isn&apos;t just about enjoying
                vacations—it&apos;s about smart investing. With the right
                guidance and strategy, your vacation home can become a valuable
                asset, generating passive income year-round.
              </Text>
              <Text block align="left">
                They will help you understand the potential return on investment
                and make sure that your investment is based on accurate and
                trustworthy information {marketPathParam && "in"}{" "}
                {LiveMarkets[marketPathParam]}
              </Text>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group style={{ background: "var(--tertiary)" }}>
        <Section
          style={{
            maxWidth: "100%",
            background: "none",
            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.white}
          >
            Experience Peace of Mind with Professional Support
          </Header>
          <SplitView
            split="2:1"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
              // margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text block align="left" color={Color.white}>
                Navigating the world of vacation rentals can be daunting, but
                you don&apos;t have to do it alone. Our dedicated team provides
                personalized support and guidance every step of the way.
              </Text>
              <Text block align="left" color={Color.white}>
                From market insights to property management solutions,
                we&apos;re committed to your success and peace of mind.
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: mobileSize ? "center" : "left",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/chalet-partner-vendors.webp`}
                alt="Vaction Rental Investor Guides. Free Market Reports. Median Revenue, ADR, Occupacny rates"
                style={{
                  padding: "0rem",
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "300px",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group
        style={{
          position: "relative"
        }}
      >
        <Section
          style={{
            maxWidth: "100%",
            background: "var(--tertiary)",

            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.white}
            style={{ zIndex: 200, position: "relative" }}
          >
            Connect with a Vacation Rental Agent Now
          </Header>
          <Image
            src={`${process.env.PUBLIC_URL}/images/vacation_home.webp`}
            alt="Vacation Rental Investor Guides. Free Market Reports. Median Revenue, ADR, Occupacny rates"
            style={{
              padding: "0rem",
              position: "absolute",
              left: 0,
              top: 0,
              opacity: "0.1",
              // zIndex: "0",
              // right: 0,
              // borderRadius: "2rem",
              maxWidth: mobileSize ? "100%" : "100%",
              width: "100%"
            }}
            $mobileSize={mobileSize}
          />
          <div
            style={{
              gridRowStart: mobileSize ? "2" : "inherit",
              paddingBottom: mobileSize ? "inherit" : "0",
              paddingTop: mobileSize ? "inherit" : "0",
              position: "relative",
              zIndex: 1
            }}
          >
            <Text block align="center" color={Color.white}>
              Instantly connect with a vacation rental real estate agent and
              start realizing your investment goals in the{" "}
              {LiveMarkets[marketPathParam]} short-term rental market.
            </Text>
            <PopupButton
              id="DU51pTsh"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug
              }}
              shareGaInstance="true"
              className="connect-with-realtor_popup_bottom"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                // backgroundColor: "var(--tertiary)",
                background:
                  "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #db005f 71.35%)",
                textTransform: "uppercase",
                display: "block",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                // display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
            >
              Connect With A Real Estate Agent
            </PopupButton>
          </div>
        </Section>
      </Group>
    </>
  );
};

export default VacationRentalAgents;
