import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  defaults
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Colors } from "../../../components/GlobalStyle";
import {
  compactNotation,
  numberNotation,
  percentNotation
} from "../../../utils/numberFormatters";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import { Font } from "../../../components/Text";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
);

defaults.font.family = Font.Oswald;

export default function LineChart({
  color = Colors.quaternary,
  data,
  min = 0,
  y = true,
  percent
}: {
  color?: Colors;
  data: Record<string, any>;
  min?: number | null;
  percent?: boolean;
  y?: boolean;
}) {
  const labels: string[] = [];
  const values: number[] = [];

  Object.entries(data).forEach(([key, value]) => {
    if (key !== "market") {
      labels.push(key.replace("Bedrooms", "BR"));
      values.push(parseFloat(String(value)));
    }
  });

  return (
    <Line
      data={{
        labels,
        datasets: [
          {
            labels,
            data: values,
            borderColor: color,
            backgroundColor: color
          }
        ] as any
      }}
      options={{
        aspectRatio: 1.75,
        elements: {
          line: {
            tension: 0.2
          },
          point: {
            radius: 0
          }
        },
        interaction: {
          intersect: false
        },
        layout: {
          padding: {
            top: 16
          }
        },
        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: (context: any) => {
                if (percent) {
                  return percentNotation(context.parsed.y / 100, 1);
                }

                return numberNotation(context.parsed.y);
              }
            }
          }
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false // This hides the vertical grid lines
            },
            ticks: {
              callback(value: any, index: any) {
                // This formats the labels to show only year and month
                const date = new Date(labels[index]);
                return `${date.getFullYear()}-${String(
                  date.getMonth() + 1
                ).padStart(2, "0")}`;
              },
              maxRotation: 0, // Prevents tilting
              minRotation: 0 // Ensures the labels are not tilted
            }
          },
          y: {
            display: y,
            min: isNullOrUndefined(min) ? undefined : min,
            position: "right",
            ticks: {
              callback: (value: any) => {
                return compactNotation(value);
              }
            }
          }
        }
      }}
    />
  );
}
