import React, { useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import { AppContext } from "../components/AppContext";
import Text from "../components/Text";
import Header from "../components/Header";
import useCookies from "../hooks/useCookies";
import Flex from "../components/Flex";
import SectionFooter from "../components/SectionFooter";
import SplitView from "../components/SplitView";
import Group from "../components/Group";
import Section from "../components/Section";
import { Color } from "../types";
import LiveMarkets from "../liveMarkets";
import Image from "../components/Image";

const Container = styled(Flex)`
  // flex-direction: column;
  font-size: 2.5rem;
  height: 100vh;
  // width: 50%;
  justify-content: center;
  line-height: 1;
  li {
    color: var(--white);
    font-weight: 200;
    fontSize; 1.2rem;
  }
  h1 {
    font-weight: 300;
    font-size: 2.5rem;
    // text-align: center;
    margin-bottom: 0;
    text-align: left;
    // padding-bottom: 1rem;
  }
  h3 {
    font-weight: 300;
    font-size: 1.6rem;
    // text-align: center;
    margin: 0;
  }
  video {
    margin: 2rem;
    max-width: 450px;
    border-radius: 0.5rem;
  }
  padding: 5rem 0;
  .first ${Text} {
    white-space: nowrap;
    font-size: 2.5rem;
  }
  .second h1 {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
    color: var(--tertiary);
  }
  .third ${Text} {
    font-size: 1.5rem;
  }
  .first {
    padding: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
    // width: 50%;
  }
  .second,
  .third {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-top: 0.75rem;
    padding: 1rem;
    padding-left: 1rem;
    // width: 50%;
  }
  .second {
    padding-top: 0;
  }
  .third {
    // display: block;
    // text-align: left;
    padding: 0rem;
  }
  ${Group} {
    :first-of-type {
      margin-top: 6rem;
    }
  }

  @media (max-width: 810px) {
    // justify-content: start;
    align-items: flex-start;
    margin-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    h3 {
      font-size: 2rem;
    }
    .first {
      text-align: center;
      h1 {
        font-size: 2.5rem;
        text-align: center;
        ${Text} {
          font-size: 2.5rem;
          text-align: center;
          line-height: 2.6rem;
        }
      }
    }
    .second {
      display: none;
    }
    video {
      display: none;
    }
    .second {
      padding-bottom: 1rem;
    }
    .third ${Text} {
      padding-bottom: 1rem;
    }
    .second,
    .third {
      text-align: center;
    }
    .third {
      flex-direction: column;
    }
  }
`;

const Service1031Exchange = () => {
  const { getAllCookies } = useCookies();
  const { marketPathParam } = useParams<any>();
  // const { mobileSize, setOpenContactModal } = useContext(AppContext);
  const { mobileSize } = useContext(AppContext);

  const bodyRef = useRef<HTMLDivElement>();

  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });
  const handleInvestorGuideClick = () => {
    let investorGuideSlug = marketPathParam;
    if (marketPathParam === "memphis-tn") {
      investorGuideSlug = "memphis-tn-investor-guide-gross-yields-from-24";
    } else if (marketPathParam === "fort-lauderdale-fl") {
      investorGuideSlug = "broward-county-fl-investor-guide-airbnb-rentals";
    } else if (marketPathParam === "san-diego-county-ca") {
      investorGuideSlug = "encinitas-carlsbad-oceanside-ca";
    } else if (marketPathParam === "lake-worth-fl") {
      investorGuideSlug = "broward-county-fl-investor-guide-airbnb-rentals";
    }
    window.open(`/investor-guides/${investorGuideSlug}`, "_blank");
  };

  const titleString = `Maximize Your 1031 Exchange with Short-Term Rental Investments | ${
    LiveMarkets[marketPathParam] || ""
  }`;
  const descriptionString = `Chalet helps you unlock the power of short-term rental investments for 
  your 1031 exchange. Our experienced real estate agents are local experts 
  who understand the benefits of working with cash buyers and have 
  a well-connected network of partners and 1031 exchange intermediaries 
  in the ${LiveMarkets[marketPathParam] || ""} market`;

  const urlSlug = marketPathParam
    ? `https://www.getchalet.com/1031-exchange/${marketPathParam}`
    : "https://www.getchalet.com/1031-exchange";
  return (
    <>
      <Helmet>
        <link rel="canonical" href={urlSlug} />
        <title>{titleString}</title>
        <meta property="og:type" content="website" />
        <meta name="description" content={descriptionString} />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={descriptionString} />
        <meta property="og:image" content="" />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Container ref={bodyRef as any}>
        <div
          style={{
            // maxWidth: mobileSize ? "100%" : "50%",
            paddingRight: mobileSize ? "0" : "4rem"
          }}
        >
          <div className="first">
            <h1 style={{ lineHeight: mobileSize ? "2.7rem" : "4.0rem" }}>
              Unlock the Power of Short-Term Rental Investments for{" "}
              <Text
                style={{
                  display: "block",
                  // fontSize: mobileSize ? "2rem" : "initial",
                  fontWeight: 400,
                  whiteSpace: "initial"
                }}
              >
                {" "}
                Your 1031 Exchange
              </Text>
            </h1>
          </div>
          <div className="second">
            <h3>
              Chalet helps you find the
              <Text
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 400,
                  color: "var(--quinary)"
                }}
              >
                &nbsp;right short-term rental property for your 1031 exchange
                <Text
                  // block
                  style={{ fontSize: mobileSize ? "2rem" : "1.6rem" }}
                >
                  {marketPathParam && " in"}
                </Text>{" "}
                <Text
                  style={{
                    color: "var(--tertiary)",
                    fontWeight: "500",
                    // textDecoration: "underline",
                    fontSize: mobileSize ? "2rem" : "1.6rem"
                  }}
                >
                  {LiveMarkets[marketPathParam]}
                </Text>
              </Text>
              <Text
                block
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 500
                  // color: "var(--quaternary)",
                  // background: "var(--tertiary)",
                  // padding: "1rem",
                  // marginTop: "-1.75rem"
                }}
              >
                {!marketPathParam && " in "}{" "}
                {!marketPathParam && <a href="#locations">top 50 US markets</a>}
              </Text>
            </h3>
          </div>
          <div className="third">
            <PopupButton
              id="G3A8zRSV"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified"
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--tertiary)"
                  : "var(--tertiary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem"
              }}
              className="connect-with-realtor_popup_top"
            >
              Talk to 1031 Exchange STR Strategist
            </PopupButton>
            <a
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--quinary)"
                  : "var(--quinary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                fontSize: "1rem",

                cursor: "pointer",
                display: mobileSize ? "block" : "initial",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "0rem auto" : "1rem"
              }}
              data-id="learn-more_button_top"
              href="#section1"
              title="/buy"
            >
              Learn More
            </a>
          </div>
          <div
            className="fourth"
            style={{
              display: mobileSize ? "none" : "none",
              marginTop: "5rem"
            }}
          >
            <h3 style={{ color: "var(--white)", textAlign: "center" }}>
              Let our realtors help you find
              <Text
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 400,
                  color: "var(--white)"
                }}
              >
                &nbsp;the best STR investments
                <Text
                  style={{
                    fontSize: mobileSize ? "2rem" : "1.6rem",
                    textAlign: "center"
                  }}
                >
                  {marketPathParam && " in"}
                </Text>{" "}
                <Text
                  style={{
                    color: "var(--white)",
                    fontWeight: "500",
                    // textDecoration: "underline",
                    textAlign: "center",
                    fontSize: mobileSize ? "2rem" : "1.6rem"
                  }}
                >
                  {LiveMarkets[marketPathParam]}
                </Text>
              </Text>
              <Text
                // block
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 500,
                  color: "var(--white)",
                  textAlign: "center"
                  // background: "var(--tertiary)",
                  // padding: "1rem",
                  // marginTop: "-1.75rem"
                }}
              >
                {!marketPathParam && " in "}{" "}
                {!marketPathParam && (
                  <a
                    style={{
                      textAlign: "center",
                      color: "var(--quaternary)",
                      background: "rgba(255,255,255,0.3)",
                      padding: "0.5rem",
                      marginTop: "0.2rem",
                      borderRadius: "0.5rem",
                      display: "block"
                    }}
                    href="#locations"
                  >
                    top 50 US markets
                  </a>
                )}
              </Text>
            </h3>
          </div>
        </div>
        <div style={{ display: "block", maxWidth: "50%" }}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.webp`}
            alt="Chalet Partner Real Estate Agents With STR Investing Expertise"
            $mobileSize={mobileSize}
            style={{
              gridRowStart: mobileSize ? "1" : "inherit",
              maxWidth: mobileSize ? "100%" : "300px",
              display: mobileSize ? "none" : "block",
              borderRadius: "0.25rem"
            }}
          />
        </div>
      </Container>
      <Group id="section1">
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit",
            maxWidth: "100%"
          }}
        >
          <Header size={2}>
            Why Choose Chalet for Your Short-Term Rental Investment?
          </Header>
          <SplitView
            split="2:3"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "right",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/invest-with-experts.webp`}
                alt="Buy your Airbnb rental with Chalet"
                style={{
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "300px",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"

                // style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                At Chalet, we are committed to enabling everyone to create
                wealth with short-term rentals. Our team of real estate agents
                are experienced working with 1031 exchange buyers and understand
                the benefits of working with cash buyers.
              </Text>
              <Text block align="left">
                They are also local experts who have a well-connected network of
                partners and 1031 exchange intermediaries{" "}
                {marketPathParam && "in"} {LiveMarkets[marketPathParam]}.
              </Text>
              {marketPathParam && (
                <Text
                  align="left"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "var(--quinary)"
                  }}
                  onClick={handleInvestorGuideClick}
                >
                  Read our Investor Guide To Airbnb Rental In{" "}
                  {LiveMarkets[marketPathParam]}
                </Text>
              )}
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group
        style={{
          background:
            "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #2F354B 67.71%, #FB929D 71.35%)",
          maxWidth: "100%"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            background: "none",
            maxWidth: "100%"
          }}
        >
          <Header size={2} color={Color.white}>
            Benefits of Working with Our Real Estate Agents
          </Header>
          <SplitView
            split="3:2"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              {/* <Text
                block
                align="left"
                style={{ lineHeight: "2.5rem" }}
                color={Color.white}
              >
                Our real estate agents are not only local experts but also
                top-performing real estate agents in the{" "}
                {LiveMarkets[marketPathParam]} area.
              </Text>
              <Text
                block
                align="left"
                style={{ lineHeight: "2.5rem" }}
                color={Color.white}
              >
                They have the skills and expertise to help you make informed
                investment decisions and reach your investment goals.
              </Text> */}
              <ul>
                <li
                  style={{
                    color: "var(--white)",
                    fontSize: "1.5rem",
                    fontWeight: 200
                  }}
                >
                  Expert knowledge of the local short-term rental market
                </li>
                <li
                  style={{
                    color: "var(--white)",
                    fontSize: "1.5rem",
                    fontWeight: 200
                  }}
                >
                  Experience working with 1031 exchange buyers
                </li>
                <li
                  style={{
                    color: "var(--white)",
                    fontSize: "1.5rem",
                    fontWeight: 200
                  }}
                >
                  An understanding of the benefits of working with cash buyers
                </li>
                <li
                  style={{
                    color: "var(--white)",
                    fontSize: "1.5rem",
                    fontWeight: 200
                  }}
                >
                  A well-connected network of partners and 1031 exchange
                  intermediaries
                </li>
              </ul>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/1031-exchange.webp`}
              alt="Local STR Experts With a Proven Track Record"
              $mobileSize={mobileSize}
              style={{
                display: "flex",
                gridRowStart: mobileSize ? "1" : "inherit",
                justifyContent: "right",
                margin: "0 auto",
                maxWidth: mobileSize ? "100vw" : "300px"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group
        style={{
          position: "relative"
        }}
      >
        <Section
          style={{
            maxWidth: "100%",
            background: "var(--tertiary)",

            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)", fontSize: "1.2rem", fontWeight: 200 }}
            size={2}
            color={Color.white}
            style={{ zIndex: 200, position: "relative" }}
          >
            Get Started Today
          </Header>
          <Image
            src={`${process.env.PUBLIC_URL}/images/vacation_home.webp`}
            alt="Airbnb Rental Investor Guides. Free Market Reports. Median Revenue, ADR, Occupacny rates"
            style={{
              padding: "0rem",
              position: "absolute",
              left: 0,
              top: 0,
              opacity: "0",
              // zIndex: "0",
              // right: 0,
              // borderRadius: "2rem",
              maxWidth: mobileSize ? "100%" : "100%",
              width: "100%"
            }}
            $mobileSize={mobileSize}
          />
          <div
            style={{
              gridRowStart: mobileSize ? "2" : "inherit",
              paddingBottom: mobileSize ? "inherit" : "0",
              paddingTop: mobileSize ? "inherit" : "0",
              position: "relative",
              zIndex: 1
            }}
          >
            <Text block align="center" color={Color.white}>
              Ready to maximize your returns with a short-term rental investment
              for your 1031 exchange?
            </Text>
            <Text block align="center" color={Color.white}>
              Contact us today to speak with one of our experienced real estate
              agents and learn how we can help you find the right property to
              meet your needs.
            </Text>
            <PopupButton
              id="G3A8zRSV"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified"
              }}
              shareGaInstance="true"
              className="connect-with-realtor_popup_bottom"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                // backgroundColor: "var(--tertiary)",
                background:
                  "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #FB929D 71.35%)",
                textTransform: "uppercase",
                display: "block",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                // display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
            >
              Talk to 1031 Exchange STR Expert
            </PopupButton>
          </div>
        </Section>
      </Group>
    </>
  );
};

export default Service1031Exchange;
