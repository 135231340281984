import React, { useState } from "react";
import styled from "styled-components";
import Text from "../../../components/Text";
import Field, { StyledField } from "../../../components/Field";
import Button from "../../../components/Button";
import LinkButton from "../../../components/LinkButton";
import Flex from "../../../components/Flex";
import { Color } from "../../../types";
import Section from "../../../components/Section";
import SignInWithGoogle from "./SignInWithGoogle";
import SignUpModal from "../SignUpModal";

const FormContainer = styled(Section)`
  box-shadow: none;
  max-width: 100%;
  padding: 0;
  ${StyledField} {
    input {
      min-width: 200px;
    }
  }
`;

const LoginForm = ({
  email,
  error,
  isLoading,
  setForgotPassword,
  onChange,
  password,
  setLogin
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Text
        weight={400}
        style={{
          fontSize: "1.5rem",
          paddingBottom: "2rem",
          color: "var(--tertiary)"
        }}
      >
        WELCOME BACK
      </Text>
      <SignInWithGoogle />
      <Text weight={700} style={{ fontSize: "1.5rem", padding: "2.5rem 0" }}>
        OR
      </Text>

      <FormContainer>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setLogin(true);
          }}
        >
          <Field
            name="email"
            placeholder="Email"
            type="email"
            value={email}
            onChange={onChange}
          />
          <Field
            name="password"
            placeholder="Password"
            type="password"
            value={password}
            onChange={onChange}
          />
          <Button
            block
            isLoading={isLoading}
            type="submit"
            style={{ width: "100%" }}
          >
            Login
          </Button>
        </form>
      </FormContainer>
      <LinkButton onClick={() => setForgotPassword(true)}>
        Forgot password?
      </LinkButton>
      <Flex>
        <Text
          color={Color.quaternary}
          weight={400}
          style={{ fontSize: "1.5rem" }}
        >
          {error}
        </Text>
      </Flex>
      <SignUpModal
        isOpen={isModalOpen}
        toggle={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default LoginForm;
