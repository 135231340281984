import React, { useContext } from "react";
import styled from "styled-components";
import Modal, { ModalProps } from "../../components/Modal";
import Text from "../../components/Text";
import { AppContext } from "../../components/AppContext";
import SpreadList from "../../components/SpreadList";
import Section from "../../components/Section";
import { Color } from "../../types";
import Flex from "../../components/Flex";

export interface SupportedMarketsModalProps extends ModalProps {
  header?: React.ReactNode;
}

const supportedCounties = [
  "Dallas, TX",
  "Memphis, TN",
  "Summit County, CO",
  "Palm Springs, CA",
  "Fort Lauderdale, FL",
  "San Diego County, CA",
  "Philadelphia, PA",
  "Atlanta, GA",
  "Phoenix/Scottsdale metro, AZ",
  "Miami Beach, FL"
];

const CountyList = styled(SpreadList)`
  font-weight: 400;
  list-style: disc;
  columns: 3;
  display: block;
  color: var(--tertiary);
  li:before,
  li::marker {
    color: var(--tertiary);
  }
`;

const SectionSide = styled.div`
  // @media (min-width: 1600px) {
  //   padding: 4rem 10rem;
  // }
  // @media (max-width: 1599px) {
  //   padding: 4rem 5.5rem;
  // }

  // @media (max-width: 1399px) {
  //   padding: 4rem 8rem;
  // }

  // @media (max-width: 1080px) {
  //   padding: 4rem 4.255rem;
  // }
  ${Section} {
    background: none;
    box-shadow: none;
    margin-top: 5rem;
    text-align: right;
    // margin-top: 5rem;
    padding: 1rem;
    @max-width (800px) {
      margin-top: 1rem;
      text-align: center;
    }
  }
`;

const StyledModal = styled(Modal)`
  ::before {
    content: "";
    background-image: url("${process.env
      .PUBLIC_URL}/images/supported-markets-background.webp");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.15;
    z-index: 100;
  }
  // background-image: url("${process.env
    .PUBLIC_URL}/images/supported-markets-background.webp");
  // background-blend-mode: luminosity;
  // background-repeat: no-repeat;
  // backroung-opacity: 0.4;
  // background-size: cover;
  // position: relative;
  z-index: 2000;
  @media (min-width: 1540px) {
    max-width: 75vw;
  }
  @media (max-width: 800px) {
    background-blend-mode: ;
  }
`;

const SupportedMarketsModal = ({
  header,
  isOpen,
  toggle
}: SupportedMarketsModalProps) => {
  const { mobileSize } = useContext(AppContext);
  return (
    <StyledModal toggle={toggle} isOpen={isOpen}>
      <div style={{ padding: "1.5rem" }}>
        <Flex style={{ flexDirection: "column", marginBottom: "1rem" }}>
          {header}
        </Flex>
        <Text
          weight={400}
          style={{
            color: "var(--tertiary)",
            display: "block",
            fontSize: "1.5rem"
          }}
        >
          We are currently supporting and launched in following markets
        </Text>
        <div style={{ marginTop: "1rem" }}>
          <div>
            <CountyList>
              {supportedCounties.map((county, idx) => {
                const key = `${county}-${idx}`;
                return <li key={key}>{county}</li>;
              })}
            </CountyList>
          </div>
          <SectionSide>
            <Section>
              <Text
                style={{
                  fontSize: "1.4rem",
                  textAlign: mobileSize ? "center" : "right",
                  display: "block",
                  fontWeight: 400,
                  color: "var(--tertiary)"
                }}
              >
                We are actively adding new markets. Stay tuned!
              </Text>
              <Text
                style={{
                  fontSize: "1.4rem",
                  display: "block",
                  textAlign: mobileSize ? "center" : "right",
                  fontWeight: 400,
                  color: "var(--tertiary)"
                }}
              >
                If your market is not supported, please let us know.
              </Text>
              <Text
                style={{
                  fontWeight: 400,
                  color: "var(--tertiary)",
                  fontSize: mobileSize ? "1rem" : "1.4rem"
                }}
              >
                Contact:
                <a
                  href="mailto:product@getchalet.com"
                  style={{ fontWeight: 400, color: "var(--tertiary)" }}
                >
                  product@getchalet.com
                </a>
              </Text>
              <Text
                color={Color.quinary}
                weight={700}
                style={{
                  fontWeight: 400,
                  color: "var(--tertiary)",
                  fontSize: "1.5rem",
                  display: "block",
                  textAlign: mobileSize ? "center" : "right"
                }}
              >
                We love your feedback
              </Text>
            </Section>
          </SectionSide>
        </div>
      </div>
    </StyledModal>
  );
};

SupportedMarketsModal.defaultProps = {
  header: (
    <Text
      color={Color.tertiary}
      weight={400}
      style={{
        display: "block",
        fontSize: "2rem",
        textAlign: "center",
        marginBottom: "0rem"
      }}
    >
      OUR MARKETS
    </Text>
  )
};

export default SupportedMarketsModal;
