import styled from "styled-components";

const Arrows = styled.img`
  animation: bounce 1000ms ease infinite;

  @keyframes bounce {
    0% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
    }

    50% {
      transform: translateY(-50%);
      -webkit-transform: translateY(-50);
    }

    100% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
    }
  }
`;

export default Arrows;
