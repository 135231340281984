import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export interface HeroProps {
  maxWindowHeight?: boolean;
}

const StyledHero = styled.div`
  background-image: url("${process.env.PUBLIC_URL}/images/about2.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  box-sizing: border-box;
  margin-bottom: 10rem;
  min-height: 100vh;
  max-width: 100vw;
  padding: 0px 0.5rem 0.5rem;
  position: absolute;
  width: 100%;
  z-index: -1;

  @media (min-width: 1400px) {
    background-size: cover;
  }

  @media (max-width: 720px) {
    margin-bottom: 0;
  }
`;

const Hero = () => {
  const location = useLocation();
  const bodyRef = useRef<HTMLDivElement>();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (location.pathname === "/" && window.innerWidth >= 900) {
      setHeight(window.innerWidth - 400);
      document.documentElement.style.setProperty(
        "--min-height",
        `${window.innerWidth - 400}px`
      );
    } else {
      setHeight(window.innerHeight);
      document.documentElement.style.setProperty(
        "--min-height",
        `${window.innerHeight}px`
      );
    }
    window.addEventListener("resize", () => {
      if (location.pathname === "/" && window.innerWidth >= 900) {
        setHeight(window.innerWidth - 400);
        document.documentElement.style.setProperty(
          "--min-height",
          `${window.innerWidth - 400}px`
        );
      } else {
        setHeight(window.innerHeight);
        document.documentElement.style.setProperty(
          "--min-height",
          `${window.innerHeight}px`
        );
      }
    });
  }, [location]);
  return (
    <StyledHero
      ref={bodyRef as any}
      style={{
        minHeight: height ? `${height}px` : undefined
      }}
    />
  );
};

export default Hero;
