import { Auth } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Modal from "../../../components/Modal";
import Image from "../../../components/Image";
import { OptionProp } from "../../../components/Select";
import Flex from "../../../components/Flex";
import { AppContext } from "../../../components/AppContext";
import LoginForm from "./LoginForm";
import Button from "../../../components/Button";
import ConfirmSignup from "./ConfirmSignup";
import ForgotPassword from "./ForgotPassword";
import AnalyticsModal from "../../Analytics/components/AnalyticsModal";

const BackgroundImage = styled(Image)`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const defaultForm = {
  email: "",
  password: "",
  verificationcode: ""
};

const LoginModal = () => {
  const history = useHistory();
  const { openLoginModal, setOpenLoginModal, setUser, mobileSize } =
    useContext(AppContext);
  const [login, setLogin] = useState(false);
  const [confirmSignup, setConfirmSignup] = useState(false);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = React.useState(false);
  const [isResendLoading, setIsResendLoading] = React.useState(false);
  const [error, setError] = useState();
  const [form, setForm] = useState(defaultForm);
  const { email, password, verificationcode } = form;

  useEffect(() => {
    const loginUser = async () => {
      try {
        localStorage.setItem("prevLoc", history.location.pathname);
        const user = await Auth.signIn(email, password);
        setUser(user);
        setLogin(false);
        setForm(defaultForm);
        setError(undefined);
        setOpenLoginModal(false);
      } catch (err: any) {
        if (err.code === "UserNotConfirmedException") {
          setConfirmSignup(true);
        }
        setError(err.message);
        setLogin(false);
      }
      setIsLoginLoading(false);
    };
    if (login) {
      setIsLoginLoading(true);
      loginUser();
    }
  }, [login, form]);

  useEffect(() => {
    async function confirmSignUp() {
      try {
        await Auth.confirmSignUp(email, verificationcode);
        setSubmitConfirmation(false);
        setConfirmSignup(false);
        setLogin(true);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("error signing up:", err);
      }
      setIsConfirmLoading(false);
    }
    if (submitConfirmation) {
      setIsConfirmLoading(true);
      confirmSignUp();
    }
  }, [submitConfirmation, form]);

  const resendCode = async () => {
    setIsResendLoading(true);
    try {
      await Auth.resendSignUp(email);
      setIsResendLoading(false);
    } catch (err) {
      // eslint-disable-next-line
      console.log("error resending code: ", err);
      setIsResendLoading(false);
    }
  };

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.persist();
    const { name: n, value } = e.target as HTMLInputElement;
    let inputName = n;
    let inputValue = value;
    if (!inputName) {
      inputName = (e as any).name;
      inputValue = ((e as any).value as OptionProp).label as string;
    }
    setForm((prevState) => ({
      ...prevState,
      [inputName]: inputValue
    }));
  };

  const closeModal = () => {
    setOpenLoginModal(false);
    setForgotPassword(false);
  };
  const handleSignUpClick = () => {
    setIsModalOpen(true);
    setOpenLoginModal(false);
    // toggle(false);
  };
  return (
    <>
      <Modal
        isOpen={openLoginModal}
        toggle={closeModal}
        style={{ width: "100%", maxWidth: mobileSize ? "90vw" : "750px" }}
      >
        <BackgroundImage src="/images/about2.webp" />
        <Flex
          style={{
            flexDirection: "column",
            padding: mobileSize ? "1.5rem" : "2.5rem"
          }}
        >
          <div
            style={{ position: "absolute", top: "0.25rem", right: "1.25rem" }}
          >
            Don&apos;t have an account
            <Button
              style={{
                textDecoration: "underline",
                display: "inline-block",
                background: "none",
                color: "var(--primary)"
              }}
              onClick={handleSignUpClick}
            >
              Sign Up
            </Button>
          </div>
          {!confirmSignup && !forgotPassword && (
            <LoginForm
              email={email}
              error={error}
              isLoading={isLoginLoading}
              forgotPassword={forgotPassword}
              onChange={onChange}
              password={password}
              setForgotPassword={setForgotPassword}
              setLogin={setLogin}
            />
          )}
          {confirmSignup && (
            <ConfirmSignup
              email={email}
              isLoading={isConfirmLoading || isResendLoading}
              verificationcode={verificationcode}
              onChange={onChange}
              setSubmitConfirmation={setSubmitConfirmation}
              resendCode={resendCode}
            />
          )}
          {forgotPassword && (
            <ForgotPassword
              email={email}
              onChange={onChange}
              setForgotPassword={setForgotPassword}
            />
          )}
          <img
            style={{
              display: "block",
              marginLeft: "auto",
              marginTop: "2rem",
              marginRight: "-1.5rem",
              marginBottom: "-1.5rem",
              height: "35px"
            }}
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="Chalet"
          />
        </Flex>
      </Modal>
      <AnalyticsModal
        isOpen={isModalOpen}
        toggle={() => {
          setIsModalOpen(false);
        }}
        ignoreTimeout
      />
    </>
  );
};

export default LoginModal;
