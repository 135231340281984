import { useEffect, useState } from "react";

export enum SameSite {
  Lax = "Lax",
  None = "None",
  Strict = "Strict"
}

const useCookies = (sameSite = SameSite.Lax) => {
  const [cookies, setCookies] = useState<string[][]>([]);

  useEffect(() => {
    setCookies(document.cookie.split("; ").map((cookie) => cookie.split("=")));
  }, []);

  const getAllCookies = () => cookies;

  const getCookie = (str: string) => {
    const newCookies =
      cookies.length === 0
        ? document.cookie.split("; ").map((cookie) => cookie.split("="))
        : [...cookies];
    return newCookies.find((cookie) => cookie[0].includes(str))?.[1];
  };

  const setCookie = (key: string | [string, string][], val = "") => {
    const newCookies =
      cookies.length === 0
        ? document.cookie.split("; ").map((cookie) => cookie.split("="))
        : [...cookies];
    if (newCookies[0][0] === "") {
      newCookies.pop();
    }
    if (Array.isArray(key)) {
      key.forEach((newVal) => {
        newCookies.push(newVal);
      });
    } else {
      const idx = newCookies.findIndex((cookie) => cookie[0].includes(key));
      if (idx !== -1) {
        newCookies[idx][1] = val;
      } else {
        newCookies.push([key, val]);
      }
    }

    setCookies(newCookies);
    newCookies.forEach((cookie) => {
      document.cookie = `${cookie.join("=")};SameSite=${sameSite}`;
    });
  };

  return {
    getAllCookies,
    getCookie,
    setCookie
  };
};

export default useCookies;
