/* eslint-disable camelcase */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import styled from "styled-components";
import { v4 as uuidV4 } from "uuid";
import { useLocation } from "react-router-dom";
import AddressAutoComplete from "../../components/AddressAutoComplete";
import { AppContext } from "../../components/AppContext";
import Flex from "../../components/Flex";
import InlineInput from "../../components/InlineInput";
import Metric from "../../components/Metric";
import Section from "../../components/Section";
import { StyledSelect } from "../../components/Select";
import Text from "../../components/Text";
import { Color } from "../../types";
import StackedBarChart from "../../components/charts/StackedBarChart";
import SupportedMarketsModal from "../modals/SupportedMarketsModal";
import Arrows from "../../components/Arrows";
import useCookies from "../../hooks/useCookies";
import Button, { StyledButton } from "../../components/Button";
import HR from "../../components/HR";
import SelectForCalculator from "../../components/SelectForCalculator";
import Header from "../../components/Header";

const formatter = (val: number, options = {}) =>
  new Intl.NumberFormat("en-us", {
    maximumFractionDigits: (options as any).style === "percent" ? 1 : 0,
    ...options
  }).format(val);

const Grid = styled(Flex)`
  align-items: stretch;
  padding: 0 0;
  width: 100%;

  ${StyledButton},
  ${StyledSelect},
  ${Section} {
    button {
      &:nth-child(5) {
        background-color: var(--white);
        color: var(--primary);
      }
    }
    margin: 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  ${Section} {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.5rem;
  }

  @media (max-width: 800px) {
    flex-direction: column;

    ${StyledSelect},
    ${Section} {
      flex: 1 1 auto;
      margin: 0.5rem 0;
      button {
        &:nth-child(5) {
          background-color: var(--white);
          color: var(--primary);
        }
      }
      &:last-child {
        padding: 0;
      }
    }
  }
`;

// const PlaceholderTooltip = (
//   <>
//     <Text weight={300} style={{ display: "block", fontSize: "14px" }}>
//       This is a placeholder tooltip
//     </Text>
//     <Text weight={300} style={{ display: "block", fontSize: "14px" }}>
//       This is a placeholder tooltip
//     </Text>
//     <Text weight={300} style={{ display: "block", fontSize: "14px" }}>
//       This is a placeholder tooltip
//     </Text>
//   </>
// );

const ArrowsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(100vh - 133px);
  right: 2rem;
`;

const StrCalculatorBody = ({
  calcState: propCalcState
}: {
  calcState?: any;
}) => {
  const location = useLocation();
  const { mobileSize, user, setOpenContactModal, calcLat, calcLng } =
    useContext(AppContext);
  const { getAllCookies, getCookie, setCookie } = useCookies();
  const periodRef = useRef<Record<string, any>>({});

  const [showInstructions, setShowInstructions] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [unsupportedMarket, setUnsupportedMarket] = useState(false);
  const [{ average_daily_rate, revenue, occupancy_rate, revpar }, setStats] =
    useState({
      average_daily_rate: undefined,
      revenue: undefined,
      gross_yield: undefined,
      occupancy_rate: undefined,
      property_tax_rate: undefined,
      revpar: undefined
    });
  const handleContactUsModal = () => {
    // eslint-disable-next-line
    console.log("Running this");
    setOpenContactModal(true);
  };

  const [
    {
      downPayment,
      closingCosts,
      renovationCosts,
      furnishingCosts,
      purchasePrice,
      interestRate,
      homeAppreciation,
      mortgageTerm,
      occupancyRate,
      netAdr,
      revenueGrowth,
      grossRevenue1,
      otaFee,
      PMI,
      lodgingTax,
      propertyManagement,
      maintenance,
      cleaningFees,
      strLicensing,
      homeownersInsurance,
      propertyTax,
      annualUtilities,
      hoa
    },
    setProfitLoss
  ] = useState<any>({
    downPayment: 62500,
    closingCosts: 0.01,
    renovationCosts: 20000,
    purchasePrice: 885000,
    interestRate: 0.03,
    homeAppreciation: 0.05,
    mortgageTerm: 30,
    furnishingCosts: 20000,
    occupancyRate: 0.5,
    netAdr: 300,
    revenueGrowth: 0.015,
    grossRevenue1: 0.5 * 350 * 365,
    otaFee: 0.03,
    PMI: 148,
    lodgingTax: 0,
    propertyManagement: 0.1,
    maintenance: 0.01,
    cleaningFees: 0.03,
    strLicensing: 200,
    homeownersInsurance: 3600,
    propertyTax: 0.005,
    annualUtilities: 8000,
    hoa: 0.0
  });

  const [{ beds, baths, accommodates, address }, setCalcState] = useState<any>(
    propCalcState || {
      beds: { label: "1 Bedroom", value: 1 },
      baths: { label: "1 Bathroom", value: 1 },
      accommodates: { label: "1 Guest", value: 1 },
      address: "",
      price: 500000
    }
  );

  useEffect(() => {
    setCalcState(
      propCalcState || {
        beds: { label: "1 Bedroom", value: 1 },
        baths: { label: "1 Bathroom", value: 1 },
        accommodates: { label: "1 Guest", value: 1 },
        address: "",
        price: 500000
      }
    );
  }, [propCalcState]);

  useEffect(() => {
    if (user) {
      document.body.style.overflow = "";
    } else {
      const anonUserId = getCookie("anon_user_id");
      const queryAmounts = getCookie("str_pred_query_count");

      if (!anonUserId && !user) {
        setCookie("anon_user_id", uuidV4());
        setCookie("str_pred_query_count", "0");
      }

      if (queryAmounts && Number(queryAmounts) === 3 && !user) {
        document.body.style.overflow = "hidden";
      }
    }
  }, [user]);

  useEffect(() => {
    if (location?.state) {
      if ((location as any).state.showInstructions) {
        setShowInstructions(true);
      } else {
        setShowInstructions(false);
      }
    } else {
      setShowInstructions(true);
    }
  }, [location]);

  useEffect(() => {
    // console.log("calcLng", calcLng);
  }, [calcLng]);

  const getPrediction = useCallback(() => {
    const cookies: Record<string, string> = {};
    // let user_id: string;
    // if (user) {
    //   user_id = user!.getUsername();
    // }
    getAllCookies().forEach(([name, val]) => {
      cookies[name] = val;
    });

    if (showInstructions) {
      setShowInstructions(false);
    }

    const fetchPrediction = async () => {
      setLoading(true);
      try {
        // console.log("lat lng", calcLat, calcLng);
        const response = await fetch(
          `https://7sdr27znoa.execute-api.us-east-2.amazonaws.com/v2/getIncomeHistory?coordinate=(${calcLat},${calcLng})&bedrooms=${beds.value}&bathrooms=${baths.value}&apiResponseType=estimator_with_comps`,
          {
            method: "GET",
            // body: JSON.stringify({
            //   coordinate: [calcLat, calcLng],
            //   bedrooms: beds.value,
            //   bathrooms: baths.value,
            //   //       accommodates: accommodates.value,
            //   //       price: purchasePrice,
            //   //       ...cookies
            //   apiResponseType: "estimator_with_comps"
            // }),
            headers: {
              Authorization:
                "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImphZStjaGFsZXRAYWlyYnRpY3MuY29tIn0.2WvTDqNTdap17Xoru845wUq87mQWwhiV5shF3dOS9H4",
              "x-api-key": "jn719ivtlxoi9CnmHMX27gkCtg9qM2ejTw1MHYTQ"
            }
          }
        );

        if (response.ok) {
          const prediction = await response.json();
          await fetch(
            "https://gpvf4ni8qj.execute-api.us-west-2.amazonaws.com/v1/airbnb-calculator",
            {
              method: "POST",
              body: JSON.stringify({
                address,
                beds: beds.value,
                baths: baths.value,
                annual_revenue:
                  prediction.message.last_12_months_summary.average.revenue,
                adr: prediction.message.last_12_months_summary.average
                  .average_daily_rate,
                occupancy_rate:
                  prediction.message.last_12_months_summary.average
                    .occupancy_rate,
                revpar:
                  prediction.message.last_12_months_summary.average
                    .average_daily_rate *
                  (0.01 *
                    prediction.message.last_12_months_summary.average
                      .occupancy_rate),
                // accommodates: accommodates.value,
                // price: purchasePrice,
                ...cookies
              })
            }
          );
          // console.log("Result fromChalet BE", resultFromChaletBE);

          // console.log("prediction", prediction);
          setLoading(false);
          setStats({
            ...prediction.message.last_12_months_summary.average,
            revpar:
              prediction.message.last_12_months_summary.average
                .average_daily_rate *
              (0.01 *
                prediction.message.last_12_months_summary.average
                  .occupancy_rate)
          });
          setProfitLoss((prevState: any) => {
            const newState = { ...prevState };
            newState.occupancyRate =
              prediction.message.last_12_months_summary.average.occupancy_rate /
              100;
            newState.netAdr =
              prediction.message.last_12_months_summary.average.average_daily_rate;
            newState.grossRevenue1 =
              prediction.message.last_12_months_summary.average.revenue;
            newState.grossRevenue5 =
              newState.grossRevenue1 * (1 + revenueGrowth) ** 4;
            return newState;
          });

          if (!user) {
            const cookie: string | undefined = getCookie(
              "str_pred_query_count"
            );

            if (!cookie || Number.isNaN(Number(cookie))) {
              setCookie("str_pred_query_count", String(1));
            } else if (Number(cookie) < 3) {
              setCookie("str_pred_query_count", String(Number(cookie) + 1));
            } else {
              document.body.style.overflow = "hidden";
            }
          }

          setTimeout(() => handleContactUsModal(), 5000);
        } else {
          throw new Error("Market not supported");
        }
      } catch (error) {
        // console.log("error", error);
        setLoading(false);
        setUnsupportedMarket(true);
        setIsOpen(true);
      }
    };
    fetchPrediction();
  }, [
    beds,
    baths,
    accommodates,
    address,
    purchasePrice,
    showInstructions,
    calcLat,
    calcLng
  ]);

  const loanAmount = useMemo(
    () => purchasePrice - downPayment,
    [purchasePrice, downPayment]
  );

  const mortgagePayment = useMemo(() => {
    const monthlyInterestRate = interestRate / 12;
    return (
      loanAmount *
      ((monthlyInterestRate * (1 + monthlyInterestRate) ** 360) /
        ((1 + monthlyInterestRate) ** 360 - 1))
    );
  }, [loanAmount, interestRate]);

  // const totalInvestment = useMemo(() => {
  //   return (
  //     downPayment +
  //     furnishingCosts +
  //     renovationCosts +
  //     closingCosts * purchasePrice
  //   );
  // }, [downPayment, renovationCosts, closingCosts, purchasePrice]);

  const grossRevenue5 = useMemo(
    () => grossRevenue1 * (1 + revenueGrowth) ** 4,
    [grossRevenue1, revenueGrowth]
  );

  const calculateLoanDataAfterMonth = (month: number) => {
    let remainingLoanAmount = loanAmount;
    let totalPrincipalPaid = 0;
    let totalInterestPaid = 0;
    for (let i = 0; i < month; i++) {
      const interestPaid = (remainingLoanAmount * interestRate) / 12;
      const principalPaid = mortgagePayment - interestPaid;
      remainingLoanAmount -= principalPaid;
      totalPrincipalPaid += principalPaid;
      totalInterestPaid += interestPaid;
    }

    return {
      remainingLoanAmount,
      totalPrincipalPaid,
      totalInterestPaid
    };
  };

  const feesFromRevenue = useCallback(
    (revenueLocal: number) =>
      propertyManagement * revenueLocal +
      lodgingTax * revenueLocal +
      cleaningFees * revenueLocal +
      otaFee * revenueLocal,
    [propertyManagement, lodgingTax, otaFee, grossRevenue1]
  );
  const costsOfOwnership1 = useMemo(
    () => (hoa + maintenance + propertyTax) * purchasePrice,
    [hoa, maintenance, propertyTax, purchasePrice]
  );
  const costsOfOwnership5 = useMemo(
    () => (hoa + maintenance + propertyTax) * purchasePrice,
    [hoa, maintenance, propertyTax, purchasePrice]
  );
  const yearlyPayments = useMemo(() => mortgagePayment * 12, [mortgagePayment]);

  const yearlyPMI = useMemo(() => PMI * 12, [PMI]);

  const otherGrossRevenues = useCallback(
    (year: number) => grossRevenue1 * (1 + revenueGrowth) ** year,
    [grossRevenue1, revenueGrowth]
  );

  const netIncome1 = useMemo(
    () =>
      grossRevenue1 -
      (feesFromRevenue(grossRevenue1) +
        costsOfOwnership1 +
        yearlyPayments +
        yearlyPMI +
        homeownersInsurance +
        annualUtilities +
        strLicensing),
    [
      feesFromRevenue,
      grossRevenue1,
      costsOfOwnership1,
      yearlyPayments,
      yearlyPMI,
      homeownersInsurance,
      annualUtilities,
      strLicensing
    ]
  );

  const netIncomeAfter1 = useCallback(
    (revenueLocal) =>
      revenueLocal -
      (feesFromRevenue(revenueLocal) +
        costsOfOwnership5 +
        yearlyPayments +
        yearlyPMI +
        homeownersInsurance +
        annualUtilities +
        strLicensing),
    [
      feesFromRevenue,
      grossRevenue5,
      costsOfOwnership5,
      yearlyPayments,
      yearlyPMI,
      homeownersInsurance,
      annualUtilities,
      strLicensing
    ]
  );

  const netOperatingIncome = useMemo(
    () =>
      grossRevenue1 -
      (feesFromRevenue(grossRevenue1) +
        hoa * purchasePrice +
        (maintenance + propertyTax) * purchasePrice +
        homeownersInsurance +
        annualUtilities +
        strLicensing),
    [
      feesFromRevenue,
      grossRevenue1,
      hoa,
      maintenance,
      purchasePrice,
      homeownersInsurance,
      annualUtilities,
      strLicensing
    ]
  );

  const appreciationAfterYear = (year: number) =>
    (purchasePrice + renovationCosts) * (1 + homeAppreciation) ** year -
    purchasePrice;

  const investmentValueAfterYear = (year: number) =>
    appreciationAfterYear(year) +
    new Array(year)
      .fill(false)
      .map((discard, idx) => {
        if (idx === 0) {
          return netIncome1;
        }
        return netIncomeAfter1(otherGrossRevenues(idx + 1));
      })
      .reduce((prev, curr) => prev + curr, 0) +
    downPayment +
    calculateLoanDataAfterMonth(year * 12).totalPrincipalPaid;

  const onChange = ({ name, value }: any) => {
    setCalcState((prevState: any) => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    });
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    if (e.key === "Backspace" && value.slice(-1) === "%") {
      e.preventDefault();

      setProfitLoss((prevState: any) => {
        const newState = { ...prevState };
        const newValue = value.slice(0, value.length - 2);
        if (newValue === "") {
          delete periodRef.current[name];
        }
        newState[name] = Number(value.slice(0, value.length - 2)) / 100;
        return newState;
      });
    }
  };

  const onInputChange = (percent?: boolean) => (e: React.FormEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    // eslint-disable-next-line
    console.log(name, value, periodRef.current[name]);
    if (value.slice(-1) !== ".") {
      setProfitLoss((prevState: any) => {
        const newState = { ...prevState };
        let str = value.replace(/[^0-9.]/g, "");
        if (periodRef.current[name]) {
          str = str
            .split(".")
            .join("")
            .replace(
              periodRef.current[name]?.prevNumStr?.split(".")[0],
              `${periodRef.current[name]?.prevNumStr?.split(".")[0]}.`
            );
        }
        let num = Number(str);
        if (percent) {
          num /= 100;
        }
        newState[name] = num;
        return newState;
      });
    } else {
      const str = value.replace(/[^0-9.]/g, "");
      periodRef.current[name] = {
        prevNumStr: str
      };
    }
  };

  return (
    <>
      <Section style={{ boxShadow: "none", maxWidth: "100%" }}>
        <Header
          style={{
            // textTransform: "uppercase",
            // fontWeight: "bold",
            color: "var(--tertiary)",
            fontSize: mobileSize ? "2rem" : "3rem"
          }}
        >
          Airbnb Calculator
        </Header>
        <Header size={3} style={{ display: mobileSize ? "none" : "initial" }}>
          Get Instant Prediction of Your Property&apos;s Airbnb (Short-Term
          Rental) Income
        </Header>
        <div
          style={{
            maxWidth: mobileSize ? "100%" : "95vw",
            margin: "0 auto",
            padding: mobileSize ? "1rem" : "4rem 1rem",
            borderRadius: "1rem",
            border: "4px double var(--quinary)"
          }}
        >
          <div>
            <Grid style={{ margin: "0 auto", justifyContent: "center" }}>
              <AddressAutoComplete
                name="address"
                onChange={onChange}
                placeholder="Enter an address to analyze ..."
                value={address}
                data-id="airbnb-calculator_address_input_field"
              />

              <SelectForCalculator
                name="beds"
                placeholder="Bedrooms"
                onChange={onChange}
                data-id="airbnb-calculator_bedrooms_button"
                style={{
                  margin: mobileSize ? "initial" : "0 0rem",
                  // background: "none",
                  boxShadow: "none",
                  color: "var(--white)",
                  border: "1px solid var(--quinary)"
                }}
                options={[
                  { label: "Studio", value: 0 },
                  { label: "1 Bedroom", value: 1 },
                  { label: "2 Bedrooms", value: 2 },
                  { label: "3 Bedrooms", value: 3 },
                  { label: "4 Bedrooms", value: 4 },
                  { label: "5 Bedrooms", value: 5 }
                ]}
                value={beds}
              />
              <SelectForCalculator
                name="baths"
                placeholder="Bathrooms"
                data-id="airbnb-calculator_bathrooms_button"
                onChange={onChange}
                style={{
                  margin: mobileSize ? "initial" : "0 1rem",
                  // background: "none",
                  boxShadow: "none",
                  color: "var(--white)",
                  border: "1px solid var(--quinary)"
                }}
                options={[
                  { label: "1 Bathroom", value: 1 },
                  { label: "2 Bathrooms", value: 2 },
                  { label: "3 Bathrooms", value: 3 },
                  { label: "4 Bathrooms", value: 4 },
                  { label: "5 Bathrooms", value: 5 },
                  { label: "6 Bathrooms", value: 6 }
                ]}
                value={baths}
              />
              {/* <SelectForCalculator
                name="accommodates"
                placeholder="Guests"
                style={{ margin: mobileSize ? "initial" : "0 1rem" }}
                onChange={onChange}
                data-id="airbnb-calculator_accommodates_button"
                options={[
                  { label: "1 Guest", value: 1 },
                  { label: "2 Guests", value: 2 },
                  { label: "3 Guests", value: 3 },
                  { label: "4 Guests", value: 4 },
                  { label: "5 Guests", value: 5 },
                  { label: "6 Guests", value: 6 },
                  { label: "7 Guests", value: 7 },
                  { label: "8 Guests", value: 8 },
                  { label: "9 Guests", value: 9 },
                  { label: "10 Guests", value: 10 },
                  { label: "11 Guests", value: 11 },
                  { label: "12 Guests", value: 12 },
                  { label: "13 Guests", value: 13 },
                  { label: "14 Guests", value: 14 }
                ]}
                value={accommodates}
              /> */}
              <Button
                color={Color.tertiary}
                // disabled={
                //   !(
                //     beds &&
                //     baths &&
                //     accommodates &&
                //     purchasePrice &&
                //     address !== "" &&
                //     isUser
                //   )
                // }
                data-id="airbnb-calculator_get_prediction_button"
                onClick={getPrediction}
                style={{
                  width: mobileSize ? "100%" : "inherit",
                  // marginLeft: mobileSize ? "0" : "0",
                  // marginRight: mobileSize ? "0" : "0",
                  margin: mobileSize ? "initial" : 0,
                  maxWidth: mobileSize ? "100%" : "250px"
                }}
                block
              >
                Update
              </Button>
            </Grid>
          </div>
          {/* {showInstructions && <StrCalculatorInstructions />} */}
          <>
            <div>
              <Grid>
                <Metric
                  loading={loading}
                  borderColor={Color.tertiary}
                  color={Color.tertiary}
                  title="Annual Revenue"
                  // tooltip={PlaceholderTooltip}
                  value={
                    revenue
                      ? formatter(revenue, {
                          style: "currency",
                          currency: "USD"
                        })
                      : undefined
                  }
                />
                <Metric
                  loading={loading}
                  borderColor={Color.quinary}
                  color={Color.quinary}
                  title="Occupancy Rate"
                  // tooltip={PlaceholderTooltip}
                  value={
                    occupancy_rate
                      ? formatter(occupancy_rate / 100, {
                          style: "percent"
                        })
                      : undefined
                  }
                />
                <Metric
                  loading={loading}
                  borderColor={Color.quaternary}
                  color={Color.quaternary}
                  title="ADR"
                  // tooltip={PlaceholderTooltip}
                  value={
                    average_daily_rate
                      ? formatter(average_daily_rate, {
                          style: "currency",
                          currency: "USD"
                        })
                      : undefined
                  }
                />
                <Metric
                  loading={loading}
                  borderColor={Color.primary}
                  color={Color.primary}
                  title="RevPAN"
                  // tooltip={PlaceholderTooltip}
                  value={
                    revpar
                      ? formatter(revpar, {
                          style: "currency",
                          currency: "USD"
                        })
                      : undefined
                  }
                />
              </Grid>
            </div>
            <HR $color={Color.quaternary} />
            <div>
              <Text weight={700} style={{ fontSize: "1.5rem" }}>
                ROI Simulator
              </Text>
              <Grid>
                <Section>
                  <Text weight={700} style={{ fontSize: "1.25rem" }}>
                    Investment
                  </Text>
                  <InlineInput
                    name="downPayment"
                    label="Down Payment"
                    value={formatter(downPayment, {
                      style: "currency",
                      currency: "USD"
                    })}
                    onChange={onInputChange()}
                  />
                  <InlineInput
                    name="renovationCosts"
                    label="Renovation Costs"
                    value={formatter(renovationCosts, {
                      style: "currency",
                      currency: "USD"
                    })}
                    onChange={onInputChange()}
                  />
                  <InlineInput
                    name="closingCosts"
                    label="Closing Costs"
                    value={formatter(closingCosts, { style: "percent" })}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange(true)}
                  />
                  <InlineInput
                    name="furnishingCosts"
                    label="Furnishing Costs"
                    value={formatter(furnishingCosts)}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange()}
                  />
                  <InlineInput
                    name="purchasePrice"
                    label="Purchase Price"
                    value={formatter(purchasePrice)}
                    onChange={onInputChange()}
                  />
                  <InlineInput
                    name="interestRate"
                    label="Interest Rate"
                    value={formatter(interestRate, { style: "percent" })}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange(true)}
                  />
                  <InlineInput
                    name="homeAppreciation"
                    label="Home Appreciation"
                    value={formatter(homeAppreciation, {
                      style: "percent"
                    })}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange(true)}
                  />
                  <InlineInput
                    name="mortgageTerm"
                    label="Mortage Term in Years"
                    value={mortgageTerm}
                    onChange={onInputChange()}
                  />
                </Section>
                <Section>
                  <Text weight={700} style={{ fontSize: "1.25rem" }}>
                    Income
                  </Text>
                  <InlineInput
                    name="occupancyRate"
                    label="Occupancy Rate"
                    value={formatter(occupancyRate, { style: "percent" })}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange(true)}
                  />
                  <InlineInput
                    name="netAdr"
                    label="Net ADR"
                    value={formatter(netAdr, {
                      style: "currency",
                      currency: "USD"
                    })}
                    onChange={onInputChange()}
                  />
                  <InlineInput
                    name="revenueGrowth"
                    label="Revenue Growth"
                    value={formatter(revenueGrowth, { style: "percent" })}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange(true)}
                  />
                  <InlineInput
                    name="grossRevenue1"
                    label="Gross Revenue Year 1"
                    value={formatter(grossRevenue1, {
                      style: "currency",
                      currency: "USD"
                    })}
                    onChange={onInputChange()}
                  />
                  <InlineInput
                    name="grossRevenue5"
                    label="Gross Revenue Year 5"
                    value={formatter(grossRevenue5, {
                      style: "currency",
                      currency: "USD"
                    })}
                    onChange={onInputChange()}
                  />
                </Section>
                <Section>
                  <Text weight={700} style={{ fontSize: "1.25rem" }}>
                    Variable Expenses
                  </Text>
                  <InlineInput
                    name="otaFee"
                    label="OTA (Airbnb) Fee"
                    value={formatter(otaFee, { style: "percent" })}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange(true)}
                  />
                  <InlineInput
                    name="lodgingTax"
                    label="Lodging Tax"
                    value={formatter(lodgingTax, { style: "percent" })}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange(true)}
                  />
                  <InlineInput
                    name="propertyManagement"
                    label="Property Management"
                    value={formatter(propertyManagement, {
                      style: "percent"
                    })}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange(true)}
                  />
                  <InlineInput
                    name="maintenance"
                    label="Maintenance"
                    value={formatter(maintenance, { style: "percent" })}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange(true)}
                  />
                  <InlineInput
                    name="cleaningFees"
                    label="Cleaning Fees"
                    value={formatter(cleaningFees, { style: "percent" })}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange(true)}
                  />
                </Section>
                <Section>
                  <Text weight={700} style={{ fontSize: "1.25rem" }}>
                    Fixed Expenses
                  </Text>
                  <InlineInput
                    name="mortgagePayment"
                    label="Mortage Payment"
                    value={formatter(mortgagePayment, {
                      style: "currency",
                      currency: "USD"
                    })}
                    onChange={onInputChange()}
                  />
                  <InlineInput
                    name="strLicensing"
                    label="STR Licensing"
                    value={formatter(strLicensing, {
                      style: "currency",
                      currency: "USD"
                    })}
                    onChange={onInputChange()}
                  />
                  <InlineInput
                    name="homeownersInsurance"
                    label="Homeowners Insurance"
                    value={formatter(homeownersInsurance, {
                      style: "currency",
                      currency: "USD"
                    })}
                    onChange={onInputChange()}
                  />
                  <InlineInput
                    name="propertyTax"
                    label="Property Tax"
                    value={formatter(propertyTax, { style: "percent" })}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange(true)}
                  />
                  <InlineInput
                    name="annualUtilities"
                    label="Annual Utilities"
                    value={formatter(annualUtilities, {
                      style: "currency",
                      currency: "USD"
                    })}
                    onChange={onInputChange()}
                  />
                  <InlineInput
                    name="hoa"
                    label="HOA"
                    value={formatter(hoa, { style: "percent" })}
                    onKeyDown={onKeyDown}
                    onChange={onInputChange(true)}
                  />
                  <InlineInput
                    name="PMI"
                    label="Monthly PMI"
                    value={formatter(PMI)}
                    onChange={onInputChange()}
                  />
                </Section>
              </Grid>
            </div>
            <HR $color={Color.quaternary} />
            <Grid>
              <Metric
                loading={loading}
                inverse
                borderColor={Color.tertiary}
                color={Color.quinary}
                title="Gross Yield"
                style={{ textAlign: "center", fontSize: "1.5rem" }}
                value={formatter(grossRevenue1 / purchasePrice, {
                  style: "percent"
                })}
                // tooltip={PlaceholderTooltip}
              />
              <Metric
                loading={loading}
                inverse
                borderColor={Color.secondary}
                color={Color.tertiary}
                title="Net Cash Flow"
                value={{
                  "Year 1": formatter(netIncome1, {
                    style: "currency",
                    currency: "USD"
                  }),
                  "Year 5": formatter(netIncomeAfter1(grossRevenue5), {
                    style: "currency",
                    currency: "USD"
                  })
                }}
                // tooltip={PlaceholderTooltip}
              />
              <Metric
                loading={loading}
                borderColor={Color.secondary}
                color={Color.primary}
                title="Cap Rate"
                value={formatter(netOperatingIncome / purchasePrice, {
                  style: "percent"
                })}
                // tooltip={PlaceholderTooltip}
              />
              <Metric
                loading={loading}
                inverse
                borderColor={Color.secondary}
                color={Color.quinary}
                title="Cash on Cash"
                value={formatter(
                  netIncome1 /
                    (downPayment +
                      closingCosts * purchasePrice +
                      renovationCosts +
                      furnishingCosts),
                  {
                    style: "percent"
                  }
                )}
                // tooltip={PlaceholderTooltip}
              />
              <Metric
                loading={loading}
                inverse
                borderColor={Color.secondary}
                color={Color.primary}
                title="Investment Value"
                subtitle="Estimate Year 2027"
                value={formatter(investmentValueAfterYear(5), {
                  style: "currency",
                  currency: "USD"
                })}
                // tooltip={PlaceholderTooltip}
              />
            </Grid>
            <Section style={{ marginTop: "1rem" }}>
              <Flex style={{ justifyContent: "center" }}>
                <Text
                  weight={300}
                  style={{
                    textAlign: mobileSize ? "center" : "initial",
                    fontSize: mobileSize ? "1rem" : "1.5rem",
                    color: "var(--tertiary)"
                  }}
                >
                  Estimated Investment Value
                </Text>
              </Flex>
              <div
                style={{
                  height: "450px",
                  padding: mobileSize ? "0rem" : "1rem"
                }}
              >
                <StackedBarChart
                  data={new Array(mobileSize ? 5 : 10)
                    .fill(false)
                    .map((discard, idx) => ({
                      year: String(new Date().getFullYear() + 1 + idx),
                      values: [
                        {
                          name: "Cummulative Net Cash Flow",
                          value: new Array(idx + 1)
                            .fill(false)
                            .map((income, incomeIdx) => {
                              if (incomeIdx === 0) {
                                return netIncome1;
                              }

                              return netIncomeAfter1(
                                otherGrossRevenues(incomeIdx + 1)
                              );
                            })
                            .reduce((prev, curr) => prev + curr, 0)
                        },
                        {
                          name: "Cummulative Equity",
                          value:
                            downPayment +
                            calculateLoanDataAfterMonth((idx + 1) * 12)
                              .totalPrincipalPaid +
                            renovationCosts
                        },
                        {
                          name: "Cummulative Appreciation",
                          value: appreciationAfterYear(idx + 1)
                        }
                      ]
                    }))}
                  line={[
                    {
                      name: "Est. Investment Value",
                      values: new Array(10)
                        .fill(false)
                        .map((discard, idx) =>
                          investmentValueAfterYear(idx + 1)
                        )
                    }
                  ]}
                />
              </div>
            </Section>
          </>
          {/* )} */}
        </div>
      </Section>
      {!showInstructions && (
        <ArrowsWrapper>
          <Arrows className="arrows" src="/arrows.png" alt="Continue down" />
        </ArrowsWrapper>
      )}
      <SupportedMarketsModal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(false);
          setUnsupportedMarket(false);
        }}
        header={
          unsupportedMarket ? (
            <Text
              weight={400}
              color={Color.tertiary}
              style={{ fontSize: "2rem" }}
            >
              WE APOLOGIZE BUT THE MARKET IS NOT SUPPORTED YET!
            </Text>
          ) : undefined
        }
      />
    </>
  );
};

export default StrCalculatorBody;
