import React from "react";
import styled from "styled-components";
import { FieldProps } from "./Field";
import Text from "./Text";

export interface InlineInputProps
  extends FieldProps,
    React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  symbol?: string;
}

export const StyledInlineInput = styled.label<{ symbol?: string }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  position: relative;
  width: 100%;

  * {
    flex: 0 1 auto;
  }

  input {
    background-color: var(--white);
    border: 1px solid var(--primary);
    border-radius: 0.25rem;
    box-sizing: border-box;

    font-weight: 300;
    margin-left: 0.5rem;
    padding: 0.25rem;
    width: calc(70px + 0.5rem);
  }
`;

const InlineInput = ({ id, label, symbol, ...props }: InlineInputProps) => {
  return (
    <StyledInlineInput htmlFor={id} symbol={symbol}>
      <Text style={{ paddingBottom: 0 }} weight={300}>
        {label}
      </Text>
      <input id={id} {...props} />
    </StyledInlineInput>
  );
};

InlineInput.propTypes = {
  type: String
  // other PropTypes declarations...
};

InlineInput.defaultProps = {
  type: "text"
};

export default InlineInput;
