import { Helmet } from "react-helmet";
import React, { useContext } from "react";
import { AppContext } from "../../components/AppContext";
import StrCalculatorBody from "./StrCalculatorBody";

export default function StrCalculator() {
  const { mobileSize } = useContext(AppContext);
  return (
    <div style={{ paddingTop: mobileSize ? "100px" : "120px" }}>
      <Helmet>
        <link rel="canonical" href="https://www.getchalet.com/str-calculator" />
        <title>Chalet | FREE Short-Term Rental (Airbnb) Calculator</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="FREE Airbnb Investment Estimator.
            Enter an address to find out how much your property
            can make as a short-term rental. Estimates on Annual Revenue, Cap Rates,
            Earning Potentials, ROI, ADR, Occupancy Rate, Cash on Cash, Yields, and more."
        />
        <meta
          property="og:title"
          content="Chalet | FREE Short-Term Rental (Airbnb) Calculator"
        />
        <meta
          property="og:description"
          content="FREE Airbnb Investment Calculator.
            Enter an address to find out how much your property
            can make as a short-term rental. Annual Revenue, Cap Rates,
            Earning Potentials, ROI, ADR, Occupancy Rate, Cash on Cash, Yields, and more."
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/str-calculator"
        />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      {/* <Header
        style={{
          // textTransform: "uppercase",
          // fontWeight: "bold",
          color: "var(--tertiary)",
          fontSize: mobileSize ? "2rem" : "3rem"
        }}
      >
        Airbnb Calculator
      </Header> */}
      <StrCalculatorBody />
    </div>
  );
}
