import React, { ForwardedRef, HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export interface FieldProps extends HTMLAttributes<HTMLInputElement> {
  as?: React.ReactNode;
  autoComplete?: string;
  invalid?: boolean;
  valid?: boolean;
  label?: string;
  name?: string;
  readOnly?: boolean;
  type?: string;
  value?: any;
}

export const StyledField = styled.label<{ invalid?: boolean; valid?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;

  input,
  textarea {
    background-color: var(--white);
    border: 1px solid rgb(0 0 0 / 40%);
    border-radius: 0.25rem;
    box-shadow: 0px 0px 43px rgb(0 0 0 / 10%);
    -webkit-appearance: none;
    color: var(--tertiary);

    font-size: 1rem;
    max-width: 100%;
    padding: 0.55rem 1rem;
    &:focus {
      outline: 1px solid var(--secondary);
      border: 1px solid var(--secondary);
    }

    ${(props) =>
      props.invalid &&
      css`
        border-color: var(--danger);
        outline-color: var(--danger);

        &:focus {
          border-color: var(--danger);
          outline-color: var(--danger);
        }
      `}

    ${(props) =>
      props.valid &&
      css`
        border-color: var(--success);
        outline-color: var(--success);

        &:focus {
          border-color: var(--success);
          outline-color: var(--success);
        }
      `}
  }
`;

const Field = React.forwardRef(
  (
    { as, children, label, invalid, valid, value, ...props }: FieldProps,
    ref: ForwardedRef<any>
  ) => {
    const Tag: any = as || "input";
    return (
      <StyledField invalid={invalid} valid={valid}>
        {label}
        <Tag {...props} value={value || ""} tabIndex={0} ref={ref} required />
        {children}
      </StyledField>
    );
  }
);

export default Field;
