import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppContext } from "../components/AppContext";
import Header from "../components/Header";
import Image from "../components/Image";
import Section from "../components/Section";
import Text from "../components/Text";
import SplitView from "../components/SplitView";
import { Color } from "../types";
import HR from "../components/HR";
import Button from "../components/Button";
import SectionFooter from "../components/SectionFooter";
import Group from "../components/Group";
import ContactUsModal from "./modals/ContactUsModal";

const ChaletConcierge = () => {
  const { mobileSize } = useContext(AppContext);
  const history = useHistory();

  const handleDownloadReportClick = () => {
    history.push("/download-report");
  };

  const handleContactUsClick = () => {
    // window.location.href = "https://form.typeform.com/to/ZbQsTdlZ";
    window.location.href =
      "https://form.typeform.com/to/ZbQsTdlZ#market_of_interest=Not Specified&source_page=concierge";
  };

  return (
    <div style={{ paddingTop: "208px" }}>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.getchalet.com/chalet-concierge"
        />
        <title>
          Airbnb Investment Consulting | Achieve higher yields through
          short-term rentals
        </title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Your team of short-term rental experts, always one call
          away to help you navigate the home buying investment journey.
          We are there for our investors from idea inception to post close management of an Airbnb vacation rental"
        />
        <meta
          property="og:title"
          content="Airbnb Investment Consulting | Achieve higher yields through short-term rentals"
        />
        <meta
          property="og:description"
          content="Your team of short-term rental experts, always one call
          away to help you navigate the home buying investment journey.
          We are there for our investors from idea inception to post close management of an Airbnb vacation rental"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/chalet-concierge"
        />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Header
        size={2}
        // font={Font.Pacifico}
        style={{
          fontSize: mobileSize ? "2rem" : "3rem",
          color: "var(--tertiary)"
        }}
      >
        Airbnb Investment Consulting
      </Header>
      <Section style={{ marginBottom: "2rem" }}>
        <Header size={2}>
          Achieve higher yields through short-term rentals
        </Header>
        <SplitView>
          <div>
            <Text
              block
              size="1.5rem"
              align="left"
              weight={300}
              style={{ lineHeight: "3rem" }}
              lineHeight="3.5rem"
            >
              We are your team of short-term rental experts, one call away, who
              help you navigate the home buying journey.
            </Text>
            <HR $color={Color.primary} />
            <Text
              block
              size="1.5rem"
              align="left"
              weight={300}
              lineHeight="3.5rem"
              style={{ lineHeight: "3rem" }}
            >
              We are there for our investors from idea inception to post close
              management of an Airbnb rental.
            </Text>
            {/* <Text
              block
              size="1.3rem"
              align="left"
              
            >
              We make our{" "}
              <Text
                weight={700}
                color={Color.primary}
                style={{ fontSize: "1.5rem" }}
              >
                $$
              </Text>{" "}
              through our vendor network. This means our Airbnb Investment Consulting
              service is available to investors like you at{" "}
              <Text
                weight={700}
                color={Color.primary}
                style={{ fontSize: "1.3rem" }}
              >
                $0 cost.
              </Text> */}
            {/* </Text> */}
          </div>
          <Image
            src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.webp`}
            alt="Chalet cycle"
            style={{ padding: "1rem", width: "80%", margin: "0 auto" }}
            $mobileSize={mobileSize}
          />
        </SplitView>
        <SectionFooter $mobileSize={mobileSize}>
          <Button
            block
            color={Color.primary}
            style={{ [mobileSize ? "marginTop" : "marginLeft"]: "1rem" }}
            onClick={handleContactUsClick}
            data-id="chalet-concierge_get_started_button"
          >
            GET STARTED
          </Button>
        </SectionFooter>
      </Section>
      <Group>
        <Header
          color={Color.primary}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "var(--tertiary)"
          }}
        >
          Analyze
        </Header>
        <Section>
          <Header
            style={{ fontWeight: 400, color: "var(--tertiary)" }}
            size={3}
            color={Color.primary}
          >
            We&apos;ve done the nitty gritty research so that you don&apos;t
            have to
          </Header>
          <SplitView>
            <Image
              src={`${process.env.PUBLIC_URL}/images/mark_report.webp`}
              alt="Market Report"
              style={{ padding: "1rem" }}
              $mobileSize={mobileSize}
            />
            <div>
              <Text
                block
                size="1.5rem"
                align="left"
                weight={300}
                lineHeight="2.5rem"
              >
                No matter your preferred market or investment goal, we will help
                you crunch the numbers and give you a data-driven projection of
                your return-on-investment(ROI).
              </Text>
              <HR $color={Color.primary} />
              <Text
                block
                size="1.5rem"
                align="left"
                weight={300}
                lineHeight="2.5rem"
              >
                We assist investors who already have markets in mind as well as
                investors who prefer direction.
              </Text>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <Button
              block
              color={Color.primary}
              onClick={handleDownloadReportClick}
              data-id="chalet-concierge_analyze_download_market_report_button"
            >
              DOWNLOAD A FREE AIRBNB MARKET REPORT
            </Button>
          </SectionFooter>
        </Section>
      </Group>
      <Group>
        <Header
          color={Color.primary}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "var(--tertiary)"
          }}
        >
          Invest
        </Header>
        <Section>
          <Header
            style={{ fontWeight: 400, color: "var(--tertiary)" }}
            size={3}
            color={Color.primary}
          >
            We help you invest with confidence
          </Header>
          <SplitView>
            <div>
              <Text
                block
                size="1.5rem"
                align="left"
                weight={300}
                lineHeight="2.5rem"
              >
                Once we understand your investment preferences and you are ready
                to buy, we will pair you with a trusted Chalet Partner Realtor.
              </Text>
              <Text
                block
                size="1.5rem"
                align="left"
                weight={300}
                lineHeight="2.5rem"
              >
                Our Chalet Partner Realtors are vetted, local experts who deeply
                understand the short-term rental landscape in their markets.
              </Text>
              <HR $color={Color.primary} />
              <Text
                block
                size="1.5rem"
                align="left"
                weight={300}
                lineHeight="2.5rem"
              >
                But that&apos;s not all! We will continue our support by
                analyzing all of your favorite properties. Our analysis will
                give you projection on several key buyer decision questions
                including revenue, occupancy, supply & demand, and more!
              </Text>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/invest.webp`}
              alt="Rental"
              style={{ padding: "1rem", borderRadius: "0.25rem" }}
              $mobileSize={mobileSize}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <Button
              block
              color={Color.quaternary}
              onClick={handleContactUsClick}
              data-id="chalet-concierge_invest_get_started_button"
            >
              GET STARTED
            </Button>
          </SectionFooter>
        </Section>
      </Group>
      <Group>
        <Header
          color={Color.primary}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "var(--tertiary)"
          }}
        >
          Manage
        </Header>
        <Section color={Color.white}>
          <Header
            style={{ fontWeight: 400, color: "var(--tertiary)" }}
            size={3}
            color={Color.primary}
          >
            We will find you the right property management partner for your
            needs
          </Header>
          <SplitView>
            <Image
              src={`${process.env.PUBLIC_URL}/images/manage.webp`}
              alt="Mountain resort"
              $mobileSize={mobileSize}
              style={{ padding: "1rem", borderRadius: "0.25rem" }}
            />
            <div>
              <Text
                block
                size="1.5rem"
                align="left"
                weight={300}
                lineHeight="2.5rem"
                style={{ color: "var(--primary)" }}
              >
                Choosing the right property management partner is as important
                as picking the perfect property.
              </Text>
              <HR $color={Color.primary} />
              <Text
                block
                size="1.5rem"
                align="left"
                weight={300}
                lineHeight="2.5rem"
                style={{ color: "var(--primary)" }}
              >
                We&apos;ve done the research and vetted the candidates, so you
                don&apos;t have to. You’ll have full access to our{" "}
                <Text
                  weight={400}
                  size="1.5rem"
                  style={{ color: "var(--primary)" }}
                >
                  Chalet Partner Property Management Network
                </Text>{" "}
                ensuring peace of mind, knowing your property is in safe and
                competent hands
              </Text>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <Button
              block
              color={Color.quaternary}
              onClick={handleContactUsClick}
              data-id="chalet-concierge_manage_find_property_manager_button"
            >
              FIND A PROPERTY MANAGER
            </Button>
          </SectionFooter>
        </Section>
      </Group>
      <Group>
        <Header
          color={Color.primary}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "var(--tertiary)"
          }}
        >
          Enjoy And Earn
        </Header>
        <Section>
          <Header
            style={{ fontWeight: 400, color: "var(--tertiary)" }}
            size={3}
            color={Color.primary}
          >
            You did the hard work, now reap the rewards
          </Header>
          <SplitView>
            <div>
              <Text
                block
                size="1.5rem"
                align="left"
                weight={300}
                lineHeight="2.5rem"
                // textAlign="left"
              >
                Our mission is to make investing in short-term and vacation
                rentals extremely accessible, easy, and cost-effective. And if
                we do our job right, we hope you’ll come back for more!
              </Text>
              <HR $color={Color.primary} />
              <Text
                block
                size="1.5rem"
                align="left"
                weight={300}
                lineHeight="2.5rem"
              >
                But it all starts with a call. Reach out now to get started on
                realizing your dreams.
              </Text>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/enjoyAndEarn.webp`}
              alt="Chalet"
              $mobileSize={mobileSize}
              style={{
                padding: "1rem",
                aspectRatio: "4/3",
                borderRadius: "0.25rem"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <Button
              block
              color={Color.primary}
              onClick={handleContactUsClick}
              data-id="chalet-concierge_enjoy_and_earn_own_airbnb_button"
            >
              OWN AN AiRBNB (SHORT-TERM) RENTAL
            </Button>
          </SectionFooter>
        </Section>
      </Group>
      <ContactUsModal />
    </div>
  );
};

export default ChaletConcierge;
