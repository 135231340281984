import { Link } from "react-router-dom";
import styled from "styled-components";
import React, { ForwardedRef } from "react";

export interface NavbarItemProps extends React.HTMLAttributes<HTMLLIElement> {
  href: string;
}

export const StyledNavbarItem = styled.li`
  border-radius: 0.25rem;
  > a {
    align-items: center;
    color: var(--primary);
    display: block;
    font-weight: 300;
    text-transform: uppercase;
    height: 100%;
    padding: 0.8rem 1rem;
    text-decoration: none;
  }
  &:hover > a {
    color: var(--white);
    background-color: var(--secondary);
  }
`;

const NavbarItem = React.forwardRef(
  ({ children, href, ...props }: NavbarItemProps, ref: ForwardedRef<any>) => (
    <StyledNavbarItem ref={ref} {...props}>
      <Link to={href}>{children}</Link>
    </StyledNavbarItem>
  )
);

export default NavbarItem;
