import React from "react";
import styled from "styled-components";
import Flex from "../../../components/Flex";

const GrossYieldWrapper = styled(Flex)`
  align-items: center;
  color: var(--white);
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  .gross-yield-title {
    color: var(--white);
    font-size: 2rem;
    font-weight: 400;
    white-space: nowrap;
  }

  .gross-yield {
    color: var(--white);
    font-size: 2.5rem;
    font-weight: 700;
  }

  .gross-yield-ranking {
    color: var(--lightBlue);
    font-size: 1.375rem;
    text-decoration: underline;
  }
`;

export default function GrossYield({
  grossYield,
  ranking
}: {
  grossYield: string;
  ranking: string;
}) {
  return (
    <GrossYieldWrapper>
      <div className="gross-yield-title">Gross Yield</div>
      <div className="gross-yield">{grossYield}</div>
      <div className="gross-yield-ranking">#{ranking} in the US</div>
    </GrossYieldWrapper>
  );
}
