import { createGlobalStyle } from "styled-components";
import hexToRgb from "./hexToRgb";

export enum Colors {
  primary = "#373737",
  secondary = "#FB929D",
  tertiary = "#2F354B",
  fourth = "#F06F7C",
  sixth = "#695979",
  quaternary = "#F4246C",
  quinary = "#F4246C",
  // white = "#FFFFFF",
  white = "#FFF8EA",
  black = "#000000",
  gray = "#EAEAEA",
  success = "#8CD867",
  danger = "#D01E11",
  seventh = "#FEC423",
  eighth = "#BC6A81",
  lightPurple = "#EEEFF8",
  pinkRed = "#DB005F",
  lightBlue = "#88CDC3",
  radialGradient = "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #FB929D 71.35%)",
  linearGradient = "linear-gradient(178deg, #F06F78 1.34%, #F4246C 98.66%)",
  linearGradient5 = "linear-gradient(1.82deg, #695979 -89.78%, #F4246C 102.71%)"
}

const GlobalStyle = createGlobalStyle`
  html {
    --primary: ${Colors.primary};
    --secondary: ${Colors.secondary};
    --tertiary: ${Colors.tertiary};
    --quaternary: ${Colors.quaternary};
    --quinary: ${Colors.quinary};
    --white: ${Colors.white};
    --black: ${Colors.black};
    --gray: ${Colors.gray};
    --fourth: ${Colors.fourth};
    --sixth: ${Colors.sixth};
    --seventh: ${Colors.seventh};
    --eighth: ${Colors.eighth};
    --linearGradient: ${Colors.linearGradient};
    --linearGradient5: ${Colors.linearGradient5};
    --radialGradient: ${Colors.radialGradient};
    --success: ${Colors.success};
    --danger: ${Colors.danger};
    --lightPurple: ${Colors.lightPurple};
    --lightBlue: ${Colors.lightBlue};
    --pinkRed: ${Colors.pinkRed};

    scroll-behavior:smooth;
    color: var(--primary);
    font-family: "Oswald", Impact, "Franklin Gothic Bold", sans-serif;
    font-size: 16px;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--tertiary);
    text-align: left;
    font-family: "Oswald", Impact, "Franklin Gothic Bold", sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    opacity: 0.6; /* Firefox */
  }
  body {
    font-family: "Oswald", Impact, "Franklin Gothic Bold", sans-serif;
    margin: 0;
    background: var(--white);
    letter-spacing: -0.8px;
    line-height: 1.8rem;
    
    * {
      box-sizing: border-box;
      font-family: "Oswald", Impact, "Franklin Gothic Bold", sans-serif;
      color: var(--tertiary);
    }

    &.font-loaded {
      letter-spacing: 0;
      line-height: initial;
    }
  }
  
  .pac-container {
    background-color: var(--white);
    border-radius: 0.25rem;
    box-shadow: 0 0 43px rgba(0, 0, 0, 0.1);
    padding: 0.25rem;
  }
  
  .pac-item {
    background: none;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 1.25rem;
    font-family: "Oswald", Impact, "Franklin Gothic Bold", sans-serif;
    outline: none;
    padding: 0.5rem;
    text-align: left;
    color: var(--primary);
    width: 100%;

    &:hover,
    &:focus,
    &:active,
    &.active 
    {
      background-color: rgba(${hexToRgb(Colors.primary)}, 0.2);
    }
    
    .pac-item-query {
      font-size: 1.25rem !important;
      font-weight: 400 !important;
    }
    
    .pac-icon {
      display: none;
    }
  }
  
  .pac-item-selected {
    background-color: rgba(${hexToRgb(Colors.primary)}, 0.2);
  }
  input[type="range"] {
    -webkit-appearance: none;
    margin-right: 15px;
    width: 200px;
    height: 7px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 25px;
    background-image: radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #FB929D 71.35%);
    background-size: 100% 100%;
    padding: 0.5rem;
    background-repeat: no-repeat;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 40px;
    width: 40px;
    border-radius: 0.5rem;
    background: var(--white);
    border: 10px solid var(--secondary);
    cursor: ew-resize;
    // box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }
  
  input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export default GlobalStyle;
