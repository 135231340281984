import { Bar } from "react-chartjs-2";
import React from "react";
import {
  Chart as ChartJS,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  defaults
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Colors } from "../../../components/GlobalStyle";
import {
  compactNotation,
  currencyNotation,
  numberNotation,
  percentNotation
} from "../../../utils/numberFormatters";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import { Font } from "../../../components/Text";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  ChartDataLabels
);

defaults.font.family = Font.Oswald;

const labelSort = (sortValues: boolean) => (a: any, b: any) => {
  if (sortValues) {
    if (parseFloat(a[1]) > parseFloat(b[1])) {
      return -1;
    }
    if (parseFloat(a[1]) < parseFloat(b[1])) {
      return 1;
    }
    return 0;
  }

  if (a[0] === "Studio" || b[0] === "Studio") {
    return 1;
  }

  if (a[0] > b[0]) {
    return 1;
  }

  if (a[0] < b[0]) {
    return -1;
  }
  return 0;
};

export default function BarChart({
  aspectRatio = 1.75,
  color,
  data,
  dataType = "currency",
  datalabels,
  height,
  width,
  percent,
  lighter,
  horizontal = false,
  padding,
  sort = "labels",
  stacked,
  xGrid,
  yGrid,
  xAxis,
  yAxis,
  xAxisPosition,
  yAxisPosition,
  yAxisStyle
}: {
  aspectRatio?: number;
  color?: Colors;
  data: Record<string, any>;
  dataType?: "currency" | "percent" | "number";
  datalabels?: boolean;
  height?: number;
  width?: number;
  lighter?: boolean;
  percent?: boolean;
  padding?: Record<string, number>;
  horizontal?: boolean;
  sort?: "labels" | "values";
  stacked?: boolean;
  xGrid?: boolean;
  yGrid?: boolean;
  xAxis?: boolean;
  yAxis?: boolean;
  xAxisPosition?: "top" | "bottom";
  yAxisPosition?: "left" | "right";
  yAxisStyle?: Record<any, any>;
}) {
  const labels: string[] = [];
  const horizontalLabels: string[] = [];
  const values: number[] = [];
  const sortValues = sort === "values";

  Object.entries(data)
    .sort(labelSort(sortValues))
    .forEach(([key, value]: [string, any]) => {
      if (key !== "market") {
        labels.push(key.replace("Bedrooms", "BR").replace("Bedroom", "BR"));
        if (typeof value === "number") {
          values.push(parseFloat(String(percent ? value / 100 : value)));
        } else {
          horizontalLabels.push(value);
          const parsed = parseFloat(
            String(value).replace("$", "").replace("+", "").replace(",", "")
          );
          values.push(percent ? parsed / 100 : parsed);
        }
      }
    });

  const largest = Math.max.apply(0, values);
  const reverseValues = values.map(() => largest);

  return (
    <Bar
      data={{
        labels,
        datasets: [
          {
            labels: horizontal ? horizontalLabels : labels,
            data: values,
            backgroundColor:
              color || (lighter ? Colors.secondary : Colors.quaternary)
          },
          ...(stacked
            ? [
                {
                  labels: horizontal ? horizontalLabels : labels,
                  data: reverseValues,
                  backgroundColor: Colors.gray,
                  plugins: {
                    datalabels: {
                      display: false
                    }
                  }
                }
              ]
            : [])
        ] as any
      }}
      height={height}
      width={width}
      options={{
        indexAxis: horizontal ? "y" : "x",
        aspectRatio: height ? undefined : aspectRatio,
        elements: {
          bar: {
            borderRadius: 5
          }
        },
        layout: {
          padding: padding || {
            top: 16,
            left: 8,
            right: 8
          }
        },
        plugins: {
          datalabels: {
            display: !isNullOrUndefined(datalabels) ? datalabels : horizontal,
            color: Colors.white,
            formatter: (value, context) => {
              if (context.datasetIndex === 0) {
                if (percent || dataType === "percent") {
                  return `${
                    (context.dataset as any).data[context.dataIndex] * 100
                  }%`;
                }

                if (dataType === "currency") {
                  return `+${currencyNotation(
                    (context.dataset as any).data[context.dataIndex]
                  )}`;
                }

                return numberNotation(
                  (context.dataset as any).data[context.dataIndex]
                );
              }
              return "";
            },
            font: {
              weight: "bold"
            }
          },
          tooltip: {
            callbacks: {
              label: (context: any) => {
                if (context.datasetIndex === 0) {
                  if (percent || dataType === "percent") {
                    return percentNotation(
                      context.parsed[horizontal ? "x" : "y"]
                    );
                  }

                  if (dataType === "currency") {
                    return currencyNotation(
                      context.parsed[horizontal ? "x" : "y"]
                    );
                  }

                  return numberNotation(context.parsed[horizontal ? "x" : "y"]);
                }

                return "";
              }
            },
            filter: (tooltipItem: any): any => {
              if (tooltipItem.datasetIndex === 0) {
                return true;
              }
              return false;
            }
          }
        },
        scales: {
          [horizontal ? "x" : "y"]: {
            display: yAxis || !horizontal,
            position: horizontal ? xAxisPosition : yAxisPosition,
            ticks: {
              color: horizontal ? Colors.quaternary : yAxisStyle?.color,
              callback: (value: any) =>
                percent ? `${Math.round(value * 100)}%` : compactNotation(value)
            },
            grid: {
              display: yGrid || false,
              borderDash: [8]
            }
          },
          [horizontal ? "y" : "x"]: {
            display: xAxis || horizontal,
            ticks: {
              color: horizontal ? yAxisStyle?.color : yAxisStyle?.color,
              font: {
                weight: yAxisStyle?.fontWeight
              }
            },
            stacked,
            position: horizontal ? yAxisPosition : xAxisPosition,
            grid: {
              display: xGrid || false
            }
          }
        }
      }}
    />
  );
}
