import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { compactNotation } from "../utils/numberFormatters";

interface FeaturedProps {
  children: React.ReactNode;
  title: string;
  actions?: React.ReactNode;
}

interface FeaturedPostProps {
  image: string;
  title: string;
  text: string;
  href?: string;
  onClick?: () => void;
}

interface FeaturedCenterProps {
  image: string;
  title: string;
  revenue?: string;
  medianHomeValue?: string;
  gross?: string;
  text?: string;
  href?: string;
  // cta: string;
  right?: boolean;
}

const FeaturedContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  height: fit-content;
  margin-bottom: 1rem;
`;

const StyledFeaturedCenter = styled.div`
  flex: 1 1 calc(33% - 2rem);
  border-radius: 8px;
  box-shadow: 0 4px 48px rgba(182, 197, 255, 1);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  // background: #eeeff8;
  // background: url("${process.env.PUBLIC_URL}/images/markets-bg.webp");
  background: var(--white);

  background-size: cover;
  cursor: pointer;

  .image {
    max-width: 40%;
    // min-width: 200px;
    width: 100%;
    height: 100%;
    display: block;
    background-size: cover;
    background-position: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .title {
    font-size: 1.625rem;
    font-weight: 400;
    text-transorm: uppercase;
    color: var(--white);
    padding: 0.5rem;
    background: var(--linearGradient);
    // margin-bottom: 8px;
  }

  .text {
    font-size: 1.2rem;
    font-weight: 300;
    // padding: 0.5rem;
    color: var(--tertiary);
    margin-bottom: 16px;
  }

  .read-more-button {
    align-self: center;
    padding: 10px 20px;
    background: #ffcf83;
    color: #695979;
    font-size: 1.5rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1;
  }

  .stats {
    margin-bottom: 2rem;
    padding: 1rem 1rem 0rem 0.5rem;
    width: 100%;
  }

  .revenue,
  .gross,
  .medianHomeValue {
    display: flex;
    font-size: 1.2rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    font-weight: 300;
    justify-content: space-between;

    .value {
      color: var(--tertiary);
      font-size: 1.3rem;
      font-weight: 400;
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    flex: 1 1 230px;
  }
`;

const StyledFeaturedPost = styled.div`
  flex-basis: 64%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  // background: #eeeff8;
  background: url("/images/markets-bg.webp");
  background-size: cover;

  .image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .content {
    height: 100%;
    padding: 20px;
  }

  .title {
    font-size: 1.6rem;
    font-weight: 300;
    color: #333;
    margin-bottom: 8px;
  }

  .text {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 16px;
  }

  .read-more-button {
    align-self: start;
    padding: 10px 20px;
    background: #ffcf83;
    color: #695979;
    font-size: 1.2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
  }
`;

const StyledFeaturedRight = styled.div`
  flex-basis: 33%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  // background: #fcddec;
  background: url("/images/blue-fade.webp");
  background-size: cover;
  display: flex;
  flex-direction: row;

  .image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .content {
    padding: 20px;
  }

  .title {
    font-size: 1.6rem;
    font-weight: 300;
    color: #333;
    margin-bottom: 8px;
  }

  .text {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .read-more-button {
    align-self: start;
    padding: 10px 20px;
    background: #f06f7c;
    color: #fffffb;
    font-size: 1.2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
  }
`;

const StyledFeaturedLeft = styled.div`
  flex-basis: 33%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  // background: #ffcf83;
  background: url("/images/markets-bg.webp");
  background-size: cover;
  display: flex;
  flex-direction: row;

  .image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .content {
    padding: 20px;
  }

  .title {
    font-size: 1.6rem;
    font-weight: 300;
    color: #333;
    margin-bottom: 8px;
  }

  .text {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 16px;
  }

  .read-more-button {
    align-self: start;
    padding: 10px 20px;
    background: #f06f7c;
    font-size: 1.2rem;
    color: #fffffb;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
  }
`;

const ContentLinkWrapper = styled(Link)`
  display: contents;
`;

const convertToKFormat = (value: string) => {
  const numericPart = value.replace(/[^\d.]/g, "");
  const number = parseFloat(numericPart);
  return compactNotation(number);
};

const FeaturedTitle = styled.div`
  align-items: center;
  display: flex;
  border-bottom: 1px solid var(--lightBlue);
  justify-content: space-between;
  line-height: 2.25;
  margin-bottom: 1rem;
  font-size: 1.75rem;
  font-weight: 400;

  @media (max-width: 810px) {
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
  }
`;

const ActionWrapper = styled.div`
  @media (max-width: 810px) {
    display: flex;
    width: calc(100% + 4rem);
    overflow-x: auto;
    overflow-y: visible;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 0 2rem;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
`;

const Featured: React.FC<FeaturedProps> = ({ actions, children, title }) => {
  return (
    <div className="featured-section">
      {title && (
        <FeaturedTitle>
          {title}
          {actions && <ActionWrapper>{actions}</ActionWrapper>}
        </FeaturedTitle>
      )}
      <FeaturedContainer className="featured">{children}</FeaturedContainer>
    </div>
  );
};

const FeaturedPost: React.FC<FeaturedPostProps> = ({
  image,
  title,
  text,
  href,
  onClick
}) => {
  return (
    <ContentLinkWrapper to={href as string}>
      <StyledFeaturedPost className="featured-post" onClick={onClick}>
        <div className="image" style={{ backgroundImage: `url(${image})` }} />
        <div className="content">
          <div className="title">{title}</div>
          <div className="text">{text}</div>
          <button className="read-more-button" type="button">
            READ MORE
          </button>
        </div>
      </StyledFeaturedPost>
    </ContentLinkWrapper>
  );
};

const FeaturedCenter: React.FC<FeaturedCenterProps> = ({
  image,
  title,
  text,
  href,
  revenue,
  medianHomeValue,
  gross,
  // cta,
  right
}) => {
  const Tag = right ? StyledFeaturedRight : StyledFeaturedCenter;
  return (
    <ContentLinkWrapper to={href as string}>
      <Tag className="featured-post">
        <div className="content" style={{ width: "100%" }}>
          <div className="title">{title}</div>
          {text && <div className="text">{text}</div>}
          <div style={{ display: "flex", justifyContent: "start" }}>
            <div
              className="image"
              style={{ backgroundImage: `url(${image})` }}
            />
            {!text && gross && revenue && (
              <div className="stats">
                <div className="revenue">
                  Annual Revenue{" "}
                  <span className="value">{convertToKFormat(revenue)}</span>
                </div>
                <div className="gross">
                  Gross Yield <span className="value">{gross}</span>
                </div>
                <div className="medianHomeValue">
                  Median Home Value{" "}
                  <span className="value">{`$${medianHomeValue}`}</span>
                </div>
              </div>
            )}
            {/* <button className="read-more-button" type="button">
            {cta}
          </button> */}
          </div>
        </div>
      </Tag>
    </ContentLinkWrapper>
  );
};

const FeaturedRight: React.FC<FeaturedPostProps> = ({
  image,
  title,
  text,
  href,
  onClick
}) => {
  return (
    <ContentLinkWrapper to={href as string}>
      <StyledFeaturedRight className="featured-post" onClick={onClick}>
        <div className="content">
          <div className="title">{title}</div>
          <div className="text">{text}</div>
          <button className="read-more-button" type="button">
            READ MORE
          </button>
        </div>
        <div className="image" style={{ backgroundImage: `url(${image})` }} />
      </StyledFeaturedRight>
    </ContentLinkWrapper>
  );
};

const FeaturedLeft: React.FC<FeaturedPostProps> = ({
  image,
  title,
  text,
  href,
  onClick
}) => {
  return (
    <ContentLinkWrapper to={href as string}>
      <StyledFeaturedLeft className="featured-post" onClick={onClick}>
        <div className="image" style={{ backgroundImage: `url(${image})` }} />
        <div className="content">
          <div className="title">{title}</div>
          <div className="text">{text}</div>
          <button className="read-more-button" type="button">
            READ MORE
          </button>
        </div>
      </StyledFeaturedLeft>
    </ContentLinkWrapper>
  );
};

export { Featured, FeaturedPost, FeaturedRight, FeaturedLeft, FeaturedCenter };
