import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import SplitView from "../components/SplitView";
import Section from "../components/Section";
import { AppContext } from "../components/AppContext";
import Image from "../components/Image";
import Text from "../components/Text";
import HR from "../components/HR";
import Header from "../components/Header";

const STRResources = () => {
  const { mobileSize } = useContext(AppContext);

  return (
    <div style={{ marginTop: "220px", background: "var(--white)" }}>
      <Helmet>
        <link rel="canonical" href="https://www.getchalet.com/str-resources" />
        <title>Short Term Rental Resources</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Short Term Rental Resource Hub. Find Any Short-Term Rental Resource anywher in the us"
        />
        <meta property="og:title" content="Short Term Rental Resources" />
        <meta property="og:description" content="Short Term Rental Resources" />
        <meta property="og:image" content="Short Term Rental Resources" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/str-resources"
        />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Header size={1} style={{ marginTop: "-60px" }}>
        Our Preferred Short-Term Rental Partners
      </Header>
      <Section
        style={{
          // background: `url("${process.env.PUBLIC_URL}/images/markets-bg.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "0.25rem",
          boxShadow: "none"
          //   max
        }}
      >
        <Text block>
          Welcome to our Exclusive Partners&apos; Zone, where seamless
          short-term rental management becomes a reality. We are proud to
          collaborate with a handpicked selection of the industry&apos;s leading
          service providers, encompassing a broad spectrum of specialties from
          online travel agencies (OTAs) like Airbnb, to cutting-edge dynamic
          pricing software, comprehensive property insurance solutions,
          sophisticated property management systems (PMS), and professional
          cleaning services.
        </Text>
        <Text>
          {" "}
          Our partners are meticulously chosen to ensure that they share our
          commitment to excellence, innovation, and the provision of
          unparalleled service. By joining forces, we aim to empower property
          owners and managers with the tools and support necessary to optimize
          their operations, enhance guest experiences, and maximize their rental
          income. Discover the synergy of working with the best in the business
          and elevate your short-term rental venture with our preferred partners
          today.
        </Text>
        <SplitView
          split="1:2"
          style={{
            boxShadow: "0 0 43px rgba(0, 0, 0, 0.1)",
            margin: "2rem auto",
            background: "var(--white)",
            borderRadius: "0.5rem",
            alignItems: "center",
            padding: mobileSize ? "1rem" : "initial"
          }}
        >
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/pricelabs.png`}
              alt="Market Report"
              style={{
                maxWidth: "200px",
                padding: mobileSize ? "2rem 0 0" : "0rem",
                height: "auto",
                display: "block",
                margin: "0 auto"
              }}
              $mobileSize={mobileSize}
            />
            <a
              href="https://pricelabs.co/users/sign_up?referral=j6m1AR"
              target="_blank"
              // rel="noreferrer"
              style={{
                display: "block",
                margin: "1rem auto",
                maxWidth: "250px",
                padding: "0.7rem",
                borderRadius: "0.25rem",
                color: "var(--white)",
                textDecoration: "none",
                textTransform: "uppercase",
                textAlign: "center",
                fontSize: "1.2rem",
                background: "var(--quinary)"
              }}
              rel="noreferrer"
            >
              Visit PriceLabs
            </a>
          </div>

          <div>
            <Text block>
              PriceLabs is an industry-leading tool for automated pricing,
              favored by many property managers and hosts, for its extensive and
              reliable data set. Known for smart pricing, it also offers
              investors access to revenue projections and top Airbnb comps.
            </Text>
            <Text block>
              Use their Free Revenue Estimator Tool for quick projections or
              subscribe for deeper data analysis. Sign up via our{" "}
              <a
                href="https://pricelabs.co/users/sign_up?referral=j6m1AR"
                target="_blank"
                rel="noreferrer"
              >
                PriceLabs referral link
              </a>{" "}
              for access to a 30-day free trial and a $10 subscription credit.
            </Text>
          </div>
        </SplitView>
        <HR />
        <SplitView
          split="1:2"
          style={{
            boxShadow: "0 0 43px rgba(0, 0, 0, 0.1)",
            margin: "2rem auto",
            background: "var(--white)",
            borderRadius: "0.5rem",
            alignItems: "center",
            padding: mobileSize ? "1rem" : "initial"
          }}
        >
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/str-search.png`}
              alt="Market Report"
              style={{
                maxWidth: "200px",
                padding: mobileSize ? "2rem 0 0" : "0rem",
                height: "auto",
                display: "block",
                margin: "0 auto"
              }}
              $mobileSize={mobileSize}
            />
            <a
              href="https://johnbianchi.mykajabi.com/a/2147561422/z6hJXM5X"
              target="_blank"
              // rel="noreferrer"
              style={{
                display: "block",
                margin: "1rem auto",
                maxWidth: "250px",
                padding: "0.7rem",
                borderRadius: "0.25rem",
                color: "var(--white)",
                textDecoration: "none",
                textTransform: "uppercase",
                textAlign: "center",
                fontSize: "1.2rem",
                background: "var(--quinary)"
              }}
              rel="noreferrer"
            >
              Visit STR Search
            </a>
          </div>

          <div>
            <Text block>
              For those seeking a more in-depth analysis of their short-term
              rental (STR) data without the hassle of crunching numbers
              themselves, John Bianchi is the go-to expert.
            </Text>
            <Text block>
              John leads the data analysis team at TechVestor, a highly esteemed
              and top-performing STR fund. He offers comprehensive, deep-dive
              analytical services for properties, ensuring you get the detailed
              insights you need.
            </Text>
          </div>
        </SplitView>
        <HR />
        <SplitView
          split="1:2"
          style={{
            boxShadow: "0 0 43px rgba(0, 0, 0, 0.1)",
            margin: "2rem auto",
            background: "var(--white)",
            borderRadius: "0.5rem",
            alignItems: "center",
            padding: mobileSize ? "1rem" : "initial"
          }}
        >
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/airdna.png`}
              alt="Airdna market analysis"
              style={{
                maxWidth: "200px",
                padding: mobileSize ? "2rem 0 0" : "0rem",
                height: "auto",
                display: "block",
                margin: "0 auto"
              }}
              $mobileSize={mobileSize}
            />
            <a
              href="https://airdna.partnerlinks.io/bk5rkka6yjei"
              target="_blank"
              // rel="noreferrer"
              style={{
                display: "block",
                margin: "1rem auto",
                maxWidth: "250px",
                padding: "0.7rem",
                borderRadius: "0.25rem",
                color: "var(--white)",
                textDecoration: "none",
                textTransform: "uppercase",
                textAlign: "center",
                fontSize: "1.2rem",
                background: "var(--quinary)"
              }}
              rel="noreferrer"
            >
              Visit Airdna
            </a>
          </div>

          <div>
            <Text block>
              AirDNA stands out as a premier market research data provider for
              the short-term rental industry. Offering both free accounts and
              subscription options, it allows users to analyze and compare STR
              markets and potential investments effectively.
            </Text>
            <Text block>
              Ideal for those embarking on a new investment journey. Please use
              our
              <a
                href="https://airdna.partnerlinks.io/bk5rkka6yjei"
                target="_blank"
                rel="noreferrer"
              >
                AirDNA referral link
              </a>{" "}
              to subscribe.
            </Text>
          </div>
        </SplitView>
        <HR />
        <SplitView
          split="1:2"
          style={{
            boxShadow: "0 0 43px rgba(0, 0, 0, 0.1)",
            margin: "2rem auto",
            background: "var(--white)",
            borderRadius: "0.5rem",
            alignItems: "center",
            padding: mobileSize ? "1rem" : "initial"
          }}
        >
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/airbnb.png`}
              alt="Market Report"
              style={{
                maxWidth: "200px",
                padding: mobileSize ? "2rem 0 0" : "0rem",
                height: "auto",
                display: "block",
                margin: "0 auto"
              }}
              $mobileSize={mobileSize}
            />
            <a
              href="www.airbnb.com/r/ashleya337"
              target="_blank"
              // rel="noreferrer"
              style={{
                display: "block",
                margin: "1rem auto",
                maxWidth: "250px",
                padding: "0.7rem",
                borderRadius: "0.25rem",
                color: "var(--white)",
                textDecoration: "none",
                textTransform: "uppercase",
                textAlign: "center",
                fontSize: "1.2rem",
                background: "var(--quinary)"
              }}
              rel="noreferrer"
            >
              Visit Airbnb
            </a>
          </div>

          <div>
            <Text block>
              Create your new host account using our referral code and get a $24
              Airbnb credit - and we get a nice bonus too, everybody wins :)
            </Text>
            <Text block>
              All that&apos;s required is that you sign up to be an Airbnb host
              directly from our Airbnb referral link as opposed to signing up
              via their standard URL/website.
            </Text>
          </div>
        </SplitView>
        <HR />
        <SplitView
          split="1:2"
          style={{
            boxShadow: "0 0 43px rgba(0, 0, 0, 0.1)",
            margin: "2rem auto",
            background: "var(--white)",
            borderRadius: "0.5rem",
            alignItems: "center",
            padding: mobileSize ? "1rem" : "initial"
          }}
        >
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/rankbreeze.png`}
              alt="Market Report"
              style={{
                maxWidth: "200px",
                padding: mobileSize ? "2rem 0 0" : "0rem",
                height: "auto",
                display: "block",
                margin: "0 auto"
              }}
              $mobileSize={mobileSize}
            />
            <a
              href="https://rankbreeze.com/getchalet"
              target="_blank"
              // rel="noreferrer"
              style={{
                display: "block",
                margin: "1rem auto",
                maxWidth: "250px",
                padding: "0.7rem",
                borderRadius: "0.25rem",
                color: "var(--white)",
                textDecoration: "none",
                textTransform: "uppercase",
                textAlign: "center",
                fontSize: "1.2rem",
                background: "var(--quinary)"
              }}
              rel="noreferrer"
            >
              Visit RankBreeze
            </a>
          </div>

          <div>
            <Text block>
              Empower your Airbnb listings with Rankbreeze – a dynamic platform
              designed to elevate your property&apos;s visibility, optimize your
              pricing strategy, and uncover lucrative market opportunities.
            </Text>
            <Text block>
              With Rankbreeze, unlock the potential of your vacation rentals
              through advanced tools tailored for maximum returns and standout
              rankings.
            </Text>
          </div>
        </SplitView>
        <HR />
        <SplitView
          split="1:2"
          style={{
            boxShadow: "0 0 43px rgba(0, 0, 0, 0.1)",
            margin: "2rem auto",
            background: "var(--white)",
            borderRadius: "0.5rem",
            alignItems: "center",
            padding: mobileSize ? "1rem" : "initial"
          }}
        >
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/turno.png`}
              alt="Market Report"
              style={{
                maxWidth: "200px",
                padding: mobileSize ? "2rem 0 0" : "0rem",
                height: "auto",
                display: "block",
                margin: "0 auto"
              }}
              $mobileSize={mobileSize}
            />
            <a
              href="http://share.turno.com/6D8FJV"
              target="_blank"
              // rel="noreferrer"
              style={{
                display: "block",
                margin: "1rem auto",
                maxWidth: "250px",
                padding: "0.7rem",
                borderRadius: "0.25rem",
                color: "var(--white)",
                textDecoration: "none",
                textTransform: "uppercase",
                textAlign: "center",
                fontSize: "1.2rem",
                background: "var(--quinary)"
              }}
              rel="noreferrer"
            >
              Visit Turno
            </a>
          </div>

          <div>
            <Text block>
              Discover the intelligent choice for maintaining spotless vacation
              rentals with Turno. Connect with top-tier cleaning services,
              streamline your scheduling, and manage payments effortlessly.
            </Text>
            <Text block>
              Embrace the convenience that hosts and property managers around
              the globe have found by putting their Airbnb and short-term rental
              cleaning on cruise control with Turno.
            </Text>
          </div>
        </SplitView>
        <HR />
        <SplitView
          split="1:2"
          style={{
            boxShadow: "0 0 43px rgba(0, 0, 0, 0.1)",
            margin: "2rem auto",
            background: "var(--white)",
            borderRadius: "0.5rem",
            alignItems: "center",
            padding: mobileSize ? "1rem" : "initial"
          }}
        >
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/guesty.png`}
              alt="Market Report"
              style={{
                maxWidth: "200px",
                padding: mobileSize ? "2rem 0 0" : "0rem",
                height: "auto",
                display: "block",
                margin: "0 auto"
              }}
              $mobileSize={mobileSize}
            />
            <a
              href="https://guesty.com/start/?r=ref-3C7H7NRgMAnRkthm-guesty"
              target="_blank"
              // rel="noreferrer"
              style={{
                display: "block",
                margin: "1rem auto",
                maxWidth: "250px",
                padding: "0.7rem",
                borderRadius: "0.25rem",
                color: "var(--white)",
                textDecoration: "none",
                textTransform: "uppercase",
                textAlign: "center",
                fontSize: "1.2rem",
                background: "var(--quinary)"
              }}
              rel="noreferrer"
            >
              Visit Guesty
            </a>
          </div>

          <div>
            <Text block>
              Guesty is an industry-leading STR Property management solution
              that helps hosts streamline, centralize, and automate property
              management.
            </Text>
            <Text block>
              Use
              <a
                href="https://guesty.com/start/?r=ref-3C7H7NRgMAnRkthm-guesty"
                target="_blank"
                rel="noreferrer"
                // rel="noreferrer"
              >
                our Guesty referral link
              </a>{" "}
              to get $20 off the first payment on Guesty Lite or $500 off
              onboarding on Guesty Pro.
            </Text>
          </div>
        </SplitView>
        <HR />
        <SplitView
          split="1:2"
          style={{
            boxShadow: "0 0 43px rgba(0, 0, 0, 0.1)",
            margin: "2rem auto",
            background: "var(--white)",
            borderRadius: "0.5rem",
            alignItems: "center",
            padding: mobileSize ? "1rem" : "initial"
          }}
        >
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/steadily.png`}
              alt="Market Report"
              style={{
                maxWidth: "200px",
                padding: mobileSize ? "2rem 0 0" : "0rem",
                height: "auto",
                display: "block",
                margin: "0 auto"
              }}
              $mobileSize={mobileSize}
            />
            <a
              href="https://getchalet.steadilypartner.com/"
              target="_blank"
              // rel="noreferrer"
              style={{
                display: "block",
                margin: "1rem auto",
                maxWidth: "250px",
                padding: "0.7rem",
                borderRadius: "0.25rem",
                color: "var(--white)",
                textDecoration: "none",
                textTransform: "uppercase",
                textAlign: "center",
                fontSize: "1.2rem",
                background: "var(--quinary)"
              }}
              rel="noreferrer"
            >
              Visit Steadily
            </a>
          </div>

          <div>
            <Text block>
              Steadily revolutionizes landlord insurance with its digital-first
              approach, providing fast, affordable coverage tailored for rental
              properties. Its intuitive online platform, designed for the modern
              landlord, offers quick quotes, easy policy management, and
              competitive rates.
            </Text>
            <Text block>
              Built by landlords for landlords, Steadily’s expertise covers a
              range of property types across the US, ensuring personalized
              service and comprehensive protection.
            </Text>
          </div>
        </SplitView>
        <HR />
        <SplitView
          split="1:2"
          style={{
            boxShadow: "0 0 43px rgba(0, 0, 0, 0.1)",
            margin: "2rem auto",
            background: "var(--tertiary)",
            borderRadius: "0.5rem",
            alignItems: "center",
            padding: mobileSize ? "1rem" : "initial"
          }}
        >
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/proper-insurance.png`}
              alt="Market Report"
              style={{
                maxWidth: "200px",
                padding: mobileSize ? "2rem 0 0" : "0rem",
                height: "auto",
                display: "block",
                margin: "0 auto"
              }}
              $mobileSize={mobileSize}
            />
            <a
              href="https://proper.ositracker.com/356119/8728"
              target="_blank"
              // rel="noreferrer"
              style={{
                display: "block",
                margin: "1rem auto",
                maxWidth: "250px",
                padding: "0.7rem",
                borderRadius: "0.25rem",
                color: "var(--white)",
                textDecoration: "none",
                textTransform: "uppercase",
                textAlign: "center",
                fontSize: "1.2rem",
                background: "var(--quinary)"
              }}
              rel="noreferrer"
            >
              Visit Proper Insurance
            </a>
          </div>

          <div>
            <Text block style={{ color: "var(--white)" }}>
              Short-term rentals require specialized insurance, which is why
              Proper Insurance provides tailor-made policies for owners and
              operators, covering both commercial and personal use.
            </Text>
            <Text block style={{ color: "var(--white)" }}>
              For a free and personalized STR insurance quote, click on our{" "}
              <a
                href="https://proper.ositracker.com/356119/8728"
                target="_blank"
                style={{ color: "var(--white)" }}
                rel="noreferrer"
              >
                Proper Insurance referral link
              </a>{" "}
              .
            </Text>
          </div>
        </SplitView>
        <HR />
      </Section>
    </div>
  );
};

export default STRResources;
