import React from "react";
import StrCalculatorBody from "../StrCalculator/StrCalculatorBody";
import AnalyticsHeader from "./components/AnalyticsHeader";
import AnalyticsWrapper from "./components/AnalyticsWrapper";

export default function AnalyticsAirbnbCalculator() {
  return (
    <AnalyticsWrapper style={{ minHeight: "100vh" }}>
      <AnalyticsHeader />
      <StrCalculatorBody />
    </AnalyticsWrapper>
  );
}
