import { HTMLAttributes } from "react";
import styled from "styled-components";

export interface SplitViewProps extends HTMLAttributes<HTMLDivElement> {
  padding?: string;
  split?: "1:2" | "2:1" | "2:3" | "3:2" | "1:1" | "1" | "7:3" | "3:7";
}

const SplitView = styled.div<SplitViewProps>`
  display: grid;
  align-items: flex-start;
  font-size: 1.125rem;
  // align-items: center;
  grid-template-columns: ${(props) => {
    switch (props.split) {
      case "1:2":
        return "1fr 2fr";
      case "2:1":
        return "2fr 1fr";
      case "2:3":
        return "2fr 3fr";
      case "3:2":
        return "3fr 2fr";
      case "7:3":
        return "7fr 3fr";
      case "3:7":
        return "3fr 7fr";
      case "1:1":
      default:
        return "1fr 1fr";
    }
  }};
  padding: 0rem;

  & > * {
    flex: 1 1;
    padding: ${(props) => props.padding};
    width: 100%;
  }

  @media (max-width: 800px) {
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    justify-items: center;

    & > * {
      padding: 0 !important;
    }

    > div:first-child {
      grid-row-start: 1;
    }

    > div:last-child {
      grid-row-start: 2;
    }
  }
`;

SplitView.defaultProps = {
  padding: "2rem",
  split: "1:1"
};

export default SplitView;
