import React, { useContext, useEffect, useState } from "react";
import Text from "../../components/Text";
import Image from "../../components/Image";
import useCookies from "../../hooks/useCookies";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import "../../styles/ContactUsModal.scss";
import { AppContext } from "../../components/AppContext";
import Input from "../../components/Input";
import Section from "../../components/Section";

const defaultForm = {
  name: "",
  phone: "",
  email: "",
  // market: "",
  // interestedIn: "",
  consent: true
};

const ContactUsModal = () => {
  const { mobileSize, openContactModal, setOpenContactModal } =
    useContext(AppContext);
  // const [isContactUs, setContactUs] = useState(false);
  const [contactUs] = useState(false);
  const [userName, setName] = useState("");
  const [userEmail, setEmail] = useState("");
  const { getAllCookies } = useCookies();
  const [thankYou, setThankYou] = useState(false);
  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });
  const handleSelect = (value: any) => {
    // if (marketOptions.some((element) => element.label === value.label)) {
    //   setMarket(
    //     value.label !== "Anywhere in the US" ? "Not Specified" : value.label
    //   );
    // } else if (
    //   serviceOptions.some((element) => element.label === value.label)
    // ) {
    //   setService(value.label);
    if (value.target.name === "email") {
      setEmail(value.target.value);
    } else if (value.target.name === "name") {
      setName(value.target.value);
    }
  };
  const handleSubmit = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(userEmail)) {
      alert("Please use a correct email address!");
      return;
    }

    try {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          email: userEmail,
          name: userName,
          sourcePage: "/str-calculator"
        })
      };
      const button = document.getElementById(
        "free-download"
      ) as HTMLButtonElement;
      if (button) button.disabled = true;

      await fetch(
        "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
        requestOptions
      ).then(() => {
        // const lastContent = button.innerHTML;
        button.innerHTML = "&#10003;";
      });
      setOpenContactModal(false);
    } catch (error) {
      console.error("error scheduling consultation:", error);
      const button = document.getElementById(
        "free-download"
      ) as HTMLButtonElement;
      if (button) {
        button.disabled = false;
        button.innerHTML = "Free Download Now";
      }
    }
  };

  const [form, setForm] = useState(defaultForm);
  const { name, phone, email, consent } = form;

  useEffect(() => {
    async function addContact() {
      try {
        const requestOptions = {
          method: "POST",
          // headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name,
            email,
            phone,
            market: "Other",
            downloadReport: true,
            // interested_in: interestedIn,
            consent,
            ...cookies,
            sourcePage: "str-calculator"
          })
        };
        // const apiData = await API.post("customers", "/contacts", data);
        const apiData = await fetch(
          "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
          requestOptions
        );
        // eslint-disable-next-line
    console.log("api data", apiData);

        setThankYou(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
    console.log("error scheduling consultation:", error);
      }
      setOpenContactModal(false);
    }
    if (contactUs) {
      addContact();
    }
  }, [contactUs, form]);

  const handleToggle = () => {
    setForm(defaultForm);
    setOpenContactModal((prevState: boolean) => !prevState);
  };
  return (
    <Modal
      isOpen={openContactModal}
      toggle={handleToggle}
      style={{
        maxWidth: mobileSize ? "100vw" : "90vw",
        opacity: 1,
        margin: 0,
        padding: 0
        // background:
        // padding: mobileSize ? "0rem" : "1rem"
      }}
    >
      {!thankYou && (
        <Section
          id="home-markets"
          style={{
            // margin: 0,
            // padding: 0,
            padding: "1rem",
            background: `url("${process.env.PUBLIC_URL}/images/markets-bg.webp")`,
            backgroundSize: "cover"
          }}
        >
          <Image
            src={`${process.env.PUBLIC_URL}/images/decoration-lcorner.webp`}
            alt="decoration"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              marginTop: "-2rem"
            }}
          />
          <Image
            src={`${process.env.PUBLIC_URL}/images/decoration-rcorner.webp`}
            alt="decoration"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              marginTop: "-1rem"
            }}
          />
          <Header id="home-markets__header">
            Airbnb Rentals Top 150 Markets
          </Header>
          <Text id="home-markets__description">
            Instantly compare the{" "}
            <span style={{ color: "var(--quinary)" }}>
              top 150 short-term (Airbnb) rental markets
            </span>{" "}
            in the US by returns on investment, occupancy rates, revenue, home
            price, and more…
          </Text>
          <div id="home-markets__main">
            <div id="left-main">
              <Image
                src={`${process.env.PUBLIC_URL}/images/chalet_markets_2024.webp`}
                alt="Market Comparison guide"
              />
            </div>
            <div id="right-main">
              <Input onChange={handleSelect} name="name" placeholder="Name" />
              <Input onChange={handleSelect} name="email" placeholder="Email" />
              <Text id="disclaimer">
                By signing up, you agree to receive subsequent email and
                third-party marketing communications from Chalet pursuant to our
                Privacy Policy, which you may opt out of, or unsubscribe from at
                any time.
              </Text>
              <Button id="free-download" onClick={handleSubmit}>
                Free download now
              </Button>
            </div>
          </div>
        </Section>
      )}
    </Modal>
  );
};

export default ContactUsModal;
