import styled from "styled-components";
import { Color } from "../types";

const HR = styled.hr<{ $color?: Color }>`
  border-color: var(--${(props) => props.$color});
  margin: 1.5rem 0;
`;

HR.defaultProps = {
  $color: Color.quinary
};

export default HR;
